export const GET_LOGS_LOADING = "get/logs/loading"
export const GET_LOGS_ERROR = "get/logs/error"
export const GET_LOGS_SUCCESS = "get/logs/success"
export const RESET_LOGS = "reset/logs"

export const GET_ELOGS_LOADING = "get/elogs/loading"
export const GET_ELOGS_ERROR = "get/elogs/error"
export const GET_ELOGS_SUCCESS = "get/elogs/success"

export const GET_HLOGS_LOADING = "get/hlogs/loading"
export const GET_HLOGS_ERROR = "get/hlogs/error"
export const GET_HLOGS_SUCCESS = "get/hlogs/success"

export const GET_JLOGS_LOADING = "get/jlogs/loading"
export const GET_JLOGS_ERROR = "get/jlogs/error"
export const GET_JLOGS_SUCCESS = "get/jlogs/success"