import axios from "axios"
import { REGULATOR_URL } from "../../constant/baseUrl"
import { get_avatars_list, get_bots_list, get_public_template_list, get_voices_list, patch_avatar, patch_bot, patch_public_template, patch_voice, post_avatar, post_public_template, post_voice, search_avatar_list, search_bots_list, search_public_templates_list, search_voices_list } from "../../constant/endpoints"
import { APPLICATION_JSON, CONTENT_TYPE, X_ACCESS_TOKEN } from "../../constant/utils"
import { getTokenFromLocal } from "../../constant/scripts/helper"

export const get_avatars_list_res = async(page, count) => {

    const res = await axios.get(REGULATOR_URL+get_avatars_list(page, count), {
        headers:{
            [CONTENT_TYPE]:APPLICATION_JSON,
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        }
    })

    return res

}

export const get_voices_list_res = async(page, count) => {

    const res = await axios.get(REGULATOR_URL+get_voices_list(page, count), {
        headers:{
            [CONTENT_TYPE]:APPLICATION_JSON,
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        }
    })

    return res

}

export const get_bots_list_res = async(page, count) => {

    const res = await axios.get(REGULATOR_URL+get_bots_list(page, count), {
        headers:{
            [CONTENT_TYPE]:APPLICATION_JSON,
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        }
    })

    return res

}


export const get_public_template_list_res = async(page, count) => {

    const res = await axios.get(REGULATOR_URL+get_public_template_list(page, count), {
        headers:{
            [CONTENT_TYPE]:APPLICATION_JSON,
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        }
    })

    return res

}

// patch apis
export const patch_avatar_res = async(formdata) => {

    const res = await axios({
        method:"patch",
        data:formdata,
        url:REGULATOR_URL+patch_avatar,
        headers:{
            [CONTENT_TYPE]:"multipart/form-data",
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        },
    
    })

    // console.log(res)
    return res

}

export const patch_bot_res = async(formdata) => {

    const res = await axios({
        method:"patch",
        data:formdata,
        url:REGULATOR_URL+patch_bot,
        headers:{
            [CONTENT_TYPE]:"multipart/form-data",
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        },
    
    })

    // console.log(res)
    return res

}

export const patch_voice_res = async(formdata) => {

    const res = await axios({
        method:"patch",
        data:formdata,
        url:REGULATOR_URL+patch_voice,
        headers:{
            [CONTENT_TYPE]:"multipart/form-data",
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        },
    
    })

    // console.log(res)
    return res

}

export const patch_public_template_res = async(formdata) => {

    const res = await axios({
        method:"patch",
        data:formdata,
        url:REGULATOR_URL+patch_public_template,
        headers:{
            [CONTENT_TYPE]:"multipart/form-data",
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        },
    
    })

    // console.log(res, "publicTemp patch")
    return res

}


// post apis
export const post_voice_res = async(formdata) => {

    const res = await axios({
        method:"post",
        data:formdata,
        url:REGULATOR_URL+post_voice,
        headers:{
            [CONTENT_TYPE]:"multipart/form-data",
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        },
    
    })

    console.log(res)
    return res

}

export const post_avatar_res = async(formdata) => {

    const res = await axios({
        method:"post",
        data:formdata,
        url:REGULATOR_URL+post_avatar,
        headers:{
            [CONTENT_TYPE]:"multipart/form-data",
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        },
    
    })

    console.log(res)
    return res

}


export const post_public_template_res = async(formdata) => {

    const res = await axios({
        method:"post",
        data:formdata,
        url:REGULATOR_URL+post_public_template,
        headers:{
            [CONTENT_TYPE]:"multipart/form-data",
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        },
    
    })

    console.log(res)
    return res

}

//search apis

export const search_avatar_list_res = async(query, type) => {

    const res = await axios.get(REGULATOR_URL+search_avatar_list(query, type), {
        headers:{
            [CONTENT_TYPE]:APPLICATION_JSON,
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        }
    })
// console.log(res, "search ava", REGULATOR_URL+search_avatar_list(query, type))
    return res

}


export const search_voices_list_res = async(query, type) => {

    const res = await axios.get(REGULATOR_URL+search_voices_list(query, type), {
        headers:{
            [CONTENT_TYPE]:APPLICATION_JSON,
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        }
    })
// console.log(res, "search ava", REGULATOR_URL+search_avatar_list(query, type))
    return res

}


export const search_bots_list_res = async(query, type) => {

    const res = await axios.get(REGULATOR_URL+search_bots_list(query, type), {
        headers:{
            [CONTENT_TYPE]:APPLICATION_JSON,
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        }
    })
// console.log(res, "search ava", REGULATOR_URL+search_avatar_list(query, type))
    return res

}



export const search_public_template_list_res = async(query, type) => {

    const res = await axios.get(REGULATOR_URL+search_public_templates_list(query, type), {
        headers:{
            [CONTENT_TYPE]:APPLICATION_JSON,
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        }
    })
// console.log(res, "search ava", REGULATOR_URL+search_avatar_list(query, type))
    return res

}