export const GET_VIDEOS_LOADING = "get/videos/loading"
export const GET_VIDEOS_ERROR = "get/videos/error"
export const GET_VIDEOS_SUCCESS = "get/videos/success"

export const PATCH_VIDEOS_LOADING = "patch/videos/loading"
export const PATCH_VIDEOS_ERROR = "patch/videos/error"
export const PATCH_VIDEOS_SUCCESS = "patch/videos/success"

export const GET_LOGICS_LOADING = "get/logics/loading"
export const GET_LOGICS_ERROR = "get/logics/error"
export const GET_LOGICS_SUCCESS = "get/logics/success"

export const PATCH_LOGICS_LOADING = "patch/logics/loading"
export const PATCH_LOGICS_ERROR = "patch/logics/error"
export const PATCH_LOGICS_SUCCESS = "patch/logics/success"

export const GET_TEMPLATES_LOADING = "get/templates/loading"
export const GET_TEMPLATES_ERROR = "get/templates/error"
export const GET_TEMPLATES_SUCCESS = "get/templates/success"

export const PATCH_TEMPLATES_LOADING = "patch/templates/loading"
export const PATCH_TEMPLATES_ERROR = "patch/templates/error"
export const PATCH_TEMPLATES_SUCCESS = "patch/templates/success"



