import axios from "axios"
import { TOKEN, USER_DETAILS } from "../localKeys"
import SweetAlert from "sweetalert2"
import AWS from "aws-sdk"

export const getTokenFromLocal = () => {
  return localStorage.getItem(TOKEN)
}

export const logout = () => {
  localStorage.setItem(USER_DETAILS, null)
  localStorage.setItem(TOKEN, "")
}

export const getUserIdFromLocal = () => {
  return JSON.parse(localStorage.getItem(USER_DETAILS)).userData.id
}
export const copyContent = async (value) => {
  // console.log(value, "value")
  try {
    await navigator.clipboard.writeText(value)
    SweetAlert.fire({ title: "Good job !", text: "Copied to clipboard !", icon: "success" });
  } catch (err) {
    SweetAlert.fire({ title: "Oh no !", text: "Something went wrong !", icon: "error" });
  }
}

export const converIntoFormData = (obj) => {
  const formdata = new FormData()
  for (let x in obj) {
    formdata.append(x, obj[x])
  }
  return formdata
}

export const create_UUID = () => {
  let dt = new Date().getTime()
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = ((dt + (Math.random() * 16)) % 16) | 0
    dt = Math.floor(dt / 16)
    return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16)
  })
  return uuid
}
export const converToUTC = (timestamp) => {
  const dateString = timestamp.split("-")[0].trim()
  const timeString = timestamp.split("-")[1].trim()
  console.log(timestamp)
  const [month, day, year, time] = dateString.split(" ");

  const [hour, minute, second] = timeString.split(":");

  const monthMap = {
    January: 0,
    February: 1,
    March: 2,
    April: 3,
    May: 4,
    June: 5,
    July: 6,
    August: 7,
    September: 8,
    October: 9,
    November: 10,
    December: 11,
  };

  const dateObj = new Date(Date.UTC(parseInt(year), monthMap[month], parseInt(day), parseInt(hour), parseInt(minute), parseInt(second)));

  return dateObj.toISOString()
}
export function IST(utcTimestamp) {
  // Create a Date object with the UTC timestamp
  const utcDate = new Date(utcTimestamp);

  // Specify the options for IST (Indian Standard Time)
  const istOptions = {
    timeZone: 'Asia/Kolkata', // IST timezone
    hour12: false, // Use 24-hour format
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };

  // Convert the UTC time to IST using toLocaleString
  const istTimestamp = utcDate.toLocaleString('en-IN', istOptions);

  return istTimestamp;
}



export const convertUTCToIST = (time) => {
  return new Date(`${time}z`).toLocaleString(undefined, { timeZone: 'Asia/Kolkata' })
}


// export const uploadToCloudinary = (file, setState, path) => {
//   return new Promise(async (resolved, rejected) => {
//     try {
//       const formData = new FormData()
//       formData.append('file', file)
//       formData.append('folder', path)
//       formData.append('upload_preset', `${process.env.REACT_APP_UPLOAD_PRESET_VALUE}`) // Replace with your Cloudinary upload preset
//       const response = await axios.post(
//         `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`,
//         formData,
//         {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//           },
//         }
//       )
//       // console.log('Upload response:', response.data)
//       resolved(response.data.secure_url)
//     } catch (error) {
//       // console.error('Error uploading image:', error)
//       rejected(error)
//     }

//   }).finally(() => {
//     // console.log("uploaded one more")
//     setState((prev) => ({ ...prev, progress: prev.progress + 1 }))
//   })
// }

export const uploadToAws = (file, setState, path) => {
  let finalEx = ""
  if (file.type.split('/')[1] === "quicktime") {
    finalEx = "mov"
  } else {
    finalEx = file.type.split('/')[1]

  }

  return new Promise(async (resolved, rejected) => {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_REGION
    })
    const s3 = new AWS.S3()

    function uploadFile(file) {
      // const details = JSON.parse(localStorage.getItem(USER_DETAILS))
      const key = `${process.env.REACT_APP_AWS_KEY + path}/${create_UUID()}.${finalEx}`

      const params = {
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Key: key,
        Body: file,
        ContentType: file.type,
      }

      s3.upload(params).on('httpUploadProgress', function (progress) {
        // setState((prev) => ({ ...prev, progressCount: Math.round((progress.loaded / progress.total) * 100) }))


      })
        .send(function (err, data_s3) {
          if (err) {
            rejected(err)
          } else {
            resolved(data_s3.Location)
          }
        })

    }

    uploadFile(file)

  }).finally(() => {
    // console.log("uploaded one more")
    setState((prev) => ({ ...prev, progress: prev.progress + 1 }))
  })
}

export const uploadMultipleImages = (files, setState, path) => {
  setState((prev) => ({ ...prev, loading: true, progress: 0 }))
  return new Promise((resolved, rejected) => {

    const uploads = files.map((el) => uploadToAws(el, setState, path))
    Promise.all(uploads).then((res) => {
      resolved(res)
    }).catch((err) => {
      rejected(err)
    }).finally(() => {
      setState((prev) => ({ ...prev, loading: false, progress: null }))
      // console.log("uploded")
    })

  })

}