import { Anchor, Box, Headphones, Home, Settings, User } from "react-feather";

export const MENUITEMS = [
  {
    title: "Dashboard",
    icon: Home,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      { path: "/dashboard/overview", title: "Overview", type: "link" },
      { path: "/dashboard/manage-admins", title: "Manage Admins", type: "link" }
      // { path: "/dashboard/ecommerce", title: "E-Commerce", type: "link" },
      // { path: "/dashboard/university", title: "University", type: "link" },
      // { path: "/dashboard/server", title: "Server", type: "link" },
      // { path: "/dashboard/project", title: "Project", type: "link" },
    ],
  },

  {
    title: "PAM",
    icon: Box,
    type: "sub",
    path: "",
    active: false,
    children: [
      { path: "/pam/manage-avatars", title: "Manage Avatars", type: "link" },
      { path: "/pam/manage-bots", title: "Manage Bots", type: "link" },
      { path: "/pam/manage-voices", title: "Manage Voices", type: "link" },
      { path: "/pam/manage-videos", title: "Manage Videos", type: "link" },
      { path: "/pam/manage-logics", title: "Manage Logics", type: "link" },
      { path: "/pam/manage-templates", title: "Manage Templates", type: "link" },
      { path: "/pam/manage-public-templates", title: "Manage Public Templates", type: "link" },
    ]
  },
  {
    title: "CAM",
    icon: User,
    type: "sub",
    path: "",
    active: false,
    children: [
      { path: "/cam/manage-orgs", title: "Manage Orgs", type: "link" },
    ]
  },

  // {
  //   title: "Support Ticket",
  //   icon: Anchor,
  //   type: "link",
  //   path: "/support-ticket/supportTicket",
  //   active: false,
  // },
  // {
  //   path: "http://support.pixelstrap.com/help-center",
  //   title: "Raise Support",
  //   icon: Headphones,
  //   type: "exteral_link",
  //   active: false,
  // },

  {
    title: "Utility",
    icon: Settings,
    type: "sub",
    path: "",
    active: false,
    children: [
      { path: "/utility/terminal", title: "Terminal", type: "link" },
    ]
  },
];
