import React, { Fragment } from "react";
import man from "../../../assets/images/dashboard/user.png";
import { User, Mail, Lock, Settings, LogOut } from "react-feather";
import { useNavigate } from "react-router-dom";
import { LOGOUT } from "../../../redux/login/login.types";
import { useDispatch } from "react-redux";
import { RANDOM_AVATAR_IMAGE_URL } from "../../../constant/utils";
import { USER_DETAILS } from "../../../constant/localKeys";

const UserMenu = () => {
  const history = useNavigate()
  const dispatch = useDispatch()
  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS))

  return (
    <Fragment>
      <li className="onhover-dropdown">
        <div className="d-flex align-items-center">
          <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={userDetails?.userData?.props?.profilePic} alt="header-user" />
          <div className="dotted-animation">
            <span className="animate-circle"></span>
            <span className="main-circle"></span>
          </div>
        </div>
        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
          {/* <li>
            <a href="#javascript">
              <User />
              Edit Profile
            </a>
          </li>
          <li>
            <a href="#javascript">
              <Mail />
              Inbox
            </a>
          </li>
          <li>
            <a href="#javascript">
              <Lock />
              Lock Screen
            </a>
          </li>
          <li>
            <a href="#javascript">
              <Settings />
              Settings
            </a>
          </li> */}
          <li onClick={() => {
        dispatch({type:LOGOUT})
      }}>
              <LogOut /> Log out
          </li>
        </ul>
      </li>
    </Fragment>
  );
};

export default UserMenu;
