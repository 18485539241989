import { ChevronLeft, ChevronRight } from "react-feather";
import { BOLD, CENTER, FILL_PARENT, FLEX, GRID, NONE } from "../../../constant/typograpy/properties";
import { Button } from "reactstrap";
import { DEEPSKYBLUE, GAINSBORO, GRAY, TRANSPARENT } from "../../../constant/typograpy/colors";
import "./style.css"
export default function Paginate ({page, setPage, totalPage, style}) {

    return  <div style={{maxWidth:"250px", display:FLEX, justifyContent:CENTER, alignItems:CENTER, height:"40px", borderRadius:"8px", border:"1px solid deepskyblue", ...style}}>
        <button style={{border:NONE, backgroundColor:TRANSPARENT}}  disabled={page === 1} onClick={() => {
            setPage(page - 1)
        }}>
        <ChevronLeft color={page === 1 ? GAINSBORO : DEEPSKYBLUE} style={{marginTop:"4px"}} />
        </button>
        <div style={{fontWeight:BOLD, height:FILL_PARENT, display:GRID, placeItems:CENTER, padding:"0px 16px", color:DEEPSKYBLUE}}><center><input className="page-input" type="number" style={{maxWidth:'60px', backgroundColor:TRANSPARENT, outline:NONE, border:"1px solid deepskyblue", borderRadius:"4px", textAlign:"center"}} placeholder={page} onKeyDown={(e) => {
            if (e.key === "Enter") {
                if (Number(e.target.value) < 1) {
                    setPage(1)
                } else if (Number(e.target.value) > totalPage) {
                    setPage(totalPage)
                } else {
                    setPage(Number(e.target.value))
                }
                e.target.value = ""
                e.target.blur()
            }
        }}></input> &nbsp; of &nbsp; {totalPage}</center></div>
        <button style={{border:NONE,  backgroundColor:TRANSPARENT}} disabled={page === totalPage} onClick={() => {
            setPage(page + 1)
        }}>
        <ChevronRight color={page === totalPage ? GAINSBORO : DEEPSKYBLUE} style={{marginTop:"4px"}} />
        </button>
</div>
}