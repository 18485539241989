//Colors
export const PRIMARY = "#9e3df2"
export const SECONDARY = "#82868b"
export const TRANSPARENT = "transparent"
export const BACKGROUND = "#ececec"
export const ALICEBLUE = "aliceblue"
export const LIGHTSALMON = "lightsalmon"
export const ANTIQUEWHITE = "antiquewhite"
export const LIGHTSEAGREEN = "lightseagreen"
export const AQUA = "aqua"
export const LIGHTSKYBLUE = "lightskyblue"
export const AQUAMARINE = "aquamarine"
export const LIGHTSLATEGRAY = "lightslategray"
export const AZURE = "azure"
export const LIGHTSTEELBLUE = "lightsteelblue"
export const BEIGE = "beige"
export const LIGHTYELLOW = "lightyellow"
export const BISQUE = "bisque"
export const LIME = "lime"
export const BLACK = "black"
export const LIMEGREEN = "limegreen"
export const BLANCHEDALMOND = "blanchedalmond"
export const LINEN = "linen"
export const BLUE = "blue"
export const MAGENTA = "magenta"
export const BLUEVIOLET = "blueviolet"
export const MAROON = "maroon"
export const BROWN = "brown"
export const MEDIUMAQUAMARINE = "mediumaquamarine"
export const BURLYWOOD = "burlywood"
export const MEDIUMBLUE = "mediumblue"
export const CADETBLUE = "cadetblue"
export const MEDIUMORCHID = "mediumorchid"
export const CHARTREUSE = "chartreuse"
export const MEDIUMPURPLE = "mediumpurple"
export const CHOCOLATE = "chocolate"
export const MEDIUMSEAGREEN = "mediumseagreen"
export const CORAL = "coral"
export const MEDIUMSLATEBLUE = "mediumslateblue"
export const CORNFLOWERBLUE = "cornflowerblue"
export const MEDIUMSPRINGGREEN = "mediumspringgreen"
export const CORNSILK = "cornsilk"
export const MEDIUMTURQUOISE = "mediumturquoise"
export const CRIMSON = "crimson"
export const MEDIUMVIOLETRED = "mediumvioletred"
export const CYAN = "cyan"
export const MIDNIGHTBLUE = "midnightblue"
export const DARKBLUE = "darkblue"
export const MINTCREAM = "mintcream"
export const DARKCYAN = "darkcyan"
export const MISTYROSE = "mistyrose"
export const DARKGOLDENROD = "darkgoldenrod"
export const MOCCASIN = "moccasin"
export const DARKGRAY = "darkgray"
export const NAVAJOWHITE = "navajowhite"
export const DARKGREEN = "darkgreen"
export const NAVY = "navy"
export const DARKKHAKI = "darkkhaki"
export const OLDLACE = "oldlace"
export const DARKMAGENTA = "darkmagenta"
export const OLIVE = "olive"
export const DARKOLIVEGREEN = "darkolivegreen"
export const OLIVEDRAB = "olivedrab"
export const DARKORANGE = "darkorange"
export const ORANGE = "orange"
export const DARKORCHID = "darkorchid"
export const ORANGERED = "orangered"
export const DARKRED = "darkred"
export const ORCHID = "orchid"
export const DARKSALMON = "darksalmon"
export const PALEGOLDENROD = "palegoldenrod"
export const DARKSEAGREEN = "darkseagreen"
export const PALEGREEN = "palegreen"
export const DARKSLATEBLUE = "darkslateblue"
export const PALETURQUOISE = "paleturquoise"
export const DARKSLATEGRAY = "darkslategray"
export const PALEVIOLETRED = "palevioletred"
export const DARKTURQUOISE = "darkturquoise"
export const PAPAYAWHIP = "papayawhip"
export const DARKVIOLET = "darkviolet"
export const PEACHPUFF = "peachpuff"
export const DEEPPINK = "deeppink"
export const PERU = "peru"
export const DEEPSKYBLUE = "deepskyblue"
export const PINK = "pink"
export const DIMGRAY = "dimgray"
export const PLUM = "plum"
export const DODGERBLUE = "dodgerblue"
export const POWDERBLUE = "powderblue"
export const FIREBRICK = "firebrick"
export const PURPLE = "purple"
export const FLORALWHITE = "floralwhite"
export const RED = "red"
export const FORESTGREEN = "forestgreen"
export const ROSYBROWN = "rosybrown"
export const FUCHSIA = "fuchsia"
export const ROYALBLUE = "royalblue"
export const GAINSBORO = "gainsboro"
export const SADDLEBROWN = "saddlebrown"
export const GHOSTWHITE = "ghostwhite"
export const SALMON = "salmon"
export const GOLD = "gold"
export const SANDYBROWN = "sandybrown"
export const GOLDENROD = "goldenrod"
export const SEAGREEN = "seagreen"
export const GRAY = "gray"
export const SEASHELL = "seashell"
export const GREEN = "green"
export const SIENNA = "sienna"
export const GREENYELLOW = "greenyellow"
export const SILVER = "silver"
export const HONEYDEW = "honeydew"
export const SKYBLUE = "skyblue"
export const HOTPINK = "hotpink"
export const SLATEBLUE = "slateblue"
export const INDIANRED = "indianred"
export const SLATEGRAY = "slategray"
export const INDIGO = "indigo"
export const SNOW = "snow"
export const IVORY = "ivory"
export const SPRINGGREEN = "springgreen"
export const KHAKI = "khaki"
export const STEELBLUE = "steelblue"
export const LAVENDER = "lavender"
export const TAN = "tan"
export const LAVENDERBLUSH = "lavenderblush"
export const TEAL = "teal"
export const LAWNGREEN = "lawngreen"
export const THISTLE = "thistle"
export const LEMONCHIFFON = "lemonchiffon"
export const TOMATO = "tomato"
export const LIGHTBLUE = "lightblue"
export const TURQUOISE = "turquoise"
export const LIGHTCORAL = "lightcoral"
export const VIOLET = "violet"
export const LIGHTCYAN = "lightcyan"
export const WHEAT = "wheat"
export const LIGHTGOLDENRODYELLOW = "lightgoldenrodyellow"
export const WHITE = "white"
export const LIGHTGREEN = "lightgreen"
export const WHITESMOKE = "whitesmoke"
export const LIGHTGREY = "lightgrey"
export const YELLOW = "yellow"
export const LIGHTPINK = "lightpink"
export const YELLOWGREEN = "yellowgreen"
