import { useState } from "react";
import { Plus } from "react-feather";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { CENTER, FLEX, SB } from "../../../constant/typograpy/properties";
import UserManager from "../AdvanceComp/UserManger";
import Swal from "sweetalert2";
import { converIntoFormData, create_UUID } from "../../../constant/scripts/helper";
import { useDispatch } from "react-redux";
import { post_avatar_api, post_voice_api } from "../../../redux/assets/assets.actions";
import PreviewAudio from "../AdvanceComp/PreviewAudio";
import { useRef } from "react";
import WhiteFade from "../WhiteFade";
import { useNavigate } from "react-router-dom";
import Terminal from "../../../pages/Utility/Terminal";
export default function CreateAvatar({ loading }) {
  const [modal, setModal] = useState(false)
  const [name, setName] = useState("")
  const [gender, setGender] = useState("male")
  const [desc, setDesc] = useState("")
 const [zipUrl, setZipUrl] = useState("")
 const [width, setWidth] = useState(0)
 const [height, setHeight] = useState(0)
 const [offset,  setOffset] = useState({x:0, y:0})
 const [cropFrame, setCropFrame] = useState(0)
 const [endFrame, setEndFrame] = useState(0)
 const [pngFrame, setPngFrame] = useState(0)
 const [startIdx, setStartIdx] = useState(-8)
 const [endIdx, setEndIdx] = useState(-4)
  
  // const [allocatedUsers, setAllocatedUsers] = useState([])
  const toggle = () => setModal(!modal)
  const dispatch = useDispatch()
  const nav = useNavigate()
  const handlePost = () => {
    if (name === "" || gender === "" || desc === "" || width === "" || height === "" || offset === "" || cropFrame === "" || endFrame === "" || pngFrame === "" || startIdx === "" || endIdx === "") {
      Swal.fire({ title: "Empty Field", html: "All * (star) fields are mandotary !", icon: "error" })
      return
    }

    const formData = converIntoFormData({
      avatar_name: name.trim(),
      avatar_description: desc.trim(),
      avatar_gender: gender.trim(),
      zip_url: zipUrl.trim(),
      width: Number(String(width).trim()),
      height: Number(String(height).trim()),
      top_x: Number(String(offset.x).trim()),
      top_y: Number(String(offset.y).trim()),
      crop_frame: Number(String(cropFrame).trim()),
      end_frame:Number(String(endFrame).trim()),
      png_frame: Number(String(pngFrame).trim()),
      count_start_idx: Number(String(startIdx).trim()),
      count_end_idx: Number(String(endIdx).trim())
    })

    dispatch(post_avatar_api(formData, Swal)).then((res) => {
      console.log(res)
      nav("/utility/terminal")
      toggle()
    }).catch((e) => {
      console.log(e)
    })
   
  }

  return <div>
    <Button onClick={toggle}><span style={{ display: FLEX, alignItems: CENTER, gap: "8px" }}><Plus size={24} /> <span> Create Avatar </span> </span> <></></Button>
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>{"Create Avatar"}</ModalHeader>
      <ModalBody>
        <div className="col-sm-12">
          <div className="card">
            {loading && <WhiteFade loader={true} />}
            <div className="card-header">
              <h5>{"Custom Avatar Form"}</h5><span>Use this form to create a new avatar.</span>
            </div>
            <div className="card-body">
              <form className="theme-form">

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="name">{"Name*"}</label>
                  <div className="col-sm-9">
                    <input value={name} onChange={(e) => setName(e.target.value)} className="form-control" type="text" id="name" placeholder="Name" />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="desc">{"Description*"}</label>
                  <div className="col-sm-9">
                    <input value={desc} onChange={(e) => setDesc(e.target.value)} className="form-control" type="text" id="desc" placeholder="Description" />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="Gender">{"Gender*"}</label>
                  <div className="col-sm-9">
                    <select value={gender} onChange={(e) => setGender(e.target.value)} className="form-control">
                      <option value={"male"}>Male</option>
                      <option value={"female"}>Female</option>
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="zipUrl">{"Zip Url*"}</label>
                  <div className="col-sm-9">
                    <div style={{ display: FLEX, alignItems: CENTER, justifyContent: SB, gap: "8px" }}>
                      <input className="form-control" value={zipUrl} onChange={(e) => setZipUrl(e.target.value)} id="zipUrl" type="text" placeholder="Zip Url" />

                    </div>
                  </div>

                </div>
               

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="width">{"Width*"}</label>
                  <div className="col-sm-9">
                    <input value={width} onChange={(e) => setWidth(e.target.value)} className="form-control" type="number" id="width" placeholder="Width" />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="height">{"Height*"}</label>
                  <div className="col-sm-9">
                    <input value={height} onChange={(e) => setHeight(e.target.value)} className="form-control" type="number" id="height" placeholder="Height" />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="topx">{"Top x*"}</label>
                  <div className="col-sm-9">
                    <input value={offset.x} onChange={(e) => setOffset({...offset, x:e.target.value})} className="form-control" type="number" id="topx" placeholder="Top x" />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="topy">{"Top y*"}</label>
                  <div className="col-sm-9">
                    <input value={offset.y} onChange={(e) => setOffset({...offset, y:e.target.value})} className="form-control" type="number" id="topy" placeholder="Top y" />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="cropFrame">{"Crop Frame*"}</label>
                  <div className="col-sm-9">
                    <input value={cropFrame} onChange={(e) => setCropFrame(e.target.value)} className="form-control" id="cropFrame" type="number" placeholder="Crop Frame" />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="endFrame">{"End Frame*"}</label>
                  <div className="col-sm-9">
                    <input value={endFrame} onChange={(e) => setEndFrame(e.target.value)} className="form-control" id="endFrame" type="number" placeholder="End Frame" />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="pngFrame">{"PNG Frame*"}</label>
                  <div className="col-sm-9">
                    <input value={pngFrame} onChange={(e) => setPngFrame(e.target.value)} className="form-control" id="pngFrame" type="number" placeholder="PNG Frame" />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="startIdx">{"Count Start Idx*"}</label>
                  <div className="col-sm-9">
                    <input value={startIdx} onChange={(e) => setStartIdx(e.target.value)} className="form-control" id="startIdx" type="number" placeholder="Count Start Idx" />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="endIdx">{"Count End Idx*"}</label>
                  <div className="col-sm-9">
                    <input value={endIdx} onChange={(e) => setEndIdx(e.target.value)} className="form-control" id="endIdx" type="number" placeholder="Count end Idx" />
                  </div>
                </div>

                {/* <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="users">{"Add Users"}</label>
                  <div className="col-sm-9">
                    <div className="form-control" style={{ padding: "16px" }}>
                      <UserManager allocatedUsers={allocatedUsers} setAllocatedUsers={setAllocatedUsers} filterList={[{ name: "ID", value: "id" }, { name: "Email", value: "email" }, { name: "Name", value: "displayName" }]} />
                    </div>
                  </div>
                </div> */}

              </form>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        {!loading && <button onClick={handlePost} className="btn btn-primary me-1">{"Create"}</button>}
        {!loading && <button onClick={toggle} className="btn btn-secondary">{"Cancel"}</button>}
      </ModalFooter>
    </Modal>
  </div>
}