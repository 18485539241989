import React, { useEffect, useState } from 'react';
import logo from '../assets/images/split_icon.png';
import { Login, LOGIN, YourName, Password, RememberMe, DoubleStyle } from '../constant';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../redux/login/login.actions';
import { Alert, Button } from 'reactstrap';
import { X } from 'react-feather';
import { RESET } from '../redux/login/login.types';
import { GMAIL_REGEX, PERSON8_REGEX, PERSONATE_REGEX } from '../constant/utils';
import SplitLoader from '../components/custom/SplitLoader';
import WhiteFade from '../components/custom/WhiteFade';
import px from '../constant/scripts/px';

const Logins = () => {
    const history = useNavigate();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [errorMsg, setErrorMsg] = useState("Something went wrong !")
    const dispatch = useDispatch()
    const { token, loading, error } = useSelector((state) => state.loginManager)
    const [enableError, setEnablError] = useState(false)

    useEffect(() => {
        if (token !== "") {
            // history(`${process.env.PUBLIC_URL}/dashboard/overview`)
        }

    }, [token])


    const loginAuth = () => {
        dispatch({ type: RESET })
        setEnablError(false)
        // console.log(email, password)
        if (email === "" || password === "") {
            setErrorMsg("Fields cannot be empty !")
            setEnablError(true)
            return
        }

        if (PERSONATE_REGEX.test(email) || PERSON8_REGEX.test(email) || GMAIL_REGEX.test(email)) {
            dispatch(loginUser({ email, password }))
        } else {
            setErrorMsg("You are not authorised !")
            setEnablError(true)
            return
        }

        // history(`${process.env.PUBLIC_URL}/dashboard/overview`);
    }
    const handleLogin = (e) => {
        if (e.code === "Enter") {
            // e.preventDefault()
            loginAuth()
            // console.log("YES", e)
        }

    }


    return (
        <div>
            <Alert className="alert-dismissible"
                isOpen={error || enableError}
                color='danger'
            >
                <p>{error ? "Something went wrong !" : errorMsg}</p>
                <Button style={{ width: px(30), height: px(10) }} className="btn-close" onClick={() => {
                    dispatch({ type: RESET })
                    setEnablError(false)
                }}></Button>
            </Alert>

            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    {/* <!-- login page start--> */}
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="text-center"><img width={200} src={logo} alt="" /></div>
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                {loading && <WhiteFade />}
                                                <div className="text-center">
                                                    <h4>{LOGIN}</h4>
                                                    <h6>{"Enter your Email and Password"} </h6>
                                                </div>
                                                <form className="theme-form">
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0">{"Email"}</label>
                                                        <input placeholder='Your Email' onKeyDown={handleLogin} value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" type="text" required="" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">{Password}</label>
                                                        <input placeholder='Your Password' onKeyDown={handleLogin} value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" type="password" required="" />
                                                    </div>
                                                    {/* <div className="checkbox p-0">
                                                        <input id="checkbox1" type="checkbox" />
                                                        <label htmlFor="checkbox1">{RememberMe}</label>
                                                    </div> */}
                                                    <div className="form-group form-row mt-3 mb-0 d-grid">
                                                        {loading ? <SplitLoader center={true} /> : <button disabled={loading} className="btn btn-primary" type="button" onClick={() => {
                                                            loginAuth()
                                                        }}>{Login} </button>}

                                                    </div>
                                                    {/* <div className="login-divider"></div>
                                                    <div className="social mt-3">
                                                        <div className="form-group btn-showcase d-flex">
                                                            <button className="btn social-btn btn-fb d-inline-block"> <i className="fa fa-facebook"></i></button>
                                                            <button className="btn social-btn btn-twitter d-inline-block"><i className="fa fa-google"></i></button>
                                                            <button className="btn social-btn btn-google d-inline-block"><i className="fa fa-twitter"></i></button>
                                                            <button className="btn social-btn btn-github d-inline-block"><i className="fa fa-github"></i></button>
                                                        </div>
                                                    </div> */}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- login page end--> */}
                </div>
            </div>
        </div>
    );
};

export default Logins;