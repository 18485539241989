import axios from "axios"
import { REGULATOR_URL } from "../../constant/baseUrl"
import { loginEndpoint } from "../../constant/endpoints"

async function loginApi(data) {
    const formdata = new FormData()
    formdata.append("username", data.email)
    formdata.append("password", data.password)
    const res = await axios.post(REGULATOR_URL+loginEndpoint, formdata)
    console.log(res)
    return res
  }

  export {loginApi}