import { loginApi } from "./login.api"
import { LOGIN_ERROR, LOGIN_LOADING, LOGIN_SUCCESS } from "./login.types"

const loginUser = (data) => async (dispatch) => {

    dispatch({ type: LOGIN_LOADING })
    try {
        let res = await loginApi(data)
        dispatch({ type: LOGIN_SUCCESS, payload: res.data })
        // console.log(res.data)
    } catch (error) {
        dispatch({ type: LOGIN_ERROR })
    }

}

export { loginUser }

