import axios from "axios"
import { REGULATOR_URL } from "../../constant/baseUrl"
import { get_users_list } from "../../constant/endpoints"
import { APPLICATION_JSON, CONTENT_TYPE, X_ACCESS_TOKEN } from "../../constant/utils"
import { getTokenFromLocal } from "../../constant/scripts/helper"

export const get_users_list_res = async(page, count) => {

    const res = await axios.get(REGULATOR_URL+get_users_list(page, count), {
        headers:{
            [CONTENT_TYPE]:APPLICATION_JSON,
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        }
    })

    return res

}