import px from "../../constant/scripts/px";
import { CENTER, GRID, INLINE_BLOCK } from "../../constant/typograpy/properties";

export default function SplitLoader({center = false}) {

    return <div className="loader-box" style={{display: center ? GRID : INLINE_BLOCK, placeItems:center && CENTER, maxHeight:px(35)}}>
        <div className="loader" >
            <div className="line bg-primary"></div>
            <div className="line bg-primary"></div>
            <div className="line bg-primary"></div>
            <div className="line bg-primary"></div>
        </div>
    </div>
} 