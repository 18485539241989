import { useEffect, useRef } from "react"
import { BLOCK, NONE, POINTER } from "../../../constant/typograpy/properties"
import { Pause, Play } from "react-feather"
import { GRAY, WHITE } from "../../../constant/typograpy/colors"
import { useState } from "react"

export default function PreviewAudio ({src = ""}) {
    const [play, setPlay] = useState(false)
    const audioRef = useRef(null)
    useEffect(() => {
        if (play) {
            audioRef.current.play()
        } else {
            audioRef.current.pause()
        }
    }, [play])

    const handlePlay = () => {
        setPlay(true)
    }
    const handlePause = () => {
        setPlay(false)
    }
    return <div >
        {!play ? <Play onClick={() => setPlay(true)} color={WHITE} style={{ backgroundColor: GRAY, padding: "4px", borderRadius: "20px", cursor: POINTER }} size={28} display={src !== "" ? BLOCK : NONE} /> : <Pause onClick={() => setPlay(false)} color={WHITE} style={{ backgroundColor: GRAY, padding: "4px", borderRadius: "20px", cursor: POINTER }} size={28} display={src !== "" ? BLOCK : NONE} />}
        <div style={{display:NONE}}>
        <audio ref={audioRef} src={src} onPlay={handlePlay} onPause={handlePause}></audio> 
        </div>
    </div>
}