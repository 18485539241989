import React from "react";
import Default from "../components/dashboard/defaultCompo/default";
import Ecommerce from "../components/dashboard/ecommerce";
import University from "../components/dashboard/university";
import ServerComponent from "../components/dashboard/server/serverComponent";
import Project from "../components/dashboard/project/project";

// sample page
import SupportTicket from "../components/support-ticket/supportTicket";
import Overview from "../pages/Dashboard/Overview/Overview";
import ManageAdmins from "../pages/Dashboard/Manage/ManageAdmins";
import Logins from "../pages/login";
import ManageAvatars from "../pages/PAM/Avatars/ManageAvatars"
import ManageVoices from "../pages/PAM/Voices/ManageVoices";
import ManageBots from "../pages/PAM/Bots/ManageBots";
import ManageVideos from "../pages/CAM/Videos/ManageVideos";
import ManageLogics from "../pages/CAM/Logics/ManageLogics";
import ManageTemplates from "../pages/CAM/Templates/ManageTemplates";
import Terminal from "../pages/Utility/Terminal";
import ManageOrgs from "../pages/CAM/Orgs/ManageOrgs";
import ManagePublicTemplate from "../pages/PAM/PublicTemplates/ManagePublicTemplates";

export const routes = [
  // { path: `${process.env.PUBLIC_URL}/dashboard/default`, Component: <Default /> },
  // { path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`, Component: <Ecommerce /> },
  // { path: `${process.env.PUBLIC_URL}/dashboard/university`, Component: <University /> },
  // { path: `${process.env.PUBLIC_URL}/dashboard/server`, Component: <ServerComponent /> },
  // { path: `${process.env.PUBLIC_URL}/dashboard/project`, Component: <Project /> },
  { path: `${process.env.PUBLIC_URL}/dashboard/overview`, Component: <Overview /> },
  { path: `${process.env.PUBLIC_URL}/dashboard/manage-admins`, Component: <ManageAdmins /> },
  { path: `${process.env.PUBLIC_URL}/pam/manage-avatars`, Component: <ManageAvatars /> },
  { path: `${process.env.PUBLIC_URL}/pam/manage-bots`, Component: <ManageBots /> },
  { path: `${process.env.PUBLIC_URL}/pam/manage-voices`, Component: <ManageVoices /> },
  { path: `${process.env.PUBLIC_URL}/pam/manage-public-templates`, Component: <ManagePublicTemplate /> },
  { path: `${process.env.PUBLIC_URL}/pam/manage-videos`, Component: <ManageVideos /> },
  { path: `${process.env.PUBLIC_URL}/pam/manage-logics`, Component: <ManageLogics /> },
  { path: `${process.env.PUBLIC_URL}/pam/manage-templates`, Component: <ManageTemplates /> },
  { path: `${process.env.PUBLIC_URL}/utility/terminal`, Component: <Terminal /> },
  { path: `${process.env.PUBLIC_URL}/support-ticket/supportTicket`, Component: <SupportTicket /> },
  { path: `${process.env.PUBLIC_URL}/cam/manage-orgs`, Component: <ManageOrgs /> },
];
