import { GET_LOGICS_ERROR, GET_LOGICS_LOADING, GET_LOGICS_SUCCESS, GET_TEMPLATES_ERROR, GET_TEMPLATES_LOADING, GET_TEMPLATES_SUCCESS, GET_VIDEOS_ERROR, GET_VIDEOS_LOADING, GET_VIDEOS_SUCCESS, PATCH_LOGICS_ERROR, PATCH_LOGICS_LOADING, PATCH_LOGICS_SUCCESS, PATCH_TEMPLATES_ERROR, PATCH_TEMPLATES_LOADING, PATCH_TEMPLATES_SUCCESS, PATCH_VIDEOS_ERROR, PATCH_VIDEOS_LOADING, PATCH_VIDEOS_SUCCESS } from "./media.types"

 const initialState = {
    videos_loading:false,
    logics_loading:false,
    templates_loading:false,
    videos_error:false,
    logics_error:false,
    templates_error:false,
    videos_list:{},
    logics_list:{},
    templates_list:{}
 }

 export const mediaReducer = (state = initialState, {type, payload}) => {

    switch (type) {
        
        case GET_VIDEOS_LOADING: {

            return {...state, videos_loading:true}
        }

        case GET_VIDEOS_SUCCESS: {

            return {...state, videos_loading:false, videos_error:false, videos_list:payload}
        }

        case GET_VIDEOS_ERROR: {

            return {...state, videos_loading:false, videos_error:true}
        }

        case GET_LOGICS_LOADING: {

            return {...state, logics_loading:true}
        }

        case GET_LOGICS_SUCCESS: {

            return {...state, logics_loading:false, logics_error:false, logics_list:payload}
        }

        case GET_LOGICS_ERROR: {

            return {...state, logics_loading:false, logics_error:true}
        }

        case GET_TEMPLATES_LOADING: {

            return {...state, templates_loading:true}
        }

        case GET_TEMPLATES_SUCCESS: {

            return {...state, templates_loading:false, templates_error:false, templates_list:payload}
        }

        case GET_TEMPLATES_ERROR: {

            return {...state, templates_loading:false, templates_error:true}
        }

        case PATCH_LOGICS_LOADING: {
            return {...state, logics_loading:true}
        }

        case PATCH_LOGICS_SUCCESS:{
            return {...state, logics_loading:false, logics_error:false}
        }

        case PATCH_LOGICS_ERROR:{
            return {...state, logics_loading:false, logics_error:true}
        }

    
        case PATCH_VIDEOS_LOADING: {
            return {...state, videos_loading:true}
        }

        case PATCH_VIDEOS_SUCCESS:{
            return {...state, videos_loading:false, videos_error:false}
        }

        case PATCH_VIDEOS_ERROR:{
            return {...state, videos_loading:false, videos_error:true}
        }

        case PATCH_TEMPLATES_LOADING: {
            return {...state, templates_loading:true}
        }

        case PATCH_TEMPLATES_SUCCESS:{
            return {...state, templates_loading:false, templates_error:false}
        }

        case PATCH_TEMPLATES_ERROR:{
            return {...state, templates_loading:false, templates_error:true}
        }

    

        default: {
            return state
        }
    }
 }