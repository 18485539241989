import axios from "axios"
import { REGULATOR_URL } from "../baseUrl"
import { activate_deactivate_user, edit_org_credits, get_job_health, get_job_logs, get_organisation_details, verifyToken, verify_unverify_user } from "../endpoints"
import { APPLICATION_JSON, CONTENT_TYPE, X_ACCESS_TOKEN } from "../utils"
import { getTokenFromLocal } from "./helper"

export const verifyTokenFunc = async (token) => {

    const res = await axios.get(REGULATOR_URL + verifyToken, {
        headers: {
            "Content-Type": "application/json",
            "x-access-token": token
        }
    })

    return res

}

export const get_job_health_request = async (id) => {

    try {
        const res = await axios.get(REGULATOR_URL + get_job_health(id), {
            headers: {
                [CONTENT_TYPE]: APPLICATION_JSON,
                [X_ACCESS_TOKEN]: getTokenFromLocal()
            }
        })
        // console.log(res, "health logs res")
        return res
    } catch (error) {

    }

}


export const get_job_logs_request = async (id) => {

    try {
        const res = await axios.get(REGULATOR_URL + get_job_logs(id), {
            headers: {
                [CONTENT_TYPE]: APPLICATION_JSON,
                [X_ACCESS_TOKEN]: getTokenFromLocal()
            }
        })
        // console.log(res, "job logs res")
        return res
    } catch (error) {

    }

}


export const get_org_details_request = async (id) => {

    try {
        const res = await axios.get(REGULATOR_URL + get_organisation_details(id), {
            headers: {
                [CONTENT_TYPE]: APPLICATION_JSON,
                [X_ACCESS_TOKEN]: getTokenFromLocal()
            }
        })
        //  console.log(res, "job logs res")
        return res
    } catch (error) {
        //  console.log(error, "org_error")
    }

}

export const patch_activate_deactivate_user = async (email, state) => {

    try {

        const res = await axios({
            method:"patch",
            url:REGULATOR_URL + activate_deactivate_user(email, state),
            headers:{
                [X_ACCESS_TOKEN]:getTokenFromLocal()
            },
        
        })
       
        // console.log(res, "patch_user_active")
        return res
    } catch (error) {
        // console.log(error, "org_error")
    }

}


export const patch_verify_unverify_user = async (email, state) => {

    try {

        const res = await axios({
            method:"patch",
            url:REGULATOR_URL + verify_unverify_user(email, state),
            headers:{
                [X_ACCESS_TOKEN]:getTokenFromLocal()
            },
        
        })
       
        // console.log(res, "patch_user_active")
        return res
    } catch (error) {
        // console.log(error, "org_error")
    }

}


export const patch_edit_org_credits_user = async (id, state) => {

    try {

        const res = await axios({
            method:"patch",
            url:REGULATOR_URL + edit_org_credits(id, state),
            headers:{
                [X_ACCESS_TOKEN]:getTokenFromLocal()
            },
        
        })
       
        // console.log(res, "patch_user_active")
        return res
    } catch (error) {
        // console.log(error, "org_error")
    }

}


