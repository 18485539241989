import { get_users_list_res } from "./user.api"
import { GET_USER_ERROR, GET_USER_LOADING, GET_USER_SUCCESS } from "./user.types"

export const get_users_list_api = (page, count) => async(dispatch) => {

    dispatch({type:GET_USER_LOADING})
    try {
        const res = await get_users_list_res(page, count)
        dispatch({type:GET_USER_SUCCESS, payload:res.data})
    } catch (error) {
        dispatch({type:GET_USER_ERROR})
    }

}