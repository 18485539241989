import axios from "axios"
import { get_admin_logs, get_event_logs, get_job_health, get_job_logs } from "../../constant/endpoints"
import { REGULATOR_URL } from "../../constant/baseUrl"
import { APPLICATION_JSON, CONTENT_TYPE, X_ACCESS_TOKEN } from "../../constant/utils"
import { getTokenFromLocal } from "../../constant/scripts/helper"

export const get_admin_logs_res = async (page) => {

    const res = await axios.get(REGULATOR_URL + get_admin_logs(page), {
        headers: {
            [CONTENT_TYPE]: APPLICATION_JSON,
            [X_ACCESS_TOKEN]: getTokenFromLocal()
        }
    })
    // console.log(res, "logs res")
    return res

}

export const get_event_logs_res = async () => {

    const res = await axios.get(REGULATOR_URL + get_event_logs, {
        headers: {
            [CONTENT_TYPE]: APPLICATION_JSON,
            [X_ACCESS_TOKEN]: getTokenFromLocal()
        }
    })
    // console.log(res, "evnt logs res")
    return res

}


export const get_job_health_res = async (id) => {

    const res = await axios.get(REGULATOR_URL + get_job_health(id), {
        headers: {
            [CONTENT_TYPE]: APPLICATION_JSON,
            [X_ACCESS_TOKEN]: getTokenFromLocal()
        }
    })
    // console.log(res, "health logs res")
    return res

}


export const get_job_logs_res = async (id) => {

    const res = await axios.get(REGULATOR_URL + get_job_logs(id), {
        headers: {
            [CONTENT_TYPE]: APPLICATION_JSON,
            [X_ACCESS_TOKEN]: getTokenFromLocal()
        }
    })
    // console.log(res, "job logs res")
    return res

}