import { get_avatars_list_res, get_bots_list_res, get_public_template_list_res, get_voices_list_res, patch_avatar_res, patch_bot_res, patch_public_template_res, patch_voice_res, post_avatar_res, post_public_template_res, post_voice_res, search_avatar_list_res, search_bots_list_res, search_public_template_list_res, search_voices_list_res } from "./assets.api"
import { GET_AVATARS_ERROR, GET_AVATARS_LOADING, GET_AVATARS_SUCCESS, GET_BOTS_ERROR, GET_BOTS_LOADING, GET_BOTS_SUCCESS, GET_PT_ERROR, GET_PT_LOADING, GET_PT_SUCCESS, GET_VOICES_ERROR, GET_VOICES_LOADING, GET_VOICES_SUCCESS, PATCH_AVATARS_ERROR, PATCH_AVATARS_LOADING, PATCH_AVATARS_SUCCESS, PATCH_BOTS_ERROR, PATCH_BOTS_LOADING, PATCH_BOTS_SUCCESS, PATCH_PT_ERROR, PATCH_PT_LOADING, PATCH_PT_SUCCESS, PATCH_VOICES_ERROR, PATCH_VOICES_LOADING, PATCH_VOICES_SUCCESS, POST_AVATARS_ERROR, POST_AVATARS_LOADING, POST_AVATARS_SUCCESS, POST_PT_ERROR, POST_PT_LOADING, POST_PT_SUCCESS, POST_VOICES_ERROR, POST_VOICES_LOADING, POST_VOICES_SUCCESS } from "./assets.types"

export const get_avatars_list_api = (page, count) => async(dispatch) => {

    dispatch({type:GET_AVATARS_LOADING})
    try {
        const res = await get_avatars_list_res(page, count)
        dispatch({type:GET_AVATARS_SUCCESS, payload:res.data})
    } catch (error) {
        dispatch({type:GET_AVATARS_ERROR})
    }

}


export const get_bots_list_api = (page, count) => async(dispatch) => {

    dispatch({type:GET_BOTS_LOADING})
    try {
        const res = await get_bots_list_res(page, count)
        dispatch({type:GET_BOTS_SUCCESS, payload:res.data})
    } catch (error) {
        dispatch({type:GET_BOTS_ERROR})
    }

}

export const get_voices_list_api = (page, count) => async(dispatch) => {

    dispatch({type:GET_VOICES_LOADING})
    try {
        const res = await get_voices_list_res(page, count)
        dispatch({type:GET_VOICES_SUCCESS, payload:res.data})
    } catch (error) {
        dispatch({type:GET_VOICES_ERROR})
    }

}


export const get_public_template_list_api = (page, count) => async(dispatch) => {

    dispatch({type:GET_PT_LOADING})
    try {
        const res = await get_public_template_list_res(page, count)
        dispatch({type:GET_PT_SUCCESS, payload:res.data})
    } catch (error) {
        dispatch({type:GET_PT_ERROR})
    }

}

export const search_avatar_list_api = (query, type) => async(dispatch) => {

    dispatch({type:GET_AVATARS_LOADING})
    try {
        const res = await search_avatar_list_res(query, type)
        dispatch({type:GET_AVATARS_SUCCESS, payload:res.data})
    } catch (error) {
        dispatch({type:GET_AVATARS_ERROR})
    }

}


export const search_voices_list_api = (query, type) => async(dispatch) => {

    dispatch({type:GET_VOICES_LOADING})
    try {
        const res = await search_voices_list_res(query, type)
        dispatch({type:GET_VOICES_SUCCESS, payload:res.data})
    } catch (error) {
        dispatch({type:GET_VOICES_ERROR})
    }

}


export const search_bots_list_api = (query, type) => async(dispatch) => {

    dispatch({type:GET_BOTS_LOADING})
    try {
        const res = await search_bots_list_res(query, type)
        dispatch({type:GET_BOTS_SUCCESS, payload:res.data})
    } catch (error) {
        dispatch({type:GET_BOTS_ERROR})
    }

}


export const search_public_template_list_api = (query, type) => async(dispatch) => {

    dispatch({type:GET_PT_LOADING})
    try {
        const res = await search_public_template_list_res(query, type)
        dispatch({type:GET_PT_SUCCESS, payload:res.data})
        // console.log(res)

    } catch (error) {
        // console.log(error)
        dispatch({type:GET_PT_ERROR})
    }

}

export const patch_avatar_api = (formdata, SweetAlert) => async(dispatch) => {
    dispatch({type:PATCH_AVATARS_LOADING})
    try {
         const res =await patch_avatar_res(formdata)
        dispatch({type:PATCH_AVATARS_SUCCESS})
        if (res.data === "Avatar updated successfully") {
            SweetAlert.fire({title:"Good job !", text:res.data, icon:"success"});      
        }
    } catch (error) {
        dispatch({type:PATCH_AVATARS_ERROR})
        SweetAlert.fire({title:"Failed!", text:JSON.parse(error.request.response).detail, icon:"error"})
    }

}


export const patch_bot_api = (formdata, SweetAlert) => async(dispatch) => {
    dispatch({type:PATCH_BOTS_LOADING})
    try {
         const res =await patch_bot_res(formdata)
        dispatch({type:PATCH_BOTS_SUCCESS})
        if (res.data === "bot updated successfully") {
            SweetAlert.fire({title:"Good job !", text:res.data, icon:"success"})      
        }
    } catch (error) {
        dispatch({type:PATCH_BOTS_ERROR})
        SweetAlert.fire({title:"Failed!", text:JSON.parse(error.request.response).detail, icon:"error"})
    }

}


export const patch_voice_api = (formdata, SweetAlert) => async(dispatch) => {
    dispatch({type:PATCH_VOICES_LOADING})
    try {
         const res =await patch_voice_res(formdata)
        dispatch({type:PATCH_VOICES_SUCCESS})
        if (res.data === "Voice updated successfully") {
            SweetAlert.fire({title:"Good job !", text:res.data, icon:"success"});      
        }
    } catch (error) {
        dispatch({type:PATCH_VOICES_ERROR})
        SweetAlert.fire({title:"Failed!", text:JSON.parse(error.request.response).detail, icon:"error"})
    }

}


export const patch_public_template_api = (formdata, SweetAlert) => async(dispatch) => {
    dispatch({type:PATCH_PT_LOADING})
    try {
         const res =await patch_public_template_res(formdata)
        dispatch({type:PATCH_PT_SUCCESS})
        if (res.data === "Template updated successfully") {
            SweetAlert.fire({title:"Good job !", text:res.data, icon:"success"});      
        }
    } catch (error) {
        dispatch({type:PATCH_PT_ERROR})
        console.log(error)
        SweetAlert.fire({title:"Failed!", text:JSON.parse(error.request.response).detail, icon:"error"})
    }

}


export const post_voice_api = (formdata, SweetAlert) => async(dispatch) => {
    dispatch({type:POST_VOICES_LOADING})
    try {
         const res =await post_voice_res(formdata)
        dispatch({type:POST_VOICES_SUCCESS})
        if (res.data === "Voice created successfully") {
            SweetAlert.fire({title:"Good job !", text:res.data, icon:"success"});      
        }
    } catch (error) {
        dispatch({type:POST_VOICES_ERROR})
        console.log(error)
        SweetAlert.fire({title:"Failed!", text:JSON.parse(error.request.response).detail, icon:"error"})
    }

}


export const post_avatar_api = (formdata, SweetAlert) => async(dispatch) => {
    dispatch({type:POST_AVATARS_LOADING})
    try {
         const res =await post_avatar_res(formdata)
        dispatch({type:POST_AVATARS_SUCCESS})
        if (res.data === "Avatar created successfully") {
            SweetAlert.fire({title:"Good job !", text:res.data, icon:"success"});      
        }
    } catch (error) {
        dispatch({type:POST_AVATARS_ERROR})
        console.log(error)
        SweetAlert.fire({title:"Failed!", text:JSON.parse(error.request.response).detail, icon:"error"})
    }

}


export const post_public_template_api = (formdata, SweetAlert, page) => async(dispatch) => {
    dispatch({type:POST_PT_LOADING})
    try {
         const res =await post_public_template_res(formdata)
        dispatch({type:POST_PT_SUCCESS})
        if (res.data === "Template created successfully") {
            SweetAlert.fire({title:"Good job !", text:res.data, icon:"success"});
            dispatch(get_public_template_list_api(page))      
        }
    } catch (error) {
        dispatch({type:POST_PT_ERROR})
        // console.log(error)
        SweetAlert.fire({title:"Failed!", text:JSON.parse(error.request.response).detail, icon:"error"})
    }

}

