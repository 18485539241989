import React, { Fragment, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import Breadcrumb from '../../../components/common/breadcrumb'
import { useDispatch, useSelector } from 'react-redux'
import { get_bots_list_api, patch_bot_api, search_bots_list_api } from '../../../redux/assets/assets.actions'
import BotActionPanel from '../../../components/custom/ActionPanels/BotActionPanel'
import { Copy, Frown } from 'react-feather'
import { CENTER, FLEX, POINTER } from '../../../constant/typograpy/properties'
import { convertUTCToIST, copyContent } from '../../../constant/scripts/helper'
import { useSearchParams } from 'react-router-dom'
import Paginate from '../../../components/custom/AdvanceComp/Paginate'
import "../../styles/styles.css"
import SplitLoader from '../../../components/custom/SplitLoader'
import SweetAlert from "sweetalert2"
import SearchBar from '../../../components/custom/AdvanceComp/SearchBar'


const filterList = [
    { name: "User ID", value: "user_id" },
    { name: "ID", value: "id" },
    { name: "Name", value: "name" },
]

const ManageBots = () => {
    const { bots_list, bots_loading, bots_error } = useSelector((state) => state.assetsManager)
    const [botsData, setBotsData] = useState([])
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const [page, setPage] = useState(Number(searchParams.get("page")) || 1)
    const [visible, setVisible] = useState(false)
    const [query, setQuery] = useState(searchParams.get("query") || "")
    const [search, setSearch] = useState(searchParams.get("type") || filterList[2].value)
    const [refresh, setRefresh] = useState(false)
    useEffect(() => {
        setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
    }, [page])

    useEffect(() => {
        if (query.trim() !== "") {
            dispatch(search_bots_list_api(query.trim(), search)) // search all avatars info
        } else {
            dispatch(get_bots_list_api(page)) // gives all bots info

        }
    }, [searchParams, search, refresh])

    const handlePatch = (formdata) => {
        dispatch(patch_bot_api(formdata, SweetAlert)).then(() => {
            setBotsData([])
            setRefresh((prev) => !prev)
        }).catch((e) => {
            console.log(e)
        })
    }

    useEffect(() => {

        setBotsData(() => bots_list.bots_list?.map((el, i) => {
            let copyId = el.id
            let copyVId = el.VAid
            el.botId = <span key={i + "copy-bot-id"}><Copy size={12} style={{ cursor: POINTER }} onClick={() => {
                copyContent(copyId)
            }} /> <span>{copyId}</span></span>
            el.botVaId = <span key={i + "copy-bots-id"}><Copy size={12} style={{ cursor: POINTER }} onClick={() => {
                copyContent(copyVId)
            }} /> <span>{copyVId}</span></span>
            el.image =  <BotActionPanel keyy={i + "bot"} id={el.id} el={el} actions={{handlePatch}} element={<Frown />} /> 
            el.action = <BotActionPanel keyy={i + "bott"} id={el.id} el={el} actions={{handlePatch}} />
            return el
        }))

    }, [bots_list])

    // console.log(bots_list)


    const columns = [

        {
            name: "Image",
            selector: (row) => row.image,
            sortable: true,
            center: true,
        },

        {
            name: "VAid",
            selector: (row) => row.botVaId,
            sortable: true,
            center: true,
        },
        {
            name: "id",
            selector: (row) => row.botId,
            sortable: true,
            center: true,
        },
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            center: true,
        },
        {
            name: "Created At",
            selector: (row) => convertUTCToIST(row.created_at),
            sortable: true,
            center: true,
        },
        {
            name: "Action",
            selector: (row) => row.action,
            sortable: true,
            center: true,
        },
    ]

    
    const handleSearch = (e) => {
        if (e.key === "Enter") {
            setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
        }
    }

    useEffect(() => {
        if (query === "") {
            dispatch(get_bots_list_api(page)) // gives all avatars info
            setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
        } 
    }, [query])

    useEffect(() => {
        setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
    }, [search])

    return (
        <Fragment>
            <Breadcrumb title="Manage Bots" parent="PAM" />
            <div className="container-fluid">
                <div className="row">
                    {/* <!-- Individual column searching (text inputs) Starts--> */}
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>{"Manage all the bots from here"} </h5><span>{""}</span>

                            </div>
                            <div style={{ margin: "16px" }}>
                                <SearchBar filters={true} top={80} visible={visible} setVisible={setVisible} filterOptions={filterList} query={query} setQuery={setQuery} search={search} setSearch={setSearch} onKeyDown={handleSearch} />
                            </div>
                            <div className="card-body">
                                <div className="table-responsive product-table">
                                {bots_loading ? <SplitLoader center={true} /> : <DataTable
                                        noHeader
                                        className='no-scroll-body'
                                        data={botsData}
                                        columns={columns}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Individual column searching (text inputs) Ends--> */}
                </div>
            </div>
            <div style={{ display: FLEX, justifyContent: "end", alignItems: CENTER, margin: "8px" }}>

                {bots_list.total_bots !== undefined && <Paginate page={page} setPage={setPage} totalPage={Math.ceil(bots_list.total_bots / 10)} />}
                
            </div>
        </Fragment>
    )
}

export default ManageBots