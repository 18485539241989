import { Search } from "react-feather";
import { ALICEBLUE, BLUE, GAINSBORO, GRAY, RED, TRANSPARENT, WHITE } from "../../../constant/typograpy/colors";
import { ABSOLUTE, BLOCK, CENTER, FILL_60PARENT, FILL_80PARENT, FILL_90PARENT, FILL_PARENT, FLEX, NONE, RELATIVE } from "../../../constant/typograpy/properties";
import { useEffect, useState } from "react";

export default function SearchBar({ visible, setVisible, top, filters, filterOptions, query, setQuery, search, setSearch, onKeyDown }) {
    useEffect(() => {
        // console.log(query)
        if (query !== "") {
            setVisible(false)
        } else {
            setVisible(false)
        }

    }, [query])

    const filtersGroup = filterOptions?.map((el, i) => <button name={el.value} key={"filter" + i} type="button" style={{ backgroundColor: el.value === search ? BLUE : TRANSPARENT, color: el.value === search ? WHITE : BLUE, border:"1px solid blue"}}>{el.name}</button>)
    // console.log(search)
    return <div style={{ display: FLEX, alignItems: CENTER, borderRadius: "50px", height: "50px", border: `1px solid ${GAINSBORO}`, backgroundColor: ALICEBLUE, gap: '8px', position: RELATIVE }}>
        <Search style={{ padding: "4px", marginLeft: '8px' }} size={32} color={GRAY} />

        <div style={{ width: "1px", height: FILL_60PARENT, backgroundColor: GAINSBORO }}></div>

        <input onKeyDown={onKeyDown} value={query} onChange={(e) => setQuery(e.target.value)} placeholder="Seacrh..." style={{ width: FILL_PARENT, height: FILL_PARENT, outline: NONE, border: NONE, borderRadius: "50px", backgroundColor: TRANSPARENT }}></input>

        <div style={{ position: ABSOLUTE, top: 55, display: filters ? BLOCK : NONE, right: 10, zIndex: 10 }}>
                <div style={{ display: FLEX, alignItems: CENTER }} onClick={(e) => {
                    setSearch(e.target.name)
                }}>
                    {filtersGroup}
            </div>
        </div>

        <div style={{ width: FILL_PARENT, position: ABSOLUTE, top: top, backgroundColor: WHITE, borderRadius: "10px", display: visible ? "block" : "none", height: "100px", zIndex: 100, boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px" }}>
            <center> Opps!! There are no result found </center>

        </div>
    </div>
}