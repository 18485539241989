import { get_admin_logs_res, get_event_logs_res, get_job_health_res, get_job_logs_res } from "./logs.api"
import { GET_ELOGS_ERROR, GET_ELOGS_LOADING, GET_ELOGS_SUCCESS, GET_HLOGS_ERROR, GET_HLOGS_LOADING, GET_HLOGS_SUCCESS, GET_JLOGS_ERROR, GET_JLOGS_LOADING, GET_JLOGS_SUCCESS, GET_LOGS_ERROR, GET_LOGS_LOADING, GET_LOGS_SUCCESS } from "./logs.types"

export const get_admin_logs_api = (page, count) => async(dispatch) => {

    dispatch({type:GET_LOGS_LOADING})
    try {
        const res = await get_admin_logs_res(page, count)
        dispatch({type:GET_LOGS_SUCCESS, payload:res.data})
    } catch (error) {
        dispatch({type:GET_LOGS_ERROR})
    }

}

export const get_event_logs_api = () => async(dispatch) => {

    dispatch({type:GET_ELOGS_LOADING})
    try {
        const res = await get_event_logs_res()
        // console.log(res.data, "my p")
        dispatch({type:GET_ELOGS_SUCCESS, payload:res.data})
    } catch (error) {
        dispatch({type:GET_ELOGS_ERROR})
    }

}


export const get_job_health_logs_api = (id) => async(dispatch) => {

    dispatch({type:GET_HLOGS_LOADING})
    try {
        const res = await get_job_health_res(id)
        dispatch({type:GET_HLOGS_SUCCESS, payload:res.data})
    } catch (error) {
        dispatch({type:GET_HLOGS_ERROR})
    }

}


export const get_job_logs_api = (id) => async(dispatch) => {

    dispatch({type:GET_JLOGS_LOADING})
    try {
        const res = await get_job_logs_res(id)
        dispatch({type:GET_JLOGS_SUCCESS, payload:res.data})
    } catch (error) {
        dispatch({type:GET_JLOGS_ERROR})
    }

}