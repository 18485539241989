import axios from "axios"
import { REGULATOR_URL } from "../../constant/baseUrl"
import { get_overview_stats } from "../../constant/endpoints"
import { getTokenFromLocal } from "../../constant/scripts/helper"
import { APPLICATION_JSON, CONTENT_TYPE, X_ACCESS_TOKEN } from "../../constant/utils"

export const get_overview_res = async () => {
    const res = await axios.get(REGULATOR_URL + get_overview_stats, {
        headers: {
            [CONTENT_TYPE]: APPLICATION_JSON,
            [X_ACCESS_TOKEN]: getTokenFromLocal()
        }
    })
    return res
} 