import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BOLD, CENTER, FILL_10PARENT, FILL_20PARENT, FILL_30PARENT, FILL_50PARENT, FILL_80PARENT, FILL_PARENT, FLEX, POINTER, SB, START, TOP } from "../../../constant/typograpy/properties";
import px from "../../../constant/scripts/px";
import { BLACK, SKYBLUE } from "../../../constant/typograpy/colors";
import UserManager from "../AdvanceComp/UserManger";
import { converIntoFormData, convertUTCToIST } from "../../../constant/scripts/helper";

export default function AvatarActionPanel({ id, actions, el, keyy, element = <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i> }) {
    const [modal, setModal] = useState(false)
    const [gender, setGender] = useState(el.gender)
    const [desc, setDesc] = useState(el.description)
    const [imageLink, setImageLink] = useState(el.previewImage)
    const [allocatedUsers, setAllocatedUsers] = useState(el.users)
    const toggle = () => setModal(!modal)
    // const [dropdownOpen, setDropdownOpen] = useState(false);
    // const toggle = () => setDropdownOpen(prevState => !prevState);

    // console.log(el)
    return <>
        <div key={keyy}>
            {/* <span style={{ cursor: POINTER }}><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></span> */}
            <span>
                <span style={{ cursor: POINTER }} onClick={toggle}>{element}</span>
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>{"Edit Avatar"}</ModalHeader>
                    <ModalBody>
                    <div className="card">
                  <div className="card-header">
                    <h5>{el.displayName.replaceAll("_", " ")}</h5><span>Modify avatar properties here and click on save to make changes.</span>
                  </div>
                        <div className="card-body">
                            <form className="theme-form">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="image">{"Image"}</label>
                                    <div className="col-sm-9">
                                        <div style={{ display: FLEX, justifyContent: "end" }}>
                                            <img className="form-control" src={el.previewImage} style={{ width: "100px" }}></img>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="disName">{"Display Name"}</label>
                                    <div className="col-sm-9">
                                        <p className="form-control">{el.displayName}</p>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="imageLink">{"Image Link"}</label>
                                    <div className="col-sm-9">
                                        <input value={imageLink} onChange={(e) => setImageLink(e.target.value)} className="form-control" id="imageLink" type="text" placeholder="Image Link" />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="createdAt">{"Created at"}</label>
                                    <div className="col-sm-9">
                                        <p className="form-control">{convertUTCToIST(el.created_at)}</p>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="gender">{"Gender"}</label>
                                    <div className="col-sm-9">
                                        <select className="form-control" value={gender} onChange={(e) => setGender(e.target.value)}>
                                            <option value="male">male</option>
                                            <option value="female">female</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="desc">{"Description"}</label>
                                    <div className="col-sm-9">
                                        <textarea value={desc} onChange={(e) => setDesc(e.target.value)} className="form-control" id="desc" type="text" placeholder="Description" />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="manage">{"Manage Users"}</label>
                                    <div className="col-sm-9">
                                        <div className="form-control" style={{ padding: "16px" }}>
                                            <UserManager allocatedUsers={allocatedUsers} setAllocatedUsers={setAllocatedUsers} filterList={[{ name: "ID", value: "id" }, { name: "Email", value: "email" }, { name: "Name", value: "displayName" }]} />
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {

                            actions.handlePatch(converIntoFormData({
                                avatar_id: id,
                                preview_image_link: imageLink,
                                gender: gender,
                                description: desc,
                                users: JSON.stringify(allocatedUsers)
                            }))
                        }}>{"Save"}</Button>
                        <Button color="secondary" onClick={toggle}>{"Cancel"}</Button>
                    </ModalFooter>
                </Modal>
            </span>
        </div>
    </>
}