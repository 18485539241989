import { get_org_list_res, search_orgs_list_res } from "./org.api"
import { GET_ORG_ERROR, GET_ORG_LOADING, GET_ORG_SUCCESS } from "./org.types"

export const get_org_list_api = (page) => async(dispatch) => {

    dispatch({type:GET_ORG_LOADING})
    try {
        const res = await get_org_list_res(page)
        dispatch({type:GET_ORG_SUCCESS, payload:res.data})
    } catch (error) {
        dispatch({type:GET_ORG_ERROR})
    }

}


export const search_orgs_list_api = (query, type) => async(dispatch) => {

    dispatch({type:GET_ORG_LOADING})
    try {
        const res = await search_orgs_list_res(query, type)
        dispatch({type:GET_ORG_SUCCESS, payload:res.data})
    } catch (error) {
        dispatch({type:GET_ORG_ERROR})
    }

}