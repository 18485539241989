import { useState } from "react";
import { Plus } from "react-feather";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { CENTER, FLEX, SB } from "../../../constant/typograpy/properties";
import UserManager from "../AdvanceComp/UserManger";
import Swal from "sweetalert2";
import { converIntoFormData, create_UUID } from "../../../constant/scripts/helper";
import { useDispatch } from "react-redux";
import { post_voice_api } from "../../../redux/assets/assets.actions";
import PreviewAudio from "../AdvanceComp/PreviewAudio";
import { useRef } from "react";
import WhiteFade from "../WhiteFade";
export default function CreateVoice({ loading }) {
  const [modal, setModal] = useState(false)
  const [name, setName] = useState("")
  const [link, setLink] = useState("")
  const [gender, setGender] = useState("male")
  const [language, setLanguage] = useState("")
  const [accent, setAccent] = useState("")
  const [tags, setTags] = useState("")
  const [value, setValue] = useState("")
  const [type, setType] = useState("mechanical")
  const [platform, setPlatform] = useState("")
  const [country, setCountry] = useState("")
  const [voiceId, setVoiceId] = useState("")
  const [allocatedUsers, setAllocatedUsers] = useState([])
  const toggle = () => setModal(!modal)
  const dispatch = useDispatch()
  const handlePost = () => {
    if (name === "" || link === "" || gender === "" || language === "" || accent === "" || type === "" || country === "" || value === "" || voiceId === "") {
      Swal.fire({ title: "Empty Field", html: "All * (star) fields are mandotary !", icon: "error" })
      return
    }

    const formData = converIntoFormData({
      voice_id: voiceId,
      preview_audio_link: link.trim(),
      gender: gender.trim(),
      value: value.trim(),
      label: name.trim(),
      language: language.trim(),
      accent: accent.trim(),
      tags: JSON.stringify(tags.split(",").map((el) => el.trim())),
      type: type.trim(),
      platform: platform.trim(),
      country: country.trim(),
      users: JSON.stringify(allocatedUsers)

    })

    dispatch(post_voice_api(formData, Swal)).then((res) => {
      console.log(res)
      toggle()
    }).catch((e) => {
      console.log(e)
    })
  }

  return <div>
    <Button onClick={toggle}><span style={{ display: FLEX, alignItems: CENTER, gap: "8px" }}><Plus size={24} /> <span> Create Voice </span> </span> <></></Button>
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>{"Create Voice"}</ModalHeader>
      <ModalBody>
        <div className="col-sm-12">
          <div className="card">
            {loading && <WhiteFade loader={true} />}
            <div className="card-header">
              <h5>{"Custom Voice Form"}</h5><span>Use this form to create a new voice.</span>
            </div>
            <div className="card-body">
              <form className="theme-form">

              <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="voiceId">{"Voice ID*"}</label>
                  <div className="col-sm-9">
                    <input value={voiceId} onChange={(e) => setVoiceId(e.target.value)} className="form-control" type="text" id="voiceId" placeholder="Voice ID" />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="name">{"Name*"}</label>
                  <div className="col-sm-9">
                    <input value={name} onChange={(e) => setName(e.target.value)} className="form-control" type="text" id="name" placeholder="Name" />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="previewLink">{"Audio Preview Link"}</label>
                  <div className="col-sm-9">
                    <div style={{ display: FLEX, alignItems: CENTER, justifyContent: SB, gap: "8px" }}>
                      <input className="form-control" value={link} onChange={(e) => setLink(e.target.value)} id="previewLink" type="text" placeholder="Audio Preview Link" />
                      <PreviewAudio src={link} />

                    </div>
                  </div>

                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="Gender">{"Gender*"}</label>
                  <div className="col-sm-9">
                    <select value={gender} onChange={(e) => setGender(e.target.value)} className="form-control">
                      <option value={"male"}>Male</option>
                      <option value={"female"}>Female</option>
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="value">{"Value*"}</label>
                  <div className="col-sm-9">
                    <input value={value} onChange={(e) => setValue(e.target.value)} className="form-control" type="text" id="value" placeholder="Value" />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="language">{"Language*"}</label>
                  <div className="col-sm-9">
                    <input value={language} onChange={(e) => setLanguage(e.target.value)} className="form-control" type="text" id="language" placeholder="Language" />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="accent">{"Accent*"}</label>
                  <div className="col-sm-9">
                    <input value={accent} onChange={(e) => setAccent(e.target.value)} className="form-control" id="accent" type="text" placeholder="Accent" />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="tags">{"Tags"}</label>
                  <div className="col-sm-9">
                    <input value={tags} onChange={(e) => setTags(e.target.value)} className="form-control" id="tags" type="text" placeholder="Enter tags, use ( , ) comma  to separate Eg: Inidan, American etc" />
                  </div>
                </div>


                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="type">{"Type*"}</label>
                  <div className="col-sm-9">
                    <select value={type} onChange={(e) => setType(e.target.value)} className="form-control">
                      <option value={"mechanical"}>Mechanical</option>
                      <option value={"realistic"}>Realistic</option>
                      <option value={"cloned"}>Cloned</option>
                    </select>                        </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="platform">{"Platfrom"}</label>
                  <div className="col-sm-9">
                    <input value={platform} onChange={(e) => setPlatform(e.target.value)} className="form-control" id="platform" type="text" placeholder="Platform" />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="country">{"Country*"}</label>
                  <div className="col-sm-9">
                    <input value={country} onChange={(e) => setCountry(e.target.value)} className="form-control" id="country" type="text" placeholder="Country" />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="users">{"Add Users"}</label>
                  <div className="col-sm-9">
                    <div className="form-control" style={{ padding: "16px" }}>
                      <UserManager allocatedUsers={allocatedUsers} setAllocatedUsers={setAllocatedUsers} filterList={[{ name: "ID", value: "id" }, { name: "Email", value: "email" }, { name: "Name", value: "displayName" }]} />
                    </div>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        {!loading && <button onClick={handlePost} className="btn btn-primary me-1">{"Create"}</button>}
        {!loading && <button onClick={toggle} className="btn btn-secondary">{"Cancel"}</button>}
      </ModalFooter>
    </Modal>
  </div>
}