import { get_overview_res } from "./overview.api"
import { OVERVIEW_ERROR, OVERVIEW_LOADING, OVERVIEW_SUCCESS } from "./overview.types"

export const get_overview_api = () => async (dispatch) => {

    dispatch({ type: OVERVIEW_LOADING })
    try {
        const res = await get_overview_res()
        dispatch({ type: OVERVIEW_SUCCESS, payload: res.data })
    } catch (error) {
        dispatch({ type: OVERVIEW_ERROR })

    }
}