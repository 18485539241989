import { useEffect, useState } from "react";
import { Plus } from "react-feather";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { CENTER, FLEX } from "../../../constant/typograpy/properties";
import UserManager from "../AdvanceComp/UserManger";
import Swal from "sweetalert2";
import { converIntoFormData, create_UUID } from "../../../constant/scripts/helper";
import { useDispatch } from "react-redux";
import { post_voice_api } from "../../../redux/assets/assets.actions";
import WhiteFade from "../WhiteFade";
import { post_admin_api } from "../../../redux/admin/admin.actions";
export default function CreateAdmin({ loading }) {
  const [modal, setModal] = useState(false)
  const [allocatedUsers, setAllocatedUsers] = useState([])
  const toggle = () => setModal(!modal)
  const dispatch = useDispatch()
  const handlePost = () => {

    if (allocatedUsers.length < 1) {
      Swal.fire({ title: "No User Allocated", html: "Please add user to create Admin", icon: "error" })

      return
    }

    const formData = converIntoFormData({
      userId: allocatedUsers[0]
    })

    dispatch(post_admin_api(formData, Swal)).then((res) => {
      console.log(res)
      toggle()
    }).catch((e) => {
      console.log(e)
    })
  }

  useEffect(() => {

    if (allocatedUsers.length > 1) {
      Swal.fire({ title: "Limit exceeded", html: "Cannot add more than one user", icon: "error" })
      setAllocatedUsers((prev) => {
        prev.pop()
        return prev
      })
    }

  }, [allocatedUsers])

  return <div>
    <Button onClick={toggle}><span style={{ display: FLEX, alignItems: CENTER, gap: "8px" }}><Plus size={24} /> <span> Create Admin </span> </span> <></></Button>
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>{"Create Admin"}</ModalHeader>
      <ModalBody>
        <div className="col-sm-12">
          <div className="card">
            {loading && <WhiteFade loader={true} />}
            <div className="card-header">
              <h5>{"Admin Allocation Form"}</h5><span>Use this form to create a new admin.</span>
            </div>
            <div className="card-body">
              <form className="theme-form">

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="users">{"Add Users"}</label>
                  <div className="col-sm-9">
                    <div className="form-control" style={{ padding: "16px" }}>
                      <UserManager allocatedUsers={allocatedUsers} setAllocatedUsers={setAllocatedUsers} filterList={[{ name: "ID", value: "id" }, { name: "Email", value: "email" }, { name: "Name", value: "displayName" }]} />
                    </div>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        {!loading && <button onClick={handlePost} className="btn btn-primary me-1">{"Create"}</button>}
        {!loading && <button onClick={toggle} className="btn btn-secondary">{"Cancel"}</button>}
      </ModalFooter>
    </Modal>
  </div>
}