import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import Breadcrumb from '../../../components/common/breadcrumb'
import { useDispatch, useSelector } from 'react-redux'
import { get_voices_list_api, patch_voice_api, search_voices_list_api } from '../../../redux/assets/assets.actions'
import { Copy, Mic, Play } from 'react-feather'
import { convertUTCToIST, copyContent } from '../../../constant/scripts/helper'
import { CENTER, FLEX, POINTER, SB } from '../../../constant/typograpy/properties'
import { useSearchParams } from 'react-router-dom'
import Paginate from '../../../components/custom/AdvanceComp/Paginate'
import "../../styles/styles.css"
import SearchBar from '../../../components/custom/AdvanceComp/SearchBar'
import SplitLoader from '../../../components/custom/SplitLoader'
import SweetAlert from "sweetalert2"
import VoiceActionPanel from '../../../components/custom/ActionPanels/VoiceActionPanel'
import CreateVoice from '../../../components/custom/CreatePanels/CreateVoice'
import { BLACK, GRAY, WHITE } from '../../../constant/typograpy/colors'
import { useRef } from 'react'
import PreviewAudio from '../../../components/custom/AdvanceComp/PreviewAudio'

const filterList = [
    { name: "User ID", value: "user_id" },
    { name: "ID", value: "id" },
    { name: "Name", value: "name" },
    { name: "Voice ID", value: "voice_id" }
]

const ManageVoices = () => {
    const { voices_list, voices_loading, voices_error } = useSelector((state) => state.assetsManager)
    const [voicesData, setVoicesData] = useState([])
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const [page, setPage] = useState(Number(searchParams.get("page")) || 1)
    const [visible, setVisible] = useState(false)
    const [query, setQuery] = useState(searchParams.get("query") || "")
    const [search, setSearch] = useState(searchParams.get("type") || filterList[2].value)
    const [refresh, setRefresh] = useState(false)
    const audiRef = useRef(null)
    useEffect(() => {
        setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
    }, [page])

    useEffect(() => {

        if (query.trim() !== "") {
            dispatch(search_voices_list_api(query.trim(), search)) // search all voices info
        } else {
            dispatch(get_voices_list_api(page)) // gives all voices info

        }

    }, [searchParams, search, refresh])
    const handlePatch = (formdata) => {
        dispatch(patch_voice_api(formdata, SweetAlert)).then(() => {
            setVoicesData([])
            setRefresh((prev) => !prev)
        }).catch((e) => {
            console.log(e)
        })
        console.log(formdata)
    }

    useEffect(() => {

        setVoicesData(() => voices_list.voices_list?.map((el, i) => {
            let copyId = el.id
            let cvoicId = el.voiceId
            el.aId = <span key={i + "copy-aid-id"}><Copy size={12} style={{ cursor: POINTER }} onClick={() => {
                copyContent(copyId)
            }} /> <span>{copyId}</span></span>
            el.voiId = <span key={i + "copy-voi-id"}><Copy size={12} style={{ cursor: POINTER }} onClick={() => {
                copyContent(cvoicId)
            }} /> <span>{cvoicId}</span></span>
            el.play = <PreviewAudio src={el.previewAudio} audioRef={audiRef} />
            el.action = <VoiceActionPanel keyy={i + "voice"} id={el.id} el={el} actions={{ handlePatch }} />
            return el
        }))

    }, [voices_list])

    // console.log(voices_list)

    const columns = [
        {
            name: "Play",
            selector: (row) => row.play,
            sortable: true,
            center: true,
        },
        {
            name: "ID",
            selector: (row) => row.aId,
            sortable: true,
            center: true,
        },
        {
            name: "Voice ID",
            selector: (row) => row.voiId,
            sortable: true,
            center: true,
        },
        {
            name: "Name",
            selector: (row) => row.label,
            sortable: true,
            center: true,
        },
        {
            name: "Created At",
            selector: (row) => convertUTCToIST(row.created_at),
            sortable: true,
            center: true,
        },
        {
            name: "Gender",
            selector: (row) => row.gender,
            sortable: true,
            center: true,
        },
        {
            name: "Accent",
            selector: (row) => row.accent,
            sortable: true,
            center: true,
        },
        {
            name: "Language",
            selector: (row) => row.language,
            sortable: true,
            center: true,
        },
        {
            name: "Action",
            selector: (row) => row.action,
            sortable: true,
            center: true,
        },
    ]

    const handleSearch = (e) => {
        if (e.key === "Enter") {
            setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
        }
    }

    useEffect(() => {
        if (query.trim() === "") {
            dispatch(get_voices_list_api(page)) // gives all voices info
            setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
        }
    }, [query])

    useEffect(() => {
        setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
    }, [search])

    return (
        <div>
            <Breadcrumb title="Manage Voices" parent="PAM" />
            <div className="container-fluid">
                <div className="row">
                    {/* <!-- Individual column searching (text inputs) Starts--> */}
                    <div className="col-sm-12">
                        <div className="card">
                            <div style={{ display: FLEX, alignItems: CENTER, justifyContent: SB }}>
                                <div className="card-header">
                                    <h5>{"Manage all the voices from here"} </h5><span>{""}</span>
                                </div>
                                <span style={{marginRight:"16px"}}><CreateVoice loading={voices_loading} /></span>
                            </div>

                            <div style={{ margin: "16px" }}>
                                <SearchBar filters={true} top={80} visible={visible} setVisible={setVisible} filterOptions={filterList} query={query} setQuery={setQuery} search={search} setSearch={setSearch} onKeyDown={handleSearch} />
                            </div>
                            <div className="card-body">
                                <div className="table-responsive product-table">
                                    {voices_loading ? <SplitLoader center={true} /> : <DataTable
                                        noHeader
                                        className='no-scroll-body'
                                        data={voicesData}
                                        columns={columns}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Individual column searching (text inputs) Ends--> */}
                </div>
            </div>
            <div style={{ display: FLEX, justifyContent: "end", alignItems: CENTER, margin: "8px" }}>
                {voices_list.total_voices !== undefined && <Paginate page={page} setPage={setPage} totalPage={Math.ceil(voices_list.total_voices / 10)} />}
            </div>
        </div>
    )
}

export default ManageVoices