export const GET_ADMIN_LOADING = "get/admin/loading"
export const GET_ADMIN_ERROR = "get/admin/error"
export const GET_ADMIN_SUCCESS = "get/admin/success"


export const PATCH_ADMIN_LOADING = "patch/admin/loading"
export const PATCH_ADMIN_ERROR = "patch/admin/error"
export const PATCH_ADMIN_SUCCESS = "patch/admin/success"


export const POST_ADMIN_LOADING = "post/admin/loading"
export const POST_ADMIN_ERROR = "post/admin/error"
export const POST_ADMIN_SUCCESS = "post/admin/success"

export const GET_IS_ADMIN_LOADING = "get/isadmin/loading"
export const GET_IS_ADMIN_SUCCESS = "get/isadmin/success"
export const GET_IS_ADMIN_ERROR = "get/isadmin/error"

