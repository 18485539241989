import { GET_AVATARS_ERROR, GET_AVATARS_LOADING, GET_AVATARS_SUCCESS, GET_BOTS_ERROR, GET_BOTS_LOADING, GET_BOTS_SUCCESS, GET_PT_ERROR, GET_PT_LOADING, GET_PT_SUCCESS, GET_VOICES_ERROR, GET_VOICES_LOADING, GET_VOICES_SUCCESS, PATCH_AVATARS_ERROR, PATCH_AVATARS_LOADING, PATCH_AVATARS_SUCCESS, PATCH_BOTS_ERROR, PATCH_BOTS_LOADING, PATCH_BOTS_SUCCESS, PATCH_PT_ERROR, PATCH_PT_LOADING, PATCH_PT_SUCCESS, PATCH_VOICES_ERROR, PATCH_VOICES_LOADING, PATCH_VOICES_SUCCESS, POST_AVATARS_ERROR, POST_AVATARS_LOADING, POST_AVATARS_SUCCESS, POST_PT_ERROR, POST_PT_LOADING, POST_PT_SUCCESS, POST_VOICES_ERROR, POST_VOICES_LOADING, POST_VOICES_SUCCESS } from "./assets.types"

const initialState = {
    avatars_loading: false,
    avatars_error: false,
    avatars_list: {},

    bots_loading: false,
    bots_error: false,
    bots_list: {},

    voices_loading: false,
    voices_error: false,
    voices_list: {},

    pt_loading: false,
    pt_error: false,
    pt_list: {}
}

export const assetsReducer = (state = initialState, { type, payload }) => {

    switch (type) {

        //get avatars

        case GET_AVATARS_LOADING: {

            return { ...state, avatars_loading: true }
        }

        case GET_AVATARS_SUCCESS: {

            return { ...state, avatars_loading: false, avatars_error: false, avatars_list: payload }
        }

        case GET_AVATARS_ERROR: {

            return { ...state, avatars_loading: false, avatars_error: true }
        }

        //get bots

        case GET_BOTS_LOADING: {

            return { ...state, bots_loading: true }
        }

        case GET_BOTS_SUCCESS: {

            return { ...state, bots_loading: false, bots_error: false, bots_list: payload }
        }

        case GET_BOTS_ERROR: {

            return { ...state, bots_loading: false, bots_error: true }
        }

        //get voices

        case GET_VOICES_LOADING: {

            return { ...state, voices_loading: true }
        }

        case GET_VOICES_SUCCESS: {

            return { ...state, voices_loading: false, voices_error: false, voices_list: payload }
        }

        case GET_VOICES_ERROR: {

            return { ...state, voices_loading: false, voices_error: true }
        }

        //get public templates

        case GET_PT_LOADING: {

            return { ...state, pt_loading: true }
        }

        case GET_PT_SUCCESS: {

            return { ...state, pt_loading: false, pt_error: false, pt_list: payload }
        }

        case GET_PT_ERROR: {

            return { ...state, pt_loading: false, pt_error: true }
        }

        //patch avatars

        case PATCH_AVATARS_LOADING: {
            return { ...state, avatars_loading: true }
        }

        case PATCH_AVATARS_SUCCESS: {
            return { ...state, avatars_loading: false, avatars_error: false }
        }

        case PATCH_AVATARS_ERROR: {
            return { ...state, avatars_loading: false, avatars_error: true }
        }

        //patch bots

        case PATCH_BOTS_LOADING: {
            return { ...state, bots_loading: true }
        }

        case PATCH_BOTS_SUCCESS: {
            return { ...state, bots_loading: false, bots_error: false }
        }

        case PATCH_BOTS_ERROR: {
            return { ...state, bots_loading: false, bots_error: true }
        }

        //patch voices

        case PATCH_VOICES_LOADING: {
            return { ...state, voices_loading: true }
        }

        case PATCH_VOICES_SUCCESS: {
            return { ...state, voices_loading: false, voices_error: false }
        }

        case PATCH_VOICES_ERROR: {
            return { ...state, voices_loading: false, voices_error: true }
        }

        //patch public templates

        case PATCH_PT_LOADING: {
            return { ...state, pt_loading: true }
        }

        case PATCH_PT_SUCCESS: {
            return { ...state, pt_loading: false, pt_error: false }
        }

        case PATCH_PT_ERROR: {
            return { ...state, pt_loading: false, pt_error: true }
        }

        // post voices

        case POST_VOICES_LOADING: {
            return { ...state, voices_loading: true }
        }

        case POST_VOICES_SUCCESS: {
            return { ...state, voices_loading: false, voices_error: false }
        }

        case POST_VOICES_ERROR: {
            return { ...state, voices_loading: false, voices_error: true }
        }

        //post avatars

        case POST_AVATARS_LOADING: {
            return { ...state, avatars_loading: true }
        }

        case POST_AVATARS_SUCCESS: {
            return { ...state, avatars_loading: false, avatars_error: false }
        }

        case POST_AVATARS_ERROR: {
            return { ...state, avatars_loading: false, avatars_error: true }
        }

        //post public templates

        
        case POST_PT_LOADING: {
            return { ...state, pt_loading: true }
        }

        case POST_PT_SUCCESS: {
            return { ...state, pt_loading: false, pt_error: false }
        }

        case POST_PT_ERROR: {
            return { ...state, pt_loading: false, pt_error: true }
        }

        default: {
            return state
        }
    }
}