import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import Breadcrumb from '../../../components/common/breadcrumb'
import { useDispatch, useSelector } from 'react-redux'
import { Copy, Layout } from 'react-feather'
import { convertUTCToIST, copyContent } from '../../../constant/scripts/helper'
import { CENTER, FLEX, POINTER } from '../../../constant/typograpy/properties'
import { useSearchParams } from 'react-router-dom'
import Paginate from '../../../components/custom/AdvanceComp/Paginate'
import "../../styles/styles.css"
import SearchBar from '../../../components/custom/AdvanceComp/SearchBar'
import SplitLoader from '../../../components/custom/SplitLoader'
import SweetAlert from "sweetalert2"
import AvatarActionPanel from '../../../components/custom/ActionPanels/AvatarActionPanel'
import { get_video_templates_list_api, patch_video_template_api, search_video_templates_list_api } from '../../../redux/media/media.actions'
import TemplateActionPanel from '../../../components/custom/ActionPanels/TemplateActionPanel'

const filterList = [
    { name: "User ID", value: "user_id" },
    { name: "ID", value: "id" },
    { name: "Template ID", value: "template_id" },
    { name: "Name", value: "name" },
]

const ManageTemplates = () => {
    const { templates_list, templates_loading, templates_error } = useSelector((state) => state.mediaManager)
    const [templatesData, setTemplatesData] = useState([])
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const [page, setPage] = useState(Number(searchParams.get("page")) || 1)
    const [visible, setVisible] = useState(false)
    const [query, setQuery] = useState(searchParams.get("query") || "")
    const [search, setSearch] = useState(searchParams.get("type") || filterList[3].value)
    const [refresh, setRefresh] = useState(false)
    useEffect(() => {
        setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
    }, [page])

    useEffect(() => {

        if (query.trim() !== "") {
            dispatch(search_video_templates_list_api(query.trim(), search)) // search all video templates info
        } else {
            dispatch(get_video_templates_list_api(page)) // gives all video templates info

        }

    }, [searchParams, search, refresh])
    const handlePatch = (formdata) => {
        dispatch(patch_video_template_api(formdata, SweetAlert)).then(() => {
            setTemplatesData([])
            setRefresh((prev) => !prev)
        }).catch((e) => {
            console.log(e)
        })
    }

    useEffect(() => {

        setTemplatesData(() => templates_list.video_templates_list?.map((el, i) => {
            let copyId = el.templateId
            el.tempId = <span key={i + "copy-temp-id"}><Copy size={12} style={{ cursor: POINTER }} onClick={() => {
                copyContent(copyId)
            }} /> <span>{copyId}</span></span>
            el.image =  <TemplateActionPanel keyy={i + "temp"} id={el.id} el={el} actions={{handlePatch}} element={<Layout />} /> 
            el.action = <TemplateActionPanel keyy={i + "tempp"} id={el.id} el={el} actions={{handlePatch}} />
            return el
        }))

    }, [templates_list])

    // console.log(templates_list)

    const columns = [
        {
            name: "Image",
            selector: (row) => row.image,
            sortable: true,
            center: true,
        },
        {
            name: "Template ID",
            selector: (row) => row.tempId,
            sortable: true,
            center: true,
        },
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            center: true,
        },
        {
            name: "Created At",
            selector: (row) => convertUTCToIST(row.created_at),
            sortable: true,
            center: true,
        },

        {
            name: "Action",
            selector: (row) => row.action,
            sortable: true,
            center: true,
        },
    ]

    const handleSearch = (e) => {
        if (e.key === "Enter") {
            setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
        }
    }

    useEffect(() => {
        if (query === "") {
            dispatch(get_video_templates_list_api(page)) // gives all video templates info
            setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
        } 
    }, [query])

    useEffect(() => {
        setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
    }, [search])


    return (
        <div>
            <Breadcrumb title="Manage Templates" parent="PAM" />
            <div className="container-fluid">
                <div className="row">
                    {/* <!-- Individual column searching (text inputs) Starts--> */}
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">

                                <h5>{"Manage all the video templates from here"} </h5><span>{""}</span>

                            </div>
                            <div style={{ margin: "16px" }}>
                                <SearchBar filters={true} top={80} visible={visible} setVisible={setVisible} filterOptions={filterList} query={query} setQuery={setQuery} search={search} setSearch={setSearch} onKeyDown={handleSearch} />
                            </div>
                            <div className="card-body">
                                <div className="table-responsive product-table">
                                    {templates_loading ? <SplitLoader center={true} /> : <DataTable
                                        noHeader
                                        className='no-scroll-body'
                                        data={templatesData}
                                        columns={columns}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Individual column searching (text inputs) Ends--> */}
                </div>
            </div>
            <div style={{ display: FLEX, justifyContent: "end", alignItems: CENTER, margin: "8px" }}>
                {templates_list.total_video_templates !== undefined && <Paginate page={page} setPage={setPage} totalPage={Math.ceil(templates_list.total_video_templates / 10)} />}
            </div>
        </div>
    )
}

export default ManageTemplates