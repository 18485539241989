import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import Breadcrumb from '../../../components/common/breadcrumb'
import { useDispatch, useSelector } from 'react-redux'
import { get_avatars_list_api, patch_avatar_api, search_avatar_list_api } from '../../../redux/assets/assets.actions'
import { Copy } from 'react-feather'
import { convertUTCToIST, copyContent } from '../../../constant/scripts/helper'
import { CENTER, FLEX, POINTER, SB } from '../../../constant/typograpy/properties'
import { useSearchParams } from 'react-router-dom'
import Paginate from '../../../components/custom/AdvanceComp/Paginate'
import "../../styles/styles.css"
import SearchBar from '../../../components/custom/AdvanceComp/SearchBar'
import SplitLoader from '../../../components/custom/SplitLoader'
import SweetAlert from "sweetalert2"
import AvatarActionPanel from '../../../components/custom/ActionPanels/AvatarActionPanel'
import CreateAvatar from '../../../components/custom/CreatePanels/CreateAvatar'

const filterList = [
    { name: "User ID", value: "user_id" },
    { name: "ID", value: "id" },
    { name: "Name", value: "name" },
]

const ManageAvatars = () => {
    const { avatars_list, avatars_loading, avatars_error } = useSelector((state) => state.assetsManager)
    const [avatarsData, setAvatarsData] = useState([])
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const [page, setPage] = useState(Number(searchParams.get("page")) || 1)
    const [visible, setVisible] = useState(false)
    const [query, setQuery] = useState(searchParams.get("query") || "")
    const [search, setSearch] = useState(searchParams.get("type") || filterList[2].value)
    const [refresh, setRefresh] = useState(false)
    useEffect(() => {
        setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
    }, [page])

    useEffect(() => {

        if (query.trim() !== "") {
            dispatch(search_avatar_list_api(query.trim(), search)) // search all avatars info
        } else {
            dispatch(get_avatars_list_api(page)) // gives all avatars info

        }

    }, [searchParams, search, refresh])
    const handlePatch = (formdata) => {
        dispatch(patch_avatar_api(formdata, SweetAlert)).then(() => {
            setAvatarsData([])
            setRefresh((prev) => !prev)
        }).catch((e) => {
            console.log(e)
        })
    }

    useEffect(() => {

        setAvatarsData(() => avatars_list.avatars_list?.map((el, i) => {
            let copyId = el.id
            el.avaId = <span key={i + "copy-ava-id"}><Copy size={12} style={{ cursor: POINTER }} onClick={() => {
                copyContent(copyId)
            }} /> <span>{copyId}</span></span>
            el.image =  <AvatarActionPanel keyy={i + "ava"} id={el.id} el={el} actions={{handlePatch}} element={<img key={i + "image-avatar"} src={el.previewImage} style={{ width: 50, height: 50 }} alt="" />} /> 
            el.action = <AvatarActionPanel keyy={i + "avaa"} id={el.id} el={el} actions={{handlePatch}} />
            return el
        }))

    }, [avatars_list])

    // console.log(avatars_list)

    const columns = [
        {
            name: "Image",
            selector: (row) => row.image,
            sortable: true,
            center: true,
        },
        {
            name: "ID",
            selector: (row) => row.avaId,
            sortable: true,
            center: true,
        },
        {
            name: "Name",
            selector: (row) => row.displayName,
            sortable: true,
            center: true,
        },
        {
            name: "Created At",
            selector: (row) => convertUTCToIST(row.created_at),
            sortable: true,
            center: true,
        },
        {
            name: "Gender",
            selector: (row) => row.gender,
            sortable: true,
            center: true,
        },
        {
            name: "Description",
            selector: (row) => row.description,
            sortable: true,
            center: true,
        },
        {
            name: "Action",
            selector: (row) => row.action,
            sortable: true,
            center: true,
        },
    ]

    const handleSearch = (e) => {
        if (e.key === "Enter") {
            setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
        }
    }

    useEffect(() => {
        if (query === "") {
            dispatch(get_avatars_list_api(page)) // gives all avatars info
            setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
        } 
    }, [query])

    useEffect(() => {
        setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
    }, [search])


    return (
        <div>
            <Breadcrumb title="Manage Avatars" parent="PAM" />
            <div className="container-fluid">
                <div className="row">
                    {/* <!-- Individual column searching (text inputs) Starts--> */}
                    <div className="col-sm-12">
                        <div className="card">
                        <div style={{ display: FLEX, alignItems: CENTER, justifyContent: SB }}>
                                <div className="card-header">
                                    <h5>{"Manage all the avatars from here"} </h5><span>{""}</span>
                                </div>
                                <span style={{marginRight:"16px"}}><CreateAvatar loading={avatars_loading} /></span>
                            </div>

                            <div style={{ margin: "16px" }}>
                                <SearchBar filters={true} top={80} visible={visible} setVisible={setVisible} filterOptions={filterList} query={query} setQuery={setQuery} search={search} setSearch={setSearch} onKeyDown={handleSearch} />
                            </div>
                            <div className="card-body">
                                <div className="table-responsive product-table">
                                    {avatars_loading ? <SplitLoader center={true} /> : <DataTable
                                        noHeader
                                        className='no-scroll-body'
                                        data={avatarsData}
                                        columns={columns}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Individual column searching (text inputs) Ends--> */}
                </div>
            </div>
            <div style={{ display: FLEX, justifyContent: "end", alignItems: CENTER, margin: "8px" }}>
                {avatars_list.total_avatars !== undefined && <Paginate page={page} setPage={setPage} totalPage={Math.ceil(avatars_list.total_avatars / 10)} />}
            </div>
        </div>
    )
}

export default ManageAvatars