import { GET_USER_ERROR, GET_USER_LOADING, GET_USER_SUCCESS } from "./user.types"

 const initialState = {
    loading:false,
    error:false,
    users_list:{}
 }

 export const userReducer = (state = initialState, {type, payload}) => {

    switch (type) {
        
        case GET_USER_LOADING: {

            return {...state, loading:true}
        }

        case GET_USER_SUCCESS: {

            return {...state, loading:false, error:false, users_list:payload}
        }

        case GET_USER_ERROR: {

            return {...state, loading:false, error:true}
        }

        default: {
            return state
        }
    }
 }