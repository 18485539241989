
//avatars types
export const GET_AVATARS_LOADING = "get/avatars/loading"
export const GET_AVATARS_ERROR = "get/avatars/error"
export const GET_AVATARS_SUCCESS = "get/avatars/success"

export const PATCH_AVATARS_LOADING = "patch/avatars/loading"
export const PATCH_AVATARS_ERROR = "patch/avatars/error"
export const PATCH_AVATARS_SUCCESS = "patch/avatars/success"


export const POST_AVATARS_LOADING = "post/avatars/loading"
export const POST_AVATARS_ERROR = "post/avatars/error"
export const POST_AVATARS_SUCCESS = "post/avatars/success"


//bots types
export const GET_BOTS_LOADING = "get/bots/loading"
export const GET_BOTS_ERROR = "get/bots/error"
export const GET_BOTS_SUCCESS = "get/bots/success"

export const PATCH_BOTS_LOADING = "patch/bots/loading"
export const PATCH_BOTS_ERROR = "patch/bots/error"
export const PATCH_BOTS_SUCCESS = "patch/bots/success"

//voices types
export const GET_VOICES_LOADING = "get/voices/loading"
export const GET_VOICES_ERROR = "get/voices/error"
export const GET_VOICES_SUCCESS = "get/voices/success"

export const PATCH_VOICES_LOADING = "patch/voices/loading"
export const PATCH_VOICES_ERROR = "patch/voices/error"
export const PATCH_VOICES_SUCCESS = "patch/voices/success"

export const POST_VOICES_LOADING = "post/voices/loading"
export const POST_VOICES_ERROR = "post/voices/error"
export const POST_VOICES_SUCCESS = "post/voices/success"

//pt types

export const GET_PT_LOADING = "get/pt/loading"
export const GET_PT_ERROR = "get/pt/error"
export const GET_PT_SUCCESS = "get/pt/success"

export const PATCH_PT_LOADING = "patch/pt/loading"
export const PATCH_PT_ERROR = "patch/pt/error"
export const PATCH_PT_SUCCESS = "patch/pt/success"

export const POST_PT_LOADING = "post/pt/loading"
export const POST_PT_ERROR = "post/pt/error"
export const POST_PT_SUCCESS = "post/pt/success"

