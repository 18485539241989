import { useState } from "react";
import { Plus } from "react-feather";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { CENTER, FLEX, SB } from "../../../constant/typograpy/properties";
import UserManager from "../AdvanceComp/UserManger";
import Swal from "sweetalert2";
import { converIntoFormData, create_UUID } from "../../../constant/scripts/helper";
import { useDispatch } from "react-redux";
import { post_avatar_api, post_public_template_api, post_voice_api } from "../../../redux/assets/assets.actions";
import PreviewAudio from "../AdvanceComp/PreviewAudio";
import { useRef } from "react";
import WhiteFade from "../WhiteFade";
import { useNavigate } from "react-router-dom";
import Terminal from "../../../pages/Utility/Terminal";
import { TagsInput } from "react-tag-input-component";
import GlobalUploader from "../GlobalUploader/GlobalUploader";
export default function CreatePublicTemplaete({ loading, page }) {
    const [modal, setModal] = useState(false)
    const trackId = useRef(create_UUID())
    // console.log(trackId)
    const [data, setData] = useState({
        templateId: "",
        name: "",
    })
    const [tags, setTags] = useState([])
    const [links, setLinks] = useState([])
    const toggle = () => setModal(!modal)
    const dispatch = useDispatch()

    const handlePost = () => {
        if (data.name === "" || data.templateId === "" || links.length === 0 || tags.links === 0) {
            Swal.fire({ title: "Empty Field", html: "All * (star) fields are mandotary !", icon: "error" })
            return
        }

        const formData = converIntoFormData({
            tracking_id: trackId.current,
            template_id: data.templateId,
            name: data.name,
            tags: JSON.stringify(tags),
            preview_images: JSON.stringify(links)
        })

        dispatch(post_public_template_api(formData, Swal, page)).then(() => {
            // console.log(res)
            toggle()
        }).catch((e) => {
            console.log(e)
        })

    }

    return <div>
        <Button onClick={toggle}><span style={{ display: FLEX, alignItems: CENTER, gap: "8px" }}><Plus size={24} /> <span> Create Public Template </span> </span> <></></Button>
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>{"Create Public Template"}</ModalHeader>
            <ModalBody>
                <div className="col-sm-12">
                    <div className="card">
                        {loading && <WhiteFade loader={true} />}
                        <div className="card-header">
                            <h5>{"Public Template Form"}</h5><span>Use this form to create a new public template.</span>
                        </div>
                        <div className="card-body">
                            <form className="theme-form">

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="name">{"Name*"}</label>
                                    <div className="col-sm-9">
                                        <input value={data.name} onChange={(e) => setData({ ...data, name: e.target.value })} className="form-control" type="text" id="name" placeholder="Name" />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="tempId">{"Template ID*"}</label>
                                    <div className="col-sm-9">
                                        <input value={data.templateId} onChange={(e) => setData({ ...data, templateId: e.target.value })} className="form-control" type="text" id="desc" placeholder="Template ID" />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="zipUrl">{"Tags*"}</label>
                                    <div className="col-sm-9">
                                        <div>
                                            <TagsInput
                                                value={tags}
                                                onChange={setTags}
                                                name="tags"
                                                placeHolder="Enter search tags"
                                            />
                                            <em>press enter to add new tag</em>
                                        </div>
                                    </div>

                                </div>


                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="images">{"Upload Images*"}</label>
                                    <div className="col-sm-9">
                                        <GlobalUploader links={links} setLinks={setLinks} path={`public_templates/${trackId.current}`} />
                                    </div>

                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                {!loading && <button onClick={handlePost} className="btn btn-primary me-1">{"Create"}</button>}
                {!loading && <button onClick={toggle} className="btn btn-secondary">{"Cancel"}</button>}
            </ModalFooter>
        </Modal>
    </div>
}