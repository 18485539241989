import { Copy, Key, Mail, User } from "react-feather";
import { Card } from "reactstrap";
import { copyContent } from "../../constant/scripts/helper";
import { CENTER, FLEX, POINTER, SB } from "../../constant/typograpy/properties";
import { GREEN, RED, WHITE } from "../../constant/typograpy/colors";

export default function UserComp({ el, keyy, setAllocatedUsers, add }) {


    return <div key={keyy} className="card" style={{borderRadius:0 }}>
        <div className="card-body">
            <div style={{ display: FLEX, alignItems: CENTER, justifyContent: SB, flexWrap:"wrap" }}>
                <p><span><User size={14} /></span>&nbsp;{el.displayName}</p>
                <p><span><Mail size={14} /></span>&nbsp;{el.email} &nbsp;<span> <Copy style={{ cursor: POINTER }} size={12} onClick={() => {
                    copyContent(el.email)
                }} /></span></p>
                <p><span><Key size={14} /></span>&nbsp;{el.id} &nbsp;<span> <Copy style={{ cursor: POINTER }} size={12} onClick={() => {
                    copyContent(el.id)
                }} /></span></p>
                 <button style={{ backgroundColor: add ? GREEN : RED, border: "none", padding: "4px 8px", borderRadius: "4px", color: WHITE }} onClick={() => {
                if (add) {
                    setAllocatedUsers((prev) => {
                        return [...prev, el.id]
                    })
                } else {
                    setAllocatedUsers((prev) => {
                        let newList = prev.filter((myel) => {
                            if (myel !== el.id) {
                                return myel
                            }
                        })
                        return newList
                    })
                }
            }}>{add ? "Add" : "Remove"}</button>
            </div>


           
        </div>
    </div>
}