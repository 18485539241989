import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import Breadcrumb from '../../../components/common/breadcrumb'
import { useDispatch, useSelector } from 'react-redux'
import { get_admins_list_api, patch_admin_api } from '../../../redux/admin/admin.actions'
import { Copy } from 'react-feather'
import { convertUTCToIST, copyContent } from '../../../constant/scripts/helper'
import {  CENTER, FLEX, POINTER, SB } from '../../../constant/typograpy/properties'
import "../../styles/styles.css"
import SplitLoader from '../../../components/custom/SplitLoader'
import SweetAlert from "sweetalert2"
import AdminActionPanel from '../../../components/custom/ActionPanels/AdminActionPanel'
import CreateAdmin from '../../../components/custom/CreatePanels/CreateAdmin'

const ManageAdmins = () => {
    const { admins_list, admins_loading, admins_error } = useSelector((state) => state.adminManager)
    const [adminsData, setAdminsData] = useState([])
    const dispatch = useDispatch()
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
            dispatch(get_admins_list_api()) // gives all avatars info
    }, [refresh])

    const handlePatch = (formdata) => {
        dispatch(patch_admin_api(formdata, SweetAlert)).then(() => {
            setAdminsData([])
            setRefresh((prev) => !prev)
        }).catch((e) => {
            console.log(e)
        })
    }

    useEffect(() => {

        setAdminsData(() => admins_list.admins_list?.map((el, i) => {
            let copyName = el.props.name
            let userEmail = el.props.email
            el.name = <span key={i + "copy-adm-id"}><Copy size={12} style={{ cursor: POINTER }} onClick={() => {
                copyContent(copyName)
            }} /> <span>{copyName}</span></span>

            el.email = <span key={i + "copy-admin-id"}><Copy size={12} style={{ cursor: POINTER }} onClick={() => {
                copyContent(userEmail)
            }} /> <span>{userEmail}</span></span>

            el.image =  <AdminActionPanel keyy={i + "ad"} id={el.id} el={el} actions={{handlePatch}} element={ <div className="avatar">
            <img
              className="img-40 rounded-circle"
              src={el.props.profilePic}
              alt="#"
            />
          </div>} /> 
            el.action = <AdminActionPanel keyy={i + "admin"} id={el.id} el={el} actions={{handlePatch}} />
            return el
        }))

    }, [admins_list])

    console.log(admins_list)

    const columns = [
        {
            name: "Image",
            selector: (row) => row.image,
            sortable: true,
            center: true,
        },
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            center: true,
        },
        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
            center: true,
        },
        {
            name: "Created At",
            selector: (row) => convertUTCToIST(row.created_at),
            sortable: true,
            center: true,
        },
        {
            name: "Role",
            selector: (row) => row.role === "admin" ? <span className="badge  badge-info">{"Admin"}</span> : row.role === "superadmin" ? <span className="badge  badge-warning">{"Super Admin"}</span> : <span className="badge  badge-secondary">{row.role}</span>,
            sortable: true,
            center: true,
        },
        {
            name: "Active",
            selector: (row) => row.isActive ? <span className="badge rounded-pill badge-success">{"Active"}</span> : <span className="badge rounded-pill badge-danger">{"Disabled"}</span>,
            sortable: true,
            center: true,
        },
        {
            name: "Action",
            selector: (row) => row.action,
            sortable: true,
            center: true,
        },
    ]

    // console.log(admins_list)


    return (
        <div>
            <Breadcrumb title="Manage Admins" parent="Dashboard" />
            <div className="container-fluid">
                <div className="row">
                    {/* <!-- Individual column searching (text inputs) Starts--> */}
                    <div className="col-sm-12">
                        <div className="card">
                        <div style={{ display: FLEX, alignItems: CENTER, justifyContent: SB }}>
                                <div className="card-header">
                                    <h5>{"Manage all the admins from here"} </h5><span>{""}</span>
                                </div>
                                <span style={{marginRight:"16px"}}><CreateAdmin loading={admins_loading} /></span>
                            </div>

                            <div className="card-body">
                                <div className="table-responsive product-table">
                                    {admins_loading ? <SplitLoader center={true} /> : <DataTable
                                        noHeader
                                        className='no-scroll-body'
                                        data={adminsData}
                                        columns={columns}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Individual column searching (text inputs) Ends--> */}
                </div>
            </div>
        </div>
    )
}

export default ManageAdmins