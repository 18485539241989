import { get_admins_list_res, get_is_admin_res, patch_admin_res, post_admin_res } from "./admin.api"
import { GET_ADMIN_ERROR, GET_ADMIN_LOADING, GET_ADMIN_SUCCESS, GET_IS_ADMIN_ERROR, GET_IS_ADMIN_LOADING, GET_IS_ADMIN_SUCCESS, PATCH_ADMIN_ERROR, PATCH_ADMIN_LOADING, PATCH_ADMIN_SUCCESS, POST_ADMIN_ERROR, POST_ADMIN_LOADING, POST_ADMIN_SUCCESS } from "./admin.types"

export const get_admins_list_api = () => async(dispatch) => {

    dispatch({type:GET_ADMIN_LOADING})
    try {
        const res = await get_admins_list_res()
        dispatch({type:GET_ADMIN_SUCCESS, payload:res.data})
    } catch (error) {
        dispatch({type:GET_ADMIN_ERROR})
    }

}


export const get_is_admin_api = (id) => async(dispatch) => {

    dispatch({type:GET_IS_ADMIN_LOADING})
    try {
        const res = await get_is_admin_res(id)
        dispatch({type:GET_IS_ADMIN_SUCCESS, payload:res.data})
    } catch (error) {
        dispatch({type:GET_IS_ADMIN_ERROR})
    }

}



export const patch_admin_api = (formdata, SweetAlert) => async(dispatch) => {
    dispatch({type:PATCH_ADMIN_LOADING})
    try {
         const res =await patch_admin_res(formdata)
        dispatch({type:PATCH_ADMIN_SUCCESS})
        if (res.data === "Admin updated successfully") {
            SweetAlert.fire({title:"Good job !", text:res.data, icon:"success"});      
        }
    } catch (error) {
        dispatch({type:PATCH_ADMIN_ERROR})
        SweetAlert.fire({title:"Failed!", text:JSON.parse(error.request.response).detail, icon:"error"})
    }

}



export const post_admin_api = (formdata, SweetAlert) => async(dispatch) => {
    dispatch({type:POST_ADMIN_LOADING})
    try {
         const res =await post_admin_res(formdata)
        dispatch({type:POST_ADMIN_SUCCESS})
        if (res.data === "Admin created successfully") {
            SweetAlert.fire({title:"Good job !", text:res.data, icon:"success"});      
        }
    } catch (error) {
        dispatch({type:POST_ADMIN_ERROR})
        console.log(error)
        SweetAlert.fire({title:"Failed!", text:JSON.parse(error.request.response).detail, icon:"error"})
    }

}