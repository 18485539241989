import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BOLD, CENTER, FILL_10PARENT, FILL_20PARENT, FILL_30PARENT, FILL_50PARENT, FILL_80PARENT, FILL_PARENT, FLEX, POINTER, SB, START, TOP } from "../../../constant/typograpy/properties";
import px from "../../../constant/scripts/px";
import UserManager from "../AdvanceComp/UserManger";
import { converIntoFormData, convertUTCToIST } from "../../../constant/scripts/helper";

export default function LogicActionPanel({ id, actions, el, keyy, element = <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i> }) {
    const [modal, setModal] = useState(false)
    const [status, setStatus] = useState(el.status)
    const [name, setName] = useState(el.name)
    const [allocatedUsers, setAllocatedUsers] = useState(el.users)
    const toggle = () => setModal(!modal)
    // const [dropdownOpen, setDropdownOpen] = useState(false);
    // const toggle = () => setDropdownOpen(prevState => !prevState);

    // console.log(el)
    return <>
        <div key={keyy}>
            {/* <span style={{ cursor: POINTER }}><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></span> */}
            <span>
                <span style={{ cursor: POINTER }} onClick={toggle}>{element}</span>
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>{"Edit Logic"}</ModalHeader>
                    <ModalBody>
                    <div className="card">
                  <div className="card-header">
                    <h5>{el.name.replaceAll("_", " ")}</h5><span>Modify logic properties here and click on save to make changes.</span>
                  </div>
                        <div className="card-body">
                            <form className="theme-form">
            
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="logicName">{"Logic Name"}</label>
                                    <div className="col-sm-9">
                                    <input value={name} onChange={(e) => setName(e.target.value)} className="form-control" id="name" type="text" placeholder="Logic Name" />                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="createdAt">{"Created at"}</label>
                                    <div className="col-sm-9">
                                        <p className="form-control">{convertUTCToIST(el.created_at)}</p>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="status">{"Status"}</label>
                                    <div className="col-sm-9">
                                        <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                                            <option value="draft">Draft</option>
                                            <option value="deployed">Deployed</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="manage">{"Manage Users"}</label>
                                    <div className="col-sm-9">
                                        <div className="form-control" style={{ padding: "16px" }}>
                                            <UserManager allocatedUsers={allocatedUsers} setAllocatedUsers={setAllocatedUsers} filterList={[{ name: "ID", value: "id" }, { name: "Email", value: "email" }, { name: "Name", value: "displayName" }]} />
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {

                            actions.handlePatch(converIntoFormData({
                                db_id: id,
                                name: name,
                                logic_status:status,
                                users: JSON.stringify(allocatedUsers)
                            }))
                        }}>{"Save"}</Button>
                        <Button color="secondary" onClick={toggle}>{"Cancel"}</Button>
                    </ModalFooter>
                </Modal>
            </span>
        </div>
    </>
}