import { OVERVIEW_ERROR, OVERVIEW_LOADING, OVERVIEW_SUCCESS } from "./overview.types"

const initialState = {
    loading:false,
    error:false,
    data:{}
}

export const overviewReducer = (state = initialState, {type, payload}) => {

    switch (type) {

        case OVERVIEW_LOADING: {

            return {
                ...state, loading:true
            }
        }

        case OVERVIEW_SUCCESS: {
            return {
                ...state, loading:false, error:false, data:payload
            }
        }

        case OVERVIEW_ERROR: {
            return {...state, loading:false, error:true}
        }

        default: {
            return state
        }
    }

}