import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BOLD, FILL_30PARENT, FILL_PARENT, FLEX, POINTER, SB } from "../../../constant/typograpy/properties";
import px from "../../../constant/scripts/px";
import { BLACK, SKYBLUE } from "../../../constant/typograpy/colors";
import UserManager from "../AdvanceComp/UserManger";
import { converIntoFormData, convertUTCToIST } from "../../../constant/scripts/helper";

export default function BotActionPanel({ id, actions, el, keyy, element = <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i> }) {
    const [modal, setModal] = useState(false)
    const [disName, setDisName] = useState(el.name)
    const toggle = () => setModal(!modal)
    const [allocatedUsers, setAllocatedUsers] = useState(el.users)
    // const [dropdownOpen, setDropdownOpen] = useState(false);
    // const toggle = () => setDropdownOpen(prevState => !prevState);

    // console.log(el)
    return <>
        <div key={keyy}>
            {/* <span style={{ cursor: POINTER }}><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></span> */}
            <span>
                <span style={{ cursor: POINTER }} onClick={toggle}>{element}</span>
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>{"Edit Bot"}</ModalHeader>
                    <ModalBody>
                        <div className="card">
                            <div className="card-header">
                                <h5>{el.name}</h5><span>Modify bot properties here and click on save to make changes.</span>
                            </div>
                            <div className="card-body">

                                <form className="theme-form" style={{ flex: 2 }}>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="disName">{"Display Name"}</label>
                                        <div className="col-sm-9">
                                            <input value={disName} onChange={(e) => setDisName(e.target.value)} className="form-control" id="name" type="text" placeholder="Display Name" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="createdAt">{"Created At"}</label>
                                        <div className="col-sm-9">
                                            <p className="form-control">{convertUTCToIST(el.created_at)}</p>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="manage">{"Manage Users"}</label>
                                        <div className="col-sm-9">
                                            <div className="form-control" style={{ padding: "16px" }}>
                                                <UserManager allocatedUsers={allocatedUsers} setAllocatedUsers={setAllocatedUsers} filterList={[{ name: "ID", value: "id" }, { name: "Email", value: "email" }, { name: "Name", value: "displayName" }]} />
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {
                            actions.handlePatch(converIntoFormData({
                                db_id: id,
                                name: disName,
                                users: JSON.stringify(allocatedUsers)
                            }))
                        }}>{"Save"}</Button>
                        <Button color="secondary" onClick={toggle}>{"Cancel"}</Button>
                    </ModalFooter>
                </Modal>
            </span>
        </div>
    </>
}