import { GET_ADMIN_ERROR, GET_ADMIN_LOADING, GET_ADMIN_SUCCESS, GET_IS_ADMIN_ERROR, GET_IS_ADMIN_LOADING, GET_IS_ADMIN_SUCCESS, PATCH_ADMIN_ERROR, PATCH_ADMIN_LOADING, PATCH_ADMIN_SUCCESS, POST_ADMIN_ERROR, POST_ADMIN_LOADING, POST_ADMIN_SUCCESS } from "./admin.types"

const initialState = {
    admins_loading:false,
    admins_error:false,
    admins_list:{},
    isAdmin_loading:false,
    isAdmin_error:false,
    isAdmin:false

}

export const adminReducer = (state = initialState , {type, payload}) => {

    switch (type) {
        
        case GET_ADMIN_LOADING: {
            return {...state, admins_loading:true}
        }

        case GET_ADMIN_SUCCESS: {
            return {...state, admins_loading:false, admins_error:false, admins_list:payload}
        }

        case GET_ADMIN_ERROR: {
            return {...state, admins_loading:false, admins_error:true}
        }


        case GET_IS_ADMIN_LOADING: {
            return {...state, isAdmin_loading:true}
        }

        case GET_IS_ADMIN_SUCCESS: {
            return {...state, isAdmin_loading:false, isAdmin_error:false, isAdmin:payload}
        }

        case GET_IS_ADMIN_ERROR: {
            return {...state, isAdmin_loading:false, isAdmin_error:true}
        }


        case PATCH_ADMIN_LOADING: {
            return {...state, admins_loading:true}
        }

        case PATCH_ADMIN_SUCCESS: {
            return {...state, admins_loading:false, admins_error:false}
        }

        case PATCH_ADMIN_ERROR: {
            return {...state, admins_loading:false, admins_error:true}
        }


        case POST_ADMIN_LOADING: {
            return {...state, admins_loading:true}
        }

        case POST_ADMIN_SUCCESS: {
            return {...state, admins_loading:false, admins_error:false}
        }

        case POST_ADMIN_ERROR: {
            return {...state, admins_loading:false, admins_error:true}
        }

        default:{
            return state
        }
    }
}