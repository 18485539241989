import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import Breadcrumb from '../../../components/common/breadcrumb'
import { useDispatch, useSelector } from 'react-redux'
import { Copy, Video } from 'react-feather'
import { convertUTCToIST, copyContent } from '../../../constant/scripts/helper'
import { CENTER, FLEX, POINTER } from '../../../constant/typograpy/properties'
import { useSearchParams } from 'react-router-dom'
import Paginate from '../../../components/custom/AdvanceComp/Paginate'
import "../../styles/styles.css"
import SearchBar from '../../../components/custom/AdvanceComp/SearchBar'
import SplitLoader from '../../../components/custom/SplitLoader'
import SweetAlert from "sweetalert2"
import { get_videos_list_api, patch_video_api, search_videos_list_api } from '../../../redux/media/media.actions'
import VideoActionPanel from '../../../components/custom/ActionPanels/VideoActionPanel'

const filterList = [
    { name: "User ID", value: "user_id" },
    { name: "ID", value: "id" },
    { name: "Name", value: "name" },
]

const ManageVideos = () => {
    const { videos_list, videos_loading, videos_error } = useSelector((state) => state.mediaManager)
    const [videosData, setVideosData] = useState([])
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const [page, setPage] = useState(Number(searchParams.get("page")) || 1)
    const [visible, setVisible] = useState(false)
    const [query, setQuery] = useState(searchParams.get("query") || "")
    const [search, setSearch] = useState(searchParams.get("type") || filterList[2].value)
    const [refresh, setRefresh] = useState(false)
    useEffect(() => {
        setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
    }, [page])

    useEffect(() => {

        if (query.trim() !== "") {
            dispatch(search_videos_list_api(query.trim(), search)) // search all videos info
        } else {
            //comment out to get video list without search
            dispatch(get_videos_list_api(page)) // gives all videos info

        }

    }, [searchParams, search, refresh])
    const handlePatch = (formdata) => {
        dispatch(patch_video_api(formdata, SweetAlert)).then(() => {
            setVideosData([])
            setRefresh((prev) => !prev)
        }).catch((e) => {
            console.log(e)
        })
    }

    useEffect(() => {

        setVideosData(() => videos_list.videos_list?.map((el, i) => {
            let videoId = el.videoId
            el.vidId = <span key={i + "copy-video-id"}><Copy size={12} style={{ cursor: POINTER }} onClick={() => {
                copyContent(videoId)
            }} /> <span>{videoId}</span></span>

            el.image =  <VideoActionPanel keyy={i + "video"} id={el.id} el={el} actions={{handlePatch}} element={<Video />} /> 
            el.action = <VideoActionPanel keyy={i + "vid"} id={el.id} el={el} actions={{handlePatch}} />
            return el
        }))

    }, [videos_list])

    console.log(videos_list)

    const columns = [
        {
            name: "Image",
            selector: (row) => row.image,
            sortable: true,
            center: true,
        },
        {
            name: "Video ID",
            selector: (row) => row.vidId,
            sortable: true,
            center: true,
        },

        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            center: true,
        },
        {
            name: "Created At",
            selector: (row) => convertUTCToIST(row.created_at),
            sortable: true,
            center: true,
        },
        {
            name: "Status",
            selector: (row) => row.status,
            sortable: true,
            center: true,
        },

        {
            name: "Action",
            selector: (row) => row.action,
            sortable: true,
            center: true,
        },
    ]

    const handleSearch = (e) => {
        if (e.key === "Enter") {
            setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
        }
    }

    useEffect(() => {
        if (query.trim() === "") {
            //comment out to get video list without search
            dispatch(get_videos_list_api(page)) // gives all videos info
            setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
        } 
    }, [query])

    useEffect(() => {
        setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
    }, [search])


    return (
        <div>
            <Breadcrumb title="Manage Videos" parent="PAM" />
            <div className="container-fluid">
                <div className="row">
                    {/* <!-- Individual column searching (text inputs) Starts--> */}
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">

                                <h5>{"Manage all the videos from here"} </h5><span>{""}</span>

                            </div>
                            <div style={{ margin: "16px" }}>
                                <SearchBar filters={true} top={80} visible={visible} setVisible={setVisible} filterOptions={filterList} query={query} setQuery={setQuery} search={search} setSearch={setSearch} onKeyDown={handleSearch} />
                            </div>
                            <div className="card-body">
                                <div className="table-responsive product-table">
                                    {videos_loading ? <SplitLoader center={true} /> : <DataTable
                                        noHeader
                                        className='no-scroll-body'
                                        data={videosData}
                                        columns={columns}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Individual column searching (text inputs) Ends--> */}
                </div>
            </div>
            <div style={{ display: FLEX, justifyContent: "end", alignItems: CENTER, margin: "8px" }}>
                {videos_list.total_videos !== undefined && <Paginate page={page} setPage={setPage} totalPage={Math.ceil(videos_list.total_videos / 10)} />}
            </div>
        </div>
    )
}

export default ManageVideos