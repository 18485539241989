import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { converIntoFormData, convertUTCToIST } from "../../../constant/scripts/helper";
import { FLEX, POINTER } from "../../../constant/typograpy/properties";
import { TagsInput } from "react-tag-input-component";
import GlobalUploader from "../GlobalUploader/GlobalUploader"


export default function PtActionPanel({ id, actions, el, keyy, element = <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i> }) {
    const [modal, setModal] = useState(false)
    const [ptid, setPtid] = useState(el.id)
    const [tid, setTid] = useState(el.source)
    const [name, setName] = useState(el.name)
    const [status, setStatus] = useState(el.status)
    const [imageLinks, setImageLinks] = useState(el.previewImages)
    const [tags, setTags] = useState(el.tags)
    const toggle = () => setModal(!modal)
    // const [dropdownOpen, setDropdownOpen] = useState(false);
    // const toggle = () => setDropdownOpen(prevState => !prevState);

    // console.log(el)
    return <>
        <div key={keyy}>
            {/* <span style={{ cursor: POINTER }}><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></span> */}
            <span>
                <span style={{ cursor: POINTER }} onClick={toggle}>{element}</span>
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>{"Edit Public Template"}</ModalHeader>
                    <ModalBody>
                        <div className="card">
                            <div className="card-header">
                                <h5>{el.name.replaceAll("_", " ")}</h5><span>Modify public template properties here and click on save to make changes.</span>
                            </div>
                            <div className="card-body">
                                <form className="theme-form">
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="image">{"Images"}</label>
                                        <div className="col-sm-9">
                                            <div style={{ display: FLEX, justifyContent: "end", gap: "4px" }}>
                                                <GlobalUploader path={`public_templates/${el.misc.tracking_id}`} links={imageLinks} setLinks={setImageLinks} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="createdAt">{"Created at"}</label>
                                        <div className="col-sm-9">
                                            <p className="form-control">{convertUTCToIST(el.created_at)}</p>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="name">{"Template name"}</label>
                                        <div className="col-sm-9">
                                            <input className="form-control" value={name} onChange={(e) => setName(e.target.value)}></input>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="ptid">{"Public Template ID"}</label>
                                        <div className="col-sm-9">
                                            <input className="form-control" value={ptid} onChange={(e) => setPtid(e.target.value)}>

                                            </input>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="tid">{"Source Template ID"}</label>
                                        <div className="col-sm-9">
                                            <input value={tid} onChange={(e) => setTid(e.target.value)} className="form-control" id="tid" type="text" placeholder="Template id" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="tags">{"Tags"}</label>
                                        <div className="col-sm-9">
                                            <div>
                                                <TagsInput
                                                    value={tags}
                                                    onChange={setTags}
                                                    name="tags"
                                                    placeHolder="Enter search tags"
                                                />
                                                <em>press enter to add new tag</em>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="status">{"Status"}</label>
                                        <div className="col-sm-9">
                                            <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                                                <option value="published">Published</option>
                                                <option value="archived">Archived</option>
                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="imageLink">{"Image Link"}</label>
                                    <div className="col-sm-9">
                                        <input value={imageLink} onChange={(e) => setImageLink(e.target.value)} className="form-control" id="imageLink" type="text" placeholder="Image Link" />
                                    </div>
                                </div> */}



                                </form>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {

                            actions.handlePatch(converIntoFormData({
                                public_template_id: ptid,
                                template_id: tid,
                                name: name,
                                preview_images: JSON.stringify(imageLinks),
                                tags: JSON.stringify(tags),
                                tracking_id: el.misc.tracking_id,
                                status:status
                            }))
                        }}>{"Save"}</Button>
                        <Button color="secondary" onClick={toggle}>{"Cancel"}</Button>
                    </ModalFooter>
                </Modal>
            </span>
        </div>
    </>
}