import { GET_ELOGS_ERROR, GET_ELOGS_LOADING, GET_ELOGS_SUCCESS, GET_HLOGS_ERROR, GET_HLOGS_LOADING, GET_HLOGS_SUCCESS, GET_JLOGS_ERROR, GET_JLOGS_LOADING, GET_JLOGS_SUCCESS, GET_LOGS_ERROR, GET_LOGS_LOADING, GET_LOGS_SUCCESS, RESET_LOGS } from "./logs.types"

const initialState = {
    logs_loading:false,
    logs_error:false,
    logs_list:{},

    elogs_loading:false,
    elogs_error:false,
    elogs_list:[],

    hlogs_loading:false,
    hlogs_error:false,
    hlogs_list:[],

    jlogs_loading:false,
    jlogs_error:false,
    jlogs_list:[]
}

export const logsReducer = (state = initialState, {type, payload}) => {

    switch (type) {

        case GET_LOGS_LOADING: {
            return {...state, logs_loading:true}
        }

        case GET_LOGS_SUCCESS: {
            return {...state, logs_loading:false, logs_error:false, logs_list:Object.keys(payload).length === 0 ? [] : payload}
        }

        case GET_LOGS_ERROR: {
            return {...state, logs_loading:false, logs_loading:false}
        }

        case GET_ELOGS_LOADING: {
            return {...state, elogs_loading:true}
        }

        case GET_ELOGS_SUCCESS: {
            return {...state, elogs_loading:false, elogs_error:false, elogs_list:payload}
        }

        case GET_ELOGS_ERROR: {
            return {...state, elogs_loading:false, elogs_loading:false}
        }
        
        
        case GET_JLOGS_LOADING: {
            return {...state, jlogs_loading:true}
        }

        case GET_JLOGS_SUCCESS: {
            return {...state, jlogs_loading:false, jlogs_error:false, jlogs_list:payload}
        }

        case GET_JLOGS_ERROR: {
            return {...state, jlogs_loading:false, jlogs_loading:false}
        }

        case GET_HLOGS_LOADING: {
            return {...state, hlogs_loading:true}
        }

        case GET_HLOGS_SUCCESS: {
            return {...state, hlogs_loading:false, hlogs_error:false, hlogs_list:payload}
        }

        case GET_HLOGS_ERROR: {
            return {...state, hlogs_loading:false, hlogs_loading:false}
        }

        case RESET_LOGS :{
            return initialState
        }
        
        default:{
            return state
        } 
            
    }
}