import axios from "axios"
import { REGULATOR_URL } from "../../constant/baseUrl"
import { get_org_list, search_orgs_list } from "../../constant/endpoints"
import { APPLICATION_JSON, CONTENT_TYPE, X_ACCESS_TOKEN } from "../../constant/utils"
import { getTokenFromLocal } from "../../constant/scripts/helper"

export const get_org_list_res = async (page) => {

    try {
        const res = await axios.get(REGULATOR_URL + get_org_list(page), {
            headers: {
                [CONTENT_TYPE]: APPLICATION_JSON,
                [X_ACCESS_TOKEN]: getTokenFromLocal()
            }
        })
        return res
    } catch (error) {
        console.log(error, "error")
    }

}

export const search_orgs_list_res = async (query, type) => {

    const res = await axios.get(REGULATOR_URL + search_orgs_list(query, type), {
        headers: {
            [CONTENT_TYPE]: APPLICATION_JSON,
            [X_ACCESS_TOKEN]: getTokenFromLocal()
        }
    })
    // console.log(res, "search ava")
    return res

}