import { get_logics_list_res, get_video_templates_list_res, get_videos_list_res, patch_logic_res, patch_video_res, patch_video_template_res, search_logics_list_res, search_video_templates_list_res, search_videos_list_res } from "./media.api"
import { GET_LOGICS_ERROR, GET_LOGICS_LOADING, GET_LOGICS_SUCCESS, GET_TEMPLATES_ERROR, GET_TEMPLATES_LOADING, GET_TEMPLATES_SUCCESS, GET_VIDEOS_ERROR, GET_VIDEOS_LOADING, GET_VIDEOS_SUCCESS, PATCH_LOGICS_ERROR, PATCH_LOGICS_LOADING, PATCH_LOGICS_SUCCESS, PATCH_TEMPLATES_LOADING, PATCH_TEMPLATES_SUCCESS, PATCH_VIDEOS_ERROR, PATCH_VIDEOS_LOADING, PATCH_VIDEOS_SUCCESS } from "./media.types"

export const get_videos_list_api = (page) => async(dispatch) => {

    dispatch({type:GET_VIDEOS_LOADING})
    try {
        const res = await get_videos_list_res(page)
        dispatch({type:GET_VIDEOS_SUCCESS, payload:res.data})
    } catch (error) {
        dispatch({type:GET_VIDEOS_ERROR})
    }

}


export const search_videos_list_api = (query, type) => async(dispatch) => {

    dispatch({type:GET_VIDEOS_LOADING})
    try {
        const res = await search_videos_list_res(query, type)
        dispatch({type:GET_VIDEOS_SUCCESS, payload:res.data})
    } catch (error) {
        dispatch({type:GET_VIDEOS_ERROR})
    }

}

export const get_logics_list_api = (page) => async(dispatch) => {

    dispatch({type:GET_LOGICS_LOADING})
    try {
        const res = await get_logics_list_res(page)
        dispatch({type:GET_LOGICS_SUCCESS, payload:res.data})
    } catch (error) {
        dispatch({type:GET_LOGICS_ERROR})
    }

}


export const search_logics_list_api = (query, type) => async(dispatch) => {

    dispatch({type:GET_LOGICS_LOADING})
    try {
        const res = await search_logics_list_res(query, type)
        dispatch({type:GET_LOGICS_SUCCESS, payload:res.data})
    } catch (error) {
        dispatch({type:GET_LOGICS_ERROR})
    }

}


export const search_video_templates_list_api = (query, type) => async(dispatch) => {

    dispatch({type:GET_TEMPLATES_LOADING})
    try {
        const res = await search_video_templates_list_res(query, type)
        dispatch({type:GET_TEMPLATES_SUCCESS, payload:res.data})
    } catch (error) {
        dispatch({type:GET_TEMPLATES_ERROR})
    }

}

export const get_video_templates_list_api = (page) => async(dispatch) => {

    dispatch({type:GET_TEMPLATES_LOADING})
    try {
        const res = await get_video_templates_list_res(page)
        dispatch({type:GET_TEMPLATES_SUCCESS, payload:res.data})
    } catch (error) {
        dispatch({type:GET_TEMPLATES_ERROR})
    }

}



export const patch_video_api = (formdata, SweetAlert) => async(dispatch) => {
    dispatch({type:PATCH_VIDEOS_LOADING})
    try {
         const res =await patch_video_res(formdata)
        dispatch({type:PATCH_VIDEOS_SUCCESS})
        if (res.data === "Video updated successfully") {
            SweetAlert.fire({title:"Good job !", text:res.data, icon:"success"});      
        }
    } catch (error) {
        dispatch({type:PATCH_VIDEOS_ERROR})
        SweetAlert.fire({title:"Failed!", text:JSON.parse(error.request.response).detail, icon:"error"})
    }

}


export const patch_logic_api = (formdata, SweetAlert) => async(dispatch) => {
    dispatch({type:PATCH_LOGICS_LOADING})
    try {
         const res =await patch_logic_res(formdata)
        dispatch({type:PATCH_LOGICS_SUCCESS})
        if (res.data === "logic updated successfully") {
            SweetAlert.fire({title:"Good job !", text:res.data, icon:"success"});      
        }
    } catch (error) {
        dispatch({type:PATCH_LOGICS_ERROR})
        SweetAlert.fire({title:"Failed!", text:JSON.parse(error.request.response).detail, icon:"error"})
    }

}


export const patch_video_template_api = (formdata, SweetAlert) => async(dispatch) => {
    dispatch({type:PATCH_TEMPLATES_LOADING})
    try {
         const res =await patch_video_template_res(formdata)
        dispatch({type:PATCH_TEMPLATES_SUCCESS})
        if (res.data === "Video Templates updated successfully") {
            SweetAlert.fire({title:"Good job !", text:res.data, icon:"success"});      
        }
    } catch (error) {
        dispatch({type:PATCH_TEMPLATES_SUCCESS})
        SweetAlert.fire({title:"Failed!", text:JSON.parse(error.request.response).detail, icon:"error"})
    }

}
