import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { get_org_details_request, patch_activate_deactivate_user, patch_edit_org_credits_user, patch_verify_unverify_user } from "../../../constant/scripts/request"
import React, { Fragment } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import Countup from "react-countup";
import Chart from "react-apexcharts";
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Modal, ModalHeader, ModalBody, Input, Button } from "reactstrap";
// import ChartistGraph from 'react-chartist';
import { Line } from "react-chartjs-2";
import { gradientChart1, gradientChartData, gradientChartOptions } from "../../../data/default";
import SplitLoader from "../../../components/custom/SplitLoader";
import { AlertTriangle, Box, Clock, Copy, DollarSign, Edit, MessageSquare, Navigation, User, Users, Video } from "react-feather";
import CountUp from 'react-countup';
import { convertUTCToIST, copyContent } from "../../../constant/scripts/helper";
import { CENTER, FLEX, POINTER } from "../../../constant/typograpy/properties";
import Switch from "react-switch";
import Swal from "sweetalert2";

const SwitchGroup = ({ el }) => {

    const [active, setActive] = useState(el.isActive)
    const [verified, setVerified] = useState(el.isVerified)

    return <div style={{ display: FLEX, gap: "16px", alignItems: CENTER }} className="pull-right">

        <div style={{ display: FLEX, alignItems: CENTER }}>
            <div>{"Active"}&nbsp;</div>
            <Switch checked={active} onChange={async () => {
                try {
                    const res = await patch_activate_deactivate_user(el.email, !active)
                    console.log(res)
                    if (res.data.status === "User updated successfully") {
                        setActive(!active)
                    }
                } catch (error) {
                    Swal.fire({ title: "Failed", text: "Something went wrong", icon: "error" });
                    setActive(active)
                }
            }}></Switch>
        </div>

        <div style={{ display: FLEX, alignItems: CENTER }}>
            <div>   {"Verified"}&nbsp;</div>
            <Switch checked={verified} onChange={async () => {
                try {
                    const res = await patch_verify_unverify_user(el.email, !verified)
                    console.log(res)
                    if (res.data.status === "User updated successfully") {
                        setVerified(!verified)
                    }
                } catch (error) {
                    Swal.fire({ title: "Failed", text: "Something went wrong", icon: "error" });
                    setVerified(verified)
                }
            }}></Switch>
        </div>
    </div>
}

export default function OrgDetailsPage() {
    const [data, setData] = useState({})
    const { id } = useParams()
    const [creditModal, setCreditModal] = useState(false)
    const [totalCredits, setTotalCredits] = useState(0)
    const [loading, setLoading] = useState(false)

    const toggle = () => {
        setCreditModal(!creditModal)
    }
    // console.log(id, "org_id")

    const fetchData = () => {
        setLoading(true)
        get_org_details_request(id).then((res) => {
            setData(res.data.orgs_details)
            setLoading(false)
        }).catch((error) => {
            // console.log(error)
            setLoading(false)
        })
    }

    const updateCredits = () => {
        setLoading(true)
        patch_edit_org_credits_user(id, totalCredits).then((res) => {
            if (res.data.status === "Credits updated successfully") {
                Swal.fire({ title: "Good job !", text: res.data.status, icon: "success" });
                setLoading(false)
                fetchData()
                toggle()
            }
        }).catch((e) => {
            // console.log("credits error", e)
            Swal.fire({ title: "Failed", text: "Something went wrong", icon: "error" });
            setLoading(false)
            toggle()
        })
    }
    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {

        if (Object.keys(data).length > 0) {
            setTotalCredits(data?.topupCredits)

        }
    }, [data])
    // console.log(data, "org_data_state")

    return <>
        {Object.keys(data).length > 0 ? <Fragment>
            <Breadcrumb parent="Manage Orgs" title={`${data?.companyName}`} />
            {loading && <SplitLoader center={true}>

            </SplitLoader>}
            <p style={{ textAlign: "end" }}><Clock size={12} /> Joined on &nbsp;{convertUTCToIST(data?.created_at)}</p>
            <div className="row">
                <div className="col-sm-6 col-xl-3 col-lg-6" >
                    <div className="card o-hidden">
                        <div className="bg-success b-r-4 card-body">
                            <div className="d-flex static-top-widget">
                                <div className="align-self-center text-center">
                                    <DollarSign />
                                </div>
                                <div className="flex-grow-1">
                                    <span className="m-0">{"Credits Left"}</span>
                                    <h4 className="mb-0 counter">
                                        <CountUp className="counter" end={data?.topupCredits} />&nbsp;<span>
                                            <Edit onClick={() => {
                                                setCreditModal(true)
                                            }} size={16} style={{ cursor: POINTER }} />
                                        </span>

                                    </h4>
                                    <DollarSign className="icon-bg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3 col-lg-6">
                    <div className="card o-hidden">
                        <div className="bg-warning b-r-4 card-body">
                            <div className="d-flex static-top-widget">
                                <div className="align-self-center text-center">
                                    <Users />
                                </div>
                                <div className="flex-grow-1"><span className="m-0">{"Employees"}</span>
                                    <h4 className="mb-0 counter">
                                        <CountUp className="counter" end={data?.employeeCount} />
                                    </h4>
                                    <Users className="icon-bg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 col-xl-3 col-lg-6">
                    <div className="card o-hidden">
                        <div className="bg-secondary b-r-4 card-body">
                            <div className="d-flex static-top-widget">
                                <div className="align-self-center text-center">
                                    <Video />
                                </div>
                                <div className="flex-grow-1"><span className="m-0">{"Total Videos"}</span>
                                    <h4 className="mb-0 counter">
                                        <CountUp className="counter" end={data?.statistics?.hits || 0} />
                                    </h4>
                                    <Video className="icon-bg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3 col-lg-6">
                    <div className="card o-hidden">
                        <div className="bg-danger b-r-4 card-body">
                            <div className="d-flex static-top-widget">
                                <div className="align-self-center text-center">
                                    <AlertTriangle />
                                </div>
                                <div className="flex-grow-1"><span className="m-0">{"Issues"}</span>
                                    <h4 className="mb-0 counter">
                                        <CountUp className="counter" end={0} />
                                    </h4>
                                    <AlertTriangle className="icon-bg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Container fluid={true}>
                {data?.statistics && data?.usage_data && <Row className="projectdetails">
                    <Col xl="4" sm="6">
                        <Card>
                            <CardBody>
                                <div className="d-flex">
                                    <h5 className="mb-0">{"Today"}</h5>
                                </div>
                                <div className="project-widgets text-center">
                                    <h1 className="font-primary counter">
                                        <Countup end={data?.usage_data?.today?.length} />
                                    </h1>
                                    <h6 className="mb-0">{"Video Created"}</h6>
                                </div>
                            </CardBody>
                            <CardFooter className="project-footer">
                                <Chart options={gradientChart1.options} series={[{
                                    name: [...data?.usage_data?.today?.map((_, key) => key)],
                                    data: [...data?.usage_data?.today?.map(item => item.credits)]
                                }]} height="100" type="area" />

                            </CardFooter>
                        </Card>
                    </Col>
                    <Col xl="4" sm="6">
                        <Card>
                            <CardBody>
                                <div className="d-flex">
                                    <h5 className="mb-0">{"Yesterday"}</h5>
                                </div>
                                <div className="project-widgets text-center">
                                    <h1 className="font-primary counter">
                                        <Countup end={data?.usage_data?.yesterday?.length} />
                                    </h1>
                                    <h6 className="mb-0">{"Video Created"}</h6>
                                </div>
                            </CardBody>
                            <CardFooter className="project-footer">
                                <Chart options={gradientChart1.options} series={[{
                                    name: [...data?.usage_data?.yesterday?.map((_, key) => key)],
                                    data: [...data?.usage_data?.yesterday?.map(item => item.credits)]
                                }]} height="100" type="area" />
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col xl="4" sm="6">
                        <Card>
                            <CardBody>
                                <div className="d-flex">
                                    <h5 className="mb-0">{"Last 7 days"}</h5>
                                </div>
                                <div className="project-widgets text-center">
                                    <h1 className="font-primary counter">
                                        <Countup end={data?.usage_data?.last7days?.length} />
                                    </h1>
                                    <h6 className="mb-0">{"Video Created"}</h6>
                                </div>
                            </CardBody>
                            <CardFooter className="project-footer">
                                <Chart options={gradientChart1.options} series={[{
                                    name: [...data?.usage_data?.last7days?.map((_, key) => key)],
                                    data: [...data?.usage_data?.last7days?.map(item => item.credits)]
                                }]} height="100" type="area" />
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col xl="4" sm="6">
                        <Card>
                            <CardBody>
                                <div className="d-flex">
                                    <h5 className="mb-0">{"Last 30 days"}</h5>
                                </div>
                                <div className="project-widgets text-center">
                                    <h1 className="font-primary counter">
                                        <Countup end={data?.usage_data?.last30days?.length} />
                                    </h1>
                                    <h6 className="mb-0">{"Video Created"}</h6>
                                </div>
                            </CardBody>
                            <CardFooter className="project-footer">
                                <Chart options={gradientChart1.options} series={[{
                                    name: [...data?.usage_data?.last30days?.map((_, key) => key)],
                                    data: [...data?.usage_data?.last30days?.map(item => item.credits)]
                                }]} height="100" type="area" />
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col xl="8" sm="6">
                        <Card>
                            <CardBody>
                                <div className="d-flex">
                                    <h5 className="mb-0">{"Last 6 months"}</h5>
                                </div>
                                <div className="project-widgets text-center">
                                    <h1 className="font-primary counter">
                                        <Countup end={data?.usage_data?.last6months?.length} />
                                    </h1>
                                    <h6 className="mb-0">{"Video Created"}</h6>
                                </div>
                            </CardBody>
                            <CardFooter className="project-footer">
                                <Chart options={gradientChart1.options} series={[{
                                    name: [...data?.usage_data?.last6months?.map((_, key) => key)],
                                    data: [...data?.usage_data?.last6months?.map(item => item.credits)]
                                }]} height="100" type="area" />
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>}
                <Row className="projectmore">

                    {/* <Col xl="5 xl-100">
                    <Card>
                        <CardBody>
                            <div className="show-value-top d-flex">
                                <div className="value-left d-inline-block">
                                    <div className="square bg-primary d-inline-block"></div>
                                    <span>{"DesignWork"}</span>
                                </div>
                                <div className="value-right d-inline-block">
                                    <div className="square d-inline-block bg-secondary"></div>
                                    <span>{"DevlopingWork"}</span>
                                </div>
                            </div>
                            <div className="smooth-chart flot-chart-container">
                                <Line data={gradientChartData} options={gradientChartOptions} height="360" />
                            </div>
                        </CardBody>
                    </Card>
                </Col> */}
                    <Col >
                        <Card>
                            <CardHeader className="card-header-border">
                                <h5>{"Users"}</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="new-users activity">

                                    {data?.users?.map((el) => <div key={el.id} className="d-flex align-items-center">
                                        <div className="flex-shrink-0 gradient-round m-r-30 gradient-line-1">
                                            <User />
                                        </div>
                                        <div className="flex-grow-1">
                                            <div style={{ display: FLEX, alignItems: CENTER, gap: "16px" }}>
                                                <h6 className="mb-1 f-w-700">{el.displayNmae}</h6>
                                                {el.userType && <span style={{ marginBottom: "16px" }} className="badge badge-info pill">{el.userType}</span>}
                                            </div>
                                            <p>{el.email}</p>
                                            <p>ID: &nbsp;<Copy style={{ cursor: POINTER }} size={12} onClick={() => {
                                                copyContent(el.id)
                                            }} />&nbsp;{el.id}</p>
                                        </div>
                                        <SwitchGroup el={el} />

                                    </div>)}
                                </div>
                                <Modal isOpen={creditModal} toggle={toggle} size="sm">
                                    <ModalHeader toggle={toggle}>{"Update Credits"}</ModalHeader>
                                    <ModalBody>
                                        <Input type="number" onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                updateCredits()
                                            }
                                        }} value={totalCredits} onChange={(e) => setTotalCredits(e.target.value)}></Input>
                                        <br></br>
                                        {loading ? <SplitLoader center={true}></SplitLoader> : <Button onClick={() => {
                                         updateCredits()
                                        }}>Update</Button>}
                                    </ModalBody>
                                </Modal>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </Fragment> : <div style={{ marginTop: "150px" }}><SplitLoader center={true} />

        </div>}
    </>
}