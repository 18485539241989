import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Loading from "../components/custom/Loading"
import { verifyTokenFunc } from "../constant/scripts/request"
import Logins from "../pages/login"
import { VERIFY } from "../redux/login/login.types"

export default function PrivateRoute({ children }) {
  const navigate = useNavigate()
  const { token, verified } = useSelector((state) => state.loginManager)
  const [isTokenValid, setIsTokenValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    if (verified === "0") {
        setLoading(true)
        verifyTokenFunc(token)
        .then((res) => {
          if (res.data.status === "Session Active") {
            dispatch({type:VERIFY})
            setIsTokenValid(true)
            setLoading(false)
          } else {
            setIsTokenValid(false)
            setLoading(false)
          }
        })
        .catch((error) => {
            setLoading(false)
          setIsTokenValid(false)
        })
    }
   
  }, [token])

  if (loading) return <Loading />

  if (!isTokenValid && verified === "0") {
    return <Logins />
  }
    return children
  
}
