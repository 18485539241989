export const loginEndpoint = "/auth/login"
export const verifyToken = "/auth/verify_token"

//get apis
export const get_overview_stats = "/dashboard/get_overview"
export const get_admins_list = `/auth/admin`
export const get_is_admin = (id) => `/auth/is_admin/${id}`
export const get_users_list = (page, count) => `/dashboard/get_users_list/${count}/${page}`
export const get_org_list = (page = 1, count = 10) => `/cam/get_organisations/${count}/${page}`
export const get_bots_list = (page = 1, count = 10) => `/dashboard/get_bots_list/${count}/${page}`
export const get_avatars_list = (page = 1, count = 10) => `/dashboard/get_avatars_list/${count}/${page}`
export const get_voices_list = (page = 1, count = 10) => `/dashboard/get_voices_list/${count}/${page}`
export const get_videos_list = (page = 1, count = 10) => `/dashboard/get_videos_list/${count}/${page}`
export const get_logics_list = (page = 1, count = 10) => `/dashboard/get_logics_list/${count}/${page}`
export const get_admin_logs = (page = 1, count = 10) => `/dashboard/get_admin_logs/${count}/${page}`
export const get_video_templates_list = (page = 1, count = 10) => `/dashboard/get_video_templates_list/${count}/${page}`
export const get_public_template_list = (page = 1, count = 10) => `/dashboard/public_template/${count}/${page}`
export const get_event_logs = `/dashboard/get_event_logs`
export const get_job_health = (id) => `/dashboard/get_job_health/${id}`
export const get_job_logs = (id) => `/dashboard/get_job_logs/${id}`
export const get_organisation_details = (id) => `/cam/get_organisation_details/${id}`

//search apis
export const search_users_list = (query, type) =>`/dashboard/search_users_list/${type}/${query}`
export const search_avatar_list = (query, type) =>`/dashboard/search_avatar_list/${type}/${query}`
export const search_voices_list = (query, type) =>`/dashboard/search_voices_list/${type}/${query}`
export const search_video_list = (query, type) =>`/dashboard/search_video_list/${type}/${query}`
export const search_bots_list = (query, type) =>`/dashboard/search_bots_list/${type}/${query}`
export const search_logics_list = (query, type) =>`/dashboard/search_logics_list/${type}/${query}`
export const search_orgs_list = (query, type) =>`/cam/search_organisation_list/${type}/${query}`
export const search_video_templates_list = (query, type) =>`/dashboard/search_video_templates_list/${type}/${query}`
export const search_public_templates_list = (query, type) =>`/dashboard/search_public_template/${type}/${query}`

//patch apis
export const patch_avatar = "/dashboard/avatar"
export const patch_voice = "/dashboard/voice"
export const patch_video = "/dashboard/video"
export const patch_logic = "/dashboard/logic"
export const patch_bot = "/dashboard/bot"
export const patch_admin = `/auth/admin`
export const patch_public_template = "/dashboard/public_template"
export const patch_video_template = "/dashboard/video_template"
export const verify_unverify_user = (email, state) => `/cam/verify_unverify_user/${email}/${state}`
export const activate_deactivate_user = (email, state) => `/cam/activate_deactivate_user/${email}/${state}`
export const edit_org_credits = (orgId, updateCount) => `/cam/edit_org_credits/${orgId}/${updateCount}`

//post apis
export const post_voice = "/dashboard/voice"
export const post_avatar = "/dashboard/avatar"
export const post_public_template = "/dashboard/public_template"
export const post_admin = `/auth/admin`
