import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BLOCK, BOLD, CENTER, FILL_10PARENT, FILL_20PARENT, FILL_30PARENT, FILL_50PARENT, FILL_80PARENT, FILL_PARENT, FLEX, NONE, POINTER, SB, START, TOP } from "../../../constant/typograpy/properties";
import px from "../../../constant/scripts/px";
import UserManager from "../AdvanceComp/UserManger";
import { IST, converIntoFormData, convertUTCToIST } from "../../../constant/scripts/helper";
import { get_job_health_request, get_job_logs_request } from "../../../constant/scripts/request";
import { BLACK, WHITE } from "../../../constant/typograpy/colors";
import ScrollBar from "react-perfect-scrollbar";

export default function VideoActionPanel({ id, actions, el, keyy, element = <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i> }) {
    const [modal, setModal] = useState(false)
    const [status, setStatus] = useState(el.status)
    const [name, setName] = useState(el.name)
    const [allocatedUsers, setAllocatedUsers] = useState(el.users)
    const toggle = () => setModal(!modal)
    const [health, setHealth] = useState("")
    const [jobLog, setJobLog] = useState([])
    const [healthLoading, setHealthLoading] = useState(false)
    const [jobLoading, setJobLoading] = useState(false)
    // const [dropdownOpen, setDropdownOpen] = useState(false);
    // const toggle = () => setDropdownOpen(prevState => !prevState);
    const handleCheckHealth = (id) => {
        setHealthLoading(true)
        get_job_health_request(id).then((health) => {
            setHealth(health.data)
            setHealthLoading(false)
        }).catch((error) => {
            setHealthLoading(false)
        })
    }


    const handleJobLog = (id) => {
        setJobLoading(true)
        get_job_logs_request(id).then((job) => {
            setJobLog(job?.data)
            setJobLoading(false)
        }).catch((error) => {
            setJobLoading(false)
        })
    }
    return <>
        <div key={keyy}>
            {/* <span style={{ cursor: POINTER }}><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></span> */}
            <span>
                <span style={{ cursor: POINTER }} onClick={toggle}>{element}</span>
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>{"Edit Video"}</ModalHeader>
                    <ModalBody>
                        <div className="card">
                            <div className="card-header">
                                <h5>{el.name.replaceAll("_", " ")}</h5><span>Modify video properties here and click on save to make changes.</span>
                            </div>
                            <div className="card-body">
                                <form className="theme-form">

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="videoName">{"Video Name"}</label>
                                        <div className="col-sm-9">
                                            <input value={name} onChange={(e) => setName(e.target.value)} className="form-control" id="name" type="text" placeholder="Video Name" />                                    </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="createdAt">{"Created at"}</label>
                                        <div className="col-sm-9">
                                            <p className="form-control">{convertUTCToIST(el.created_at)}</p>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="status">{"Status"}</label>
                                        <div className="col-sm-9">
                                            <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                                                <option value="draft">Draft</option>
                                                <option value="ready">Ready</option>
                                                <option value="rendering">Rendering</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="manage">{"Manage Users"}</label>
                                        <div className="col-sm-9">
                                            <div className="form-control" style={{ padding: "16px" }}>
                                                <UserManager allocatedUsers={allocatedUsers} setAllocatedUsers={setAllocatedUsers} filterList={[{ name: "ID", value: "id" }, { name: "Email", value: "email" }, { name: "Name", value: "displayName" }]} />
                                            </div>
                                        </div>
                                    </div>

                                    {el?.video_details?.aws_job_id && <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="check status">{"Check Health & Logs"}</label>
                                        <div className="col-sm-9">
                                            <div className="form-control" style={{ padding: "16px" }}>
                                                <Button color="success" onClick={() => {
                                                    handleCheckHealth(el.video_details.aws_job_id)
                                                }}>{healthLoading ? <span style={{ width: "10px", height: "10px", margin: 6, backgroundColor: WHITE }} className="rotate dashed"></span> : "Check Health"}</Button>
                                                &nbsp;
                                                <Button color="warning" onClick={() => {
                                                    handleJobLog(el.video_details.aws_job_id)
                                                }}>{jobLoading ? <span style={{ width: "10px", height: "10px", margin: 6, backgroundColor: WHITE }} className="rotate dashed"></span> : "Check Logs"}</Button>
                                                &nbsp;
                                                <br />
                                                <div className={`${health !== "" && "badge"} ${health === "FAILED" ? "badge-danger" : "badge-info"}`}>
                                                    {health}
                                                </div>
                                                <br />
                                                <div style={{ backgroundColor: BLACK, borderRadius: "8px", padding: "8px", marginTop:"8px", display:jobLog.length === 0 ? NONE : BLOCK }}>
                                                    <ScrollBar className="scroll">
                                                        {jobLog?.map(({ timestamp, message }, i) => {
                                                            return <>
                                                                <div key={`log${i}`} style={{ display: FLEX, gap: "4px", alignItems: START }}>
                                                                    <div className="badge badge-warning">{IST(timestamp)}</div>

                                                                    <div style={{ color: WHITE }}>{message}</div>

                                                                </div>
                                                                <br />
                                                            </>

                                                        })}
                                                    </ScrollBar>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                </form>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {

                            actions.handlePatch(converIntoFormData({
                                db_id: id,
                                name: name,
                                video_status: status,
                                users: JSON.stringify(allocatedUsers)
                            }))
                        }}>{"Save"}</Button>
                        <Button color="secondary" onClick={toggle}>{"Cancel"}</Button>
                    </ModalFooter>
                </Modal>
            </span>
        </div>
    </>
}