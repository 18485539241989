import { combineReducers } from "redux"
import Customizer from "./customizer/reducer"
import { loginReducer } from "./login/login.reducer"
import { overviewReducer } from "./overview/overview.reducer"
import { userReducer } from "./user/user.reducer"
import { orgReducer } from "./org/org.reducer"
import { assetsReducer } from "./assets/assets.reducer"
import { mediaReducer } from "./media/media.reducer"
import { adminReducer } from "./admin/admin.reducer"
import { logsReducer } from "./logs/logs.reducer"

const reducers = combineReducers({
  Customizer,
  loginManager:loginReducer,
  overviewManager:overviewReducer,
  userManager:userReducer,
  orgManager:orgReducer,
  assetsManager:assetsReducer,
  mediaManager:mediaReducer,
  adminManager:adminReducer,
  logManager:logsReducer
})

export default reducers
