import axios from "axios"
import { REGULATOR_URL } from "../../constant/baseUrl"
import { get_logics_list, get_video_templates_list, get_videos_list, patch_logic, patch_video, patch_video_template, search_logics_list, search_video_list, search_video_templates_list } from "../../constant/endpoints"
import { APPLICATION_JSON, CONTENT_TYPE, X_ACCESS_TOKEN } from "../../constant/utils"
import { getTokenFromLocal } from "../../constant/scripts/helper"

export const get_videos_list_res = async(page) => {

    const res = await axios.get(REGULATOR_URL+get_videos_list(page), {
        headers:{
            [CONTENT_TYPE]:APPLICATION_JSON,
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        }
    })

    return res

}

export const get_logics_list_res = async(page) => {

    const res = await axios.get(REGULATOR_URL+get_logics_list(page), {
        headers:{
            [CONTENT_TYPE]:APPLICATION_JSON,
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        }
    })

    return res

}



export const get_video_templates_list_res = async(page) => {

    const res = await axios.get(REGULATOR_URL+get_video_templates_list(page), {
        headers:{
            [CONTENT_TYPE]:APPLICATION_JSON,
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        }
    })

    return res

}


//patch apis
export const patch_video_res = async(formdata) => {

    const res = await axios({
        method:"patch",
        data:formdata,
        url:REGULATOR_URL+patch_video,
        headers:{
            [CONTENT_TYPE]:"multipart/form-data",
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        },
    
    })

    // console.log(res)
    return res

}

export const patch_logic_res = async(formdata) => {

    const res = await axios({
        method:"patch",
        data:formdata,
        url:REGULATOR_URL+patch_logic,
        headers:{
            [CONTENT_TYPE]:"multipart/form-data",
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        },
    
    })

    // console.log(res)
    return res

}

export const patch_video_template_res = async(formdata) => {

    const res = await axios({
        method:"patch",
        data:formdata,
        url:REGULATOR_URL+patch_video_template,
        headers:{
            [CONTENT_TYPE]:"multipart/form-data",
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        },
    
    })

    console.log(res)
    return res

}

// search apis

export const search_videos_list_res = async(query, type) => {

    const res = await axios.get(REGULATOR_URL+search_video_list(query, type), {
        headers:{
            [CONTENT_TYPE]:APPLICATION_JSON,
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        }
    })
// console.log(res, "search ava", REGULATOR_URL+search_avatar_list(query, type))
    return res

}


export const search_video_templates_list_res = async(query, type) => {

    const res = await axios.get(REGULATOR_URL+search_video_templates_list(query, type), {
        headers:{
            [CONTENT_TYPE]:APPLICATION_JSON,
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        }
    })
    return res

}


export const search_logics_list_res = async(query, type) => {

    const res = await axios.get(REGULATOR_URL+search_logics_list(query, type), {
        headers:{
            [CONTENT_TYPE]:APPLICATION_JSON,
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        }
    })
    return res

}