import React, { Fragment } from 'react';
import man from '../../../assets/images/dashboard/user.png'
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';
import {ELANA,GeneralManager} from '../../../constant'
import { USER_DETAILS } from '../../../constant/localKeys';
import { RANDOM_AVATAR_IMAGE_URL } from '../../../constant/utils';

const UserPanel = () => {
    const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS))
    
    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <div>
                    <img className="img-60 rounded-circle lazyloaded blur-up" src={userDetails?.userData?.props?.profilePic} alt="#" />
                    {/* <div className="profile-edit">
                        <Link to={`${process.env.PUBLIC_URL}/users/userEdit`}>
                            <Edit />
                        </Link>
                    </div> */}
                </div>
                <h6  style={{ textOverflow:"ellipsis", overflowX:"hidden", marginTop:"16px", fontSize:"12px"}}>{userDetails?.userData?.props?.name}</h6>
                <p>{userDetails?.userData?.ability === "admin" ? <span className="badge  badge-info">{"Admin"}</span> : userDetails?.userData?.ability === "superadmin" ? <span className="badge  badge-warning">{"Super Admin"}</span> : <span className="badge  badge-secondary">{userDetails?.userData?.ability}</span>}</p>
            </div>
        </Fragment>
    );
};

export default UserPanel;