import axios from "axios"
import { REGULATOR_URL } from "../../constant/baseUrl"
import { get_admins_list, get_is_admin, patch_admin, post_admin } from "../../constant/endpoints"
import { getTokenFromLocal } from "../../constant/scripts/helper"
import { APPLICATION_JSON, CONTENT_TYPE, X_ACCESS_TOKEN } from "../../constant/utils"

export const get_admins_list_res = async() => {

    const res = await axios.get(REGULATOR_URL+get_admins_list, {
        headers:{
            [CONTENT_TYPE]:APPLICATION_JSON,
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        }
    })
// console.log(res)
    return res

}


export const get_is_admin_res = async(id) => {

    const res = await axios.get(REGULATOR_URL+get_is_admin(id), {
        headers:{
            [CONTENT_TYPE]:APPLICATION_JSON,
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        }
    })

    return res

}



// patch apis
export const patch_admin_res = async(formdata) => {

    const res = await axios({
        method:"patch",
        data:formdata,
        url:REGULATOR_URL+patch_admin,
        headers:{
            [CONTENT_TYPE]:"multipart/form-data",
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        },
    
    })

    // console.log(res)
    return res

}



// post apis
export const post_admin_res = async(formdata) => {

    const res = await axios({
        method:"post",
        data:formdata,
        url:REGULATOR_URL+post_admin,
        headers:{
            [CONTENT_TYPE]:"multipart/form-data",
            [X_ACCESS_TOKEN]:getTokenFromLocal()
        },
    
    })

    // console.log(res)
    return res

}