import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import Breadcrumb from '../../../components/common/breadcrumb'
import { useDispatch, useSelector } from 'react-redux'
import { Copy, Framer, Video } from 'react-feather'
import { convertUTCToIST, copyContent } from '../../../constant/scripts/helper'
import { CENTER, FLEX, POINTER } from '../../../constant/typograpy/properties'
import { useSearchParams } from 'react-router-dom'
import Paginate from '../../../components/custom/AdvanceComp/Paginate'
import "../../styles/styles.css"
import SearchBar from '../../../components/custom/AdvanceComp/SearchBar'
import SplitLoader from '../../../components/custom/SplitLoader'
import SweetAlert from "sweetalert2"
import { get_logics_list_api, patch_logic_api, search_logics_list_api } from '../../../redux/media/media.actions'
import VideoActionPanel from '../../../components/custom/ActionPanels/VideoActionPanel'
import LogicActionPanel from '../../../components/custom/ActionPanels/LogicActionPanel'

const filterList = [
    { name: "User ID", value: "user_id" },
    { name: "ID", value: "id" },
    { name: "Bot ID", value: "bot_id" },
    { name: "Name", value: "name" },
]

const ManageLogics = () => {
    const { logics_list, logics_loading, logics_error } = useSelector((state) => state.mediaManager)
    const [logicsData, setLogicsData] = useState([])
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const [page, setPage] = useState(Number(searchParams.get("page")) || 1)
    const [visible, setVisible] = useState(false)
    const [query, setQuery] = useState(searchParams.get("query") || "")
    const [search, setSearch] = useState(searchParams.get("type") || filterList[3].value)
    const [refresh, setRefresh] = useState(false)
    useEffect(() => {
        setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
    }, [page])

    useEffect(() => {

        if (query.trim() !== "") {
            dispatch(search_logics_list_api(query.trim(), search)) // search all logics info
        } else {
            //comment out to get logics list without search
            dispatch(get_logics_list_api(page)) // gives all logics info

        }

    }, [searchParams, search, refresh])
    const handlePatch = (formdata) => {
        dispatch(patch_logic_api(formdata, SweetAlert)).then(() => {
            setLogicsData([])
            setRefresh((prev) => !prev)
        }).catch((e) => {
            console.log(e)
        })
    }

    useEffect(() => {

        setLogicsData(() => logics_list.logics_list?.map((el, i) => {
            let copyId = el.id
            let botId = el.botId
            el.lid = <span key={i + "copy-logic-id"}><Copy size={12} style={{ cursor: POINTER }} onClick={() => {
                copyContent(copyId)
            }} /> <span>{copyId}</span></span>

            el.bId = <span key={i + "copy-logic-lid"}><Copy size={12} style={{ cursor: POINTER }} onClick={() => {
                copyContent(botId)
            }} /> <span>{botId}</span></span>

            el.image =  <LogicActionPanel keyy={i + "logic"} id={el.id} el={el} actions={{handlePatch}} element={<Framer />} /> 
            el.action = <LogicActionPanel keyy={i + "log"} id={el.id} el={el} actions={{handlePatch}} />
            return el
        }))

    }, [logics_list])

    // console.log(logics_list)

    const columns = [
        {
            name: "Image",
            selector: (row) => row.image,
            sortable: true,
            center: true,
        },
        {
            name: "ID",
            selector: (row) => row.lid,
            sortable: true,
            center: true,
        },
        {
            name: "Bot ID",
            selector: (row) => row.bId,
            sortable: true,
            center: true,
        },

        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            center: true,
        },
        {
            name: "Created At",
            selector: (row) => convertUTCToIST(row.created_at),
            sortable: true,
            center: true,
        },
        {
            name: "Status",
            selector: (row) => row.status,
            sortable: true,
            center: true,
        },

        {
            name: "Action",
            selector: (row) => row.action,
            sortable: true,
            center: true,
        },
    ]

    const handleSearch = (e) => {
        if (e.key === "Enter") {
            setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
        }
    }

    useEffect(() => {
        if (query.trim() === "") {
            //comment out to get logics list without search
            dispatch(get_logics_list_api(page)) // gives all logics info
            setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
        } 
    }, [query])

    useEffect(() => {
        setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
    }, [search])


    return (
        <div>
            <Breadcrumb title="Manage Logics" parent="PAM" />
            <div className="container-fluid">
                <div className="row">
                    {/* <!-- Individual column searching (text inputs) Starts--> */}
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">

                                <h5>{"Manage all the logics from here"} </h5><span>{""}</span>

                            </div>
                            <div style={{ margin: "16px" }}>
                                <SearchBar filters={true} top={80} visible={visible} setVisible={setVisible} filterOptions={filterList} query={query} setQuery={setQuery} search={search} setSearch={setSearch} onKeyDown={handleSearch} />
                            </div>
                            <div className="card-body">
                                <div className="table-responsive product-table">
                                    {logics_loading ? <SplitLoader center={true} /> : <DataTable
                                        noHeader
                                        className='no-scroll-body'
                                        data={logicsData}
                                        columns={columns}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Individual column searching (text inputs) Ends--> */}
                </div>
            </div>
            <div style={{ display: FLEX, justifyContent: "end", alignItems: CENTER, margin: "8px" }}>
                {logics_list.total_logics !== undefined && <Paginate page={page} setPage={setPage} totalPage={Math.ceil(logics_list.total_logics / 10)} />}
            </div>
        </div>
    )
}

export default ManageLogics