import { useNavigate } from "react-router-dom"
import { IS_AUTH, LOGIN_STATUS, TOKEN, USER_DETAILS, VERIFIED } from "../../constant/localKeys"
import { LOGIN_ERROR, LOGIN_LOADING, LOGIN_SUCCESS, LOGOUT, RESET, VERIFY } from "./login.types"
import { ONE, ZERO } from "../../constant/utils"

const initialState = {
    loading:false,
    error:false,
    token: localStorage.getItem(TOKEN) || "",
    userDetails: JSON.parse(localStorage.getItem(USER_DETAILS) ) || {},
    verified:ZERO
}


export const loginReducer = (state = initialState, {type, payload}) => {
    switch(type) {
        case LOGIN_LOADING: {

            return {...state, loading:true}
        }

        case LOGIN_SUCCESS: {
            localStorage.setItem(USER_DETAILS, JSON.stringify(payload))
            localStorage.setItem(IS_AUTH, ONE)
            localStorage.setItem(TOKEN, payload.accessToken)
            localStorage.setItem(LOGIN_STATUS, ONE)
            return {
                ...state, loading:false, error:false, token:payload.accessToken, userDetails:payload.userData
            }
        }

        case LOGIN_ERROR: {
            return {
                ...state, loading:false, error:true
            }
        }

        case RESET: {
            return {...state, error:false, loading:false}
        }

        case LOGOUT: {
            localStorage.setItem(USER_DETAILS, null)
            localStorage.setItem(TOKEN, "")
            return {...state, token:"", USER_DETAILS:{}, verified:ZERO}
        }

        case VERIFY: {

            return {...state, verified:ONE}

        }

        default: {
            return state
        }
    }

    
}