import { GET_ORG_ERROR, GET_ORG_LOADING, GET_ORG_SUCCESS } from "./org.types"

 const initialState = {
    loading:false,
    error:false,
    org_list:{}
 }

 export const orgReducer = (state = initialState, {type, payload}) => {

    switch (type) {
        
        case GET_ORG_LOADING: {

            return {...state, loading:true}
        }

        case GET_ORG_SUCCESS: {

            return {...state, loading:false, error:false, org_list:payload}
        }

        case GET_ORG_ERROR: {

            return {...state, loading:false, error:true}
        }

        default: {
            return state
        }
    }
 }