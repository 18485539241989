import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BLOCK, BOLD, CENTER, COLUMN, FILL_30PARENT, FILL_50PARENT, FILL_80PARENT, FILL_PARENT, FLEX, NONE, POINTER, SB, START, TOP } from "../../../constant/typograpy/properties";
import px from "../../../constant/scripts/px";
import { BLACK, GRAY, SKYBLUE, WHITE } from "../../../constant/typograpy/colors";
import UserManager from "../AdvanceComp/UserManger";
import { converIntoFormData } from "../../../constant/scripts/helper";
import { Mic, Pause, Play } from "react-feather";
import PreviewAudio from "../AdvanceComp/PreviewAudio";
import { useRef } from "react";

export default function VoiceActionPanel({ id, actions, el, keyy, element = <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i> }) {
    const [modal, setModal] = useState(false)
    const [gender, setGender] = useState(el.gender)
    const [accent, setAccent] = useState(el.accent)
    const [language, setLanguage] = useState(el.language)
    const [value, setValue] = useState(el.value)
    const [audioLink, setAudioLink] = useState(el.previewAudio)
    const [disName, setDisName] = useState(el.label)
    const [allocatedUsers, setAllocatedUsers] = useState(el.users)
    const toggle = () => setModal(!modal)
    // const [dropdownOpen, setDropdownOpen] = useState(false);
    // const toggle = () => setDropdownOpen(prevState => !prevState);

    // console.log(el)

    return <>
        <div key={keyy}>
            {/* <span style={{ cursor: POINTER }}><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></span> */}
            <span>
                <span style={{ cursor: POINTER }} onClick={toggle}>{element}</span>
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>{"Edit Voice"}</ModalHeader>
                    <ModalBody>

                        <div className="card">
                            <div className="card-header">
                                <h5>{el.label.replaceAll("_", " ")}</h5><span>Modify voice properties here and click on save to make changes.</span>
                            </div>
                            <div className="card-body">
                                <form className="theme-form">

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="voiceName">{"Voice Name"}</label>
                                        <div className="col-sm-9">
                                            <input value={disName} onChange={(e) => setDisName(e.target.value)} className="form-control" id="voiceName" type="text" placeholder="Voice Name" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="previewLink">{"Audio Preview Link"}</label>
                                        <div className="col-sm-9">
                                            <div style={{ display: FLEX, alignItems: CENTER, justifyContent: SB, gap: "8px" }}>
                                                <input className="form-control" value={audioLink} onChange={(e) => setAudioLink(e.target.value)} id="previewLink" type="text" placeholder="Audio Preview Link" />
                                                <PreviewAudio src={audioLink} />

                                            </div>
                                        </div>

                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="language">{"Language"}</label>
                                        <div className="col-sm-9">
                                            <input value={language} onChange={(e) => setLanguage(e.target.value)} className="form-control" id="language" type="text" placeholder="Language" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="value">{"Value"}</label>
                                        <div className="col-sm-9">
                                            <input value={value} onChange={(e) => setValue(e.target.value)} className="form-control" id="value" type="text" placeholder="Value" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="gender">{"Gender"}</label>
                                        <div className="col-sm-9">
                                            <select className="form-control" value={gender} onChange={(e) => setGender(e.target.value)}>
                                                <option value="male">male</option>
                                                <option value="female">female</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="accent">{"Accent"}</label>
                                        <div className="col-sm-9">
                                            <input value={accent} onChange={(e) => setAccent(e.target.value)} className="form-control" id="accent" type="text" placeholder="Accent" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label" htmlFor="manage">{"Manage Users"}</label>
                                        <div className="col-sm-9">
                                            <div className="form-control" style={{ padding: "16px" }}>
                                                <UserManager allocatedUsers={allocatedUsers} setAllocatedUsers={setAllocatedUsers} filterList={[{ name: "ID", value: "id" }, { name: "Email", value: "email" }, { name: "Name", value: "displayName" }]} />
                                            </div>
                                        </div>
                                    </div>

                                </form>

                            </div>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {

                            actions.handlePatch(converIntoFormData({
                                db_id: id,
                                preview_audio_link: audioLink,
                                gender: gender,
                                accent: accent,
                                value: value,
                                label: disName,
                                language: language,
                                users: JSON.stringify(allocatedUsers)
                            }))
                        }}>{"Save"}</Button>
                        <Button color="secondary" onClick={toggle}>{"Cancel"}</Button>
                    </ModalFooter>
                </Modal>
            </span>
        </div>
    </>
}