import { Copy, Plus, User, UserMinus, UserPlus, Users } from "react-feather";
import { CENTER, FILL_PARENT, FLEX, POINTER } from "../../../constant/typograpy/properties";
import px from "../../../constant/scripts/px";
import { copyContent } from "../../../constant/scripts/helper";
import ScrollBar from "react-perfect-scrollbar";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_users_list_api } from "../../../redux/user/user.actions";
import SplitLoader from "../SplitLoader";
import SearchBar from "./SearchBar";
import { GRAY } from "../../../constant/typograpy/colors";
import UserComp from "../UserComp";


export default function UserManager({ allocatedUsers, setAllocatedUsers, filterList }) {
    const { users_list, loading, error } = useSelector((state) => state.userManager)
    const dispatch = useDispatch()
    const [modal, setModal] = useState(false)
    const [visible, setVisible] = useState(false)
    const toggle = () => setModal(!modal)
    const [query, setQuery] = useState("")
    const [search, setSearch] = useState(filterList[1].value)
    const [displayList, setDisplayList] = useState([])
    const [allocatedList, setAllocatedList] = useState([])
    const [displayAllocatedList, setDisplayAllocatedList] = useState([])
    const [toggleList, setToggleList] = useState("1")
    const users = allocatedUsers.map((el, i) => <span key={"userss" + i} style={{ margin: "4px" }} className="badge badge-light"><span><User /></span>&nbsp;{el} &nbsp; <span><Copy style={{ cursor: POINTER }} onClick={() => {
        copyContent(el)
    }} /></span></span>)

    useEffect(() => {

        if (Object.keys(users_list).length < 1) {
            dispatch(get_users_list_api(1, -1))
        }

    }, [])

    let id
    useEffect(() => {
        if (id) {
            clearTimeout(id)
        }
        if (query === "") {
            setDisplayList([])
            // console.log(displayList, "newList")
        } else {
            id = setTimeout(() => {
                const newList = users_list.users_list?.filter((el) => {
                    if (el[search].toLowerCase().includes(query.toLowerCase().trim())) {
                        return el
                    }
                })
                setDisplayList(newList)
                // console.log(displayList, "newList")
            }, 200)
        }

        return () => {
            clearTimeout(id);
        }

    }, [query, users_list, search, allocatedUsers])

    useEffect(() => {
        const list = []
        users_list.users_list?.forEach((el) => {

            if (allocatedUsers.includes(el.id)) {
                list.push(el)
            }

        })
        setAllocatedList(list)

    }, [users_list, allocatedUsers])

    let aid
    useEffect(() => {
        if (aid) {
            clearTimeout(aid)
        }
        if (query === "") {
            setDisplayAllocatedList(allocatedList)
            // console.log(displayList, "newList")
        } else {
            id = setTimeout(() => {
                const newList = allocatedList?.filter((el) => {
                    if (el[search].toLowerCase().includes(query.toLowerCase().trim())) {
                        return el
                    }
                })
                setDisplayAllocatedList(newList)
                // console.log(displayList, "newList")
            }, 200)
        }

        return () => {
            clearTimeout(aid);
        }


    }, [query, allocatedList, search])

    // console.log(users_list, search)
    // console.log(allocatedUsers)
    if (loading) return <SplitLoader center={true} />
    return <div style={{ display: FLEX, width: FILL_PARENT, gap: "4px", alignItems: CENTER }}>
        <div style={{ display: FLEX, alignItems: CENTER, width: FILL_PARENT }}>
            <ScrollBar className="scroll">
                {users.length > 0 ? users : <h6 style={{ color: GRAY, fontWeight: 500, fontSize: 14 }}>No user allocated</h6>}
            </ScrollBar>
        </div>
        <UserPlus size={32} onClick={toggle} style={{ width: "60px", borderRadius: px(50), padding: "8px 8px", border: "1px solid gray", cursor: POINTER, boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px" }} />

        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>{"Manage Users"}</ModalHeader>
            <ModalBody>
                <div >
                    <SearchBar filters={true} top={80} visible={visible} setVisible={setVisible} filterOptions={filterList} query={query} setQuery={setQuery} search={search} setSearch={setSearch} />
                </div>

                <Nav className="border-tab" tabs style={{marginTop:"24px"}}>
                    <NavItem>
                        <NavLink href="#" className={toggleList === '1' ? 'active' : ''} onClick={() => setToggleList('1')}><i><UserMinus size={16} /></i>{"Allocated Users"}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="#" className={toggleList === '2' ? 'active' : ''} onClick={() => setToggleList('2')}><i><Users size={16} /></i>{"All Users"}</NavLink>
                    </NavItem>

                </Nav>

                <TabContent activeTab={toggleList}>
                    <TabPane className="fade show" tabId="1">
                        <ScrollBar className="scroll">
                            {displayAllocatedList?.map((el, i) => <UserComp keyy={"users-" + i} el={el} setAllocatedUsers={setAllocatedUsers} add={false} />)}
                        </ScrollBar>

                    </TabPane>

                    <TabPane tabId="2">
                        <ScrollBar className="scroll">
                            {displayList?.map((el, i) => {
                                if (!allocatedUsers.includes(el.id)) {
                                    return <UserComp keyy={"user-" + i} el={el} setAllocatedUsers={setAllocatedUsers} add={true} />
                                }
                            })}
                        </ScrollBar>

                    </TabPane>
                </TabContent>
            </ModalBody>
            <ModalFooter>
                <h6 style={{ color: GRAY, padding: "8px" }}>Search and Modify user permission</h6>
                <Button onClick={toggle}>Continue</Button>
            </ModalFooter>
        </Modal>
    </div>
}