
export const FILL_PARENT = "100%"
export const FILL_90PARENT = "90%"
export const FILL_80PARENT = "80%"
export const FILL_70PARENT = "70%"
export const FILL_60PARENT = "60%"
export const FILL_50PARENT = "50%"
export const FILL_40PARENT = "40%"
export const FILL_30PARENT = "30%"
export const FILL_20PARENT = "20%"
export const FILL_10PARENT = "10%"
export const FILL_85PARENT = "85%"
export const FILL_75PARENT = "75%"
export const FILL_65PARENT = "65%"
export const FILL_55PARENT = "55%"
export const FILL_45PARENT = "45%"
export const FILL_35PARENT = "35%"
export const FILL_25PARENT = "25%"
export const FILL_15PARENT = "15%"
export const FILL_5PARENT = "5%"
export const VH_100 = "100vh"

//Positions
export const FIXED = "fixed"
export const RELATIVE = "relative"
export const ABSOLUTE = "absolute"
export const STICKY = "sticky"

//Font Weight
export const BOLD = "bold"
export const ITALIC = "italic"
export const NORMAL = "normal"

//GridTempletColumns
export const R1 = "repeat(1,1fr)"
export const R2 = "repeat(2,1fr)"
export const R3 = "repeat(3,1fr)"
export const R4 = "repeat(4,1fr)"
export const R5 = "repeat(5,1fr)"
export const R6 = "repeat(6,1fr)"
export const R7 = "repeat(7,1fr)"
export const R8 = "repeat(8,1fr)"
export const R9 = "repeat(9,1fr)"
export const R10 = "repeat(10,1fr)"
export const R11 = "repeat(11,1fr)"
export const R12 = "repeat(12,1fr)"

//Margins
export const AUTO = "auto"

//curson
export const POINTER = "pointer"

//Alignments
export const CENTER = "center"
export const LEFT = "left"
export const TOP = "top"
export const BOTTOM = "bottom"
export const RIGHT = "right"
export const START = "start"
export const SB = "space-between"
export const SE = "space-evenly"

//display
export const FLEX = "flex"
export const GRID = "grid"
export const BLOCK = "block"
export const NONE = "none"
export const INLINE_BLOCK = "inline-block"


//Flex direction
export const ROW = "row"
export const COLUMN = "column"

//border
export const SOLID = "solid"

//text decoration
export const LINE_THROUGH = "line-through"
export const UNDERLINE = "underline"

//types
export const CHECK_BOX = "checkbox"

//justify
export const FLEX_START = "flex-start"
export const FLEX_END = "flex-end"

//custom 
export const PERSONATE_BORDER = "radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 3px, transparent 3px) 0% 0%/8px 8px no-repeat, radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px) 100% 0%/8px 8px no-repeat,radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px) 0% 100%/8px 8px no-repeat,radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100% 100%/8px 8px no-repeat,linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 10px) calc(100% - 16px) no-repeat,linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 16px) calc(100% - 10px) no-repeat,linear-gradient(90deg, #d72b99 0%, #48abe0 100%)"