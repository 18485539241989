import { useEffect, useState } from "react";
import { Copy } from "react-feather";
import ScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button } from "reactstrap";
import Breadcrumb from "../../../components/common/breadcrumb";
import Paginate from "../../../components/custom/AdvanceComp/Paginate";
import SplitLoader from "../../../components/custom/SplitLoader";
import EventCharts from "../../../components/custom/eventCharts";
import { Activitys } from "../../../constant";
import { LOGIN_STATUS } from "../../../constant/localKeys";
import { converToUTC, copyContent } from "../../../constant/scripts/helper";
import px from "../../../constant/scripts/px";
import { CENTER, FILL_PARENT, FLEX, GRID, POINTER } from "../../../constant/typograpy/properties";
import { ONE, ZERO } from "../../../constant/utils";
import { get_is_admin_api } from "../../../redux/admin/admin.actions";
import { get_admin_logs_api, get_event_logs_api } from "../../../redux/logs/logs.actions";
import { RESET_LOGS } from "../../../redux/logs/logs.types";
import { get_org_list_api } from "../../../redux/org/org.actions";
import { get_overview_api } from "../../../redux/overview/overview.actions";
import { get_public_template_list_api } from "../../../redux/assets/assets.actions";


export default function Overview() {

    const [showLoginSuccessful, setShowLoginSuccessful] = useState(false)
    const { data, loading, error } = useSelector((state) => state.overviewManager)
    const {org_list} = useSelector((state) => state.orgManager)
    // const {users_list} = useSelector((state) => state.userManager)
    // const {avatars_list, bots_list, voices_list} = useSelector((state) => state.assetsManager)
    const {pt_list} = useSelector((state) => state.assetsManager)
    // const {videos_list, templates_list, logics_list} = useSelector((state) => state.mediaManager)
    const { isAdmin_loading, isAdmin_error, isAdmin } = useSelector((state) => state.adminManager)
    const { logs_loading, logs_error, logs_list, elogs_list } = useSelector((state) => state.logManager)
    const {userDetails} = useSelector((state) => state.loginManager)
    const [logsData, setLogsData] = useState([])
    const [page, setPage] = useState(1)
    const dispatch = useDispatch()
    useEffect(() => {

        if (localStorage.getItem(LOGIN_STATUS) === ONE) {
            // console.log("status 1")
            dispatch({type:RESET_LOGS})
            setShowLoginSuccessful(true)
            localStorage.setItem(LOGIN_STATUS, ZERO)
        } else {
            // console.log("status 0")

            setShowLoginSuccessful(false)
        }

    }, [])

    useEffect(() => {
        if (Object.keys(data).length === 0) {
            dispatch(get_overview_api())
        }
        // dispatch(get_users_list_api()) //gives all user info
        dispatch(get_org_list_api()) // gives all org info
        // dispatch(get_avatars_list_api()) // gives all avatars info
        // dispatch(get_bots_list_api()) // gives all avatars info
        // dispatch(get_videos_list_api()) // gives all videos info
        // dispatch(get_logics_list_api()) // gives all logics info
        // dispatch(get_voices_list_api()) // gives all logics info
        // dispatch(get_admins_list_api()) // gives all logics info
        // dispatch(get_video_templates_list_api()) // gives all video_template info
        dispatch(get_public_template_list_api()) // gives all video_template info
    }, [])

    useEffect(() => {
        if (userDetails?.userData?.id) {
            dispatch(get_is_admin_api(userDetails.userData.id)) // gives all logics info
        }

    }, [userDetails])

    useEffect(() => {
        dispatch(get_admin_logs_api(page))
        dispatch(get_event_logs_api())
    }, [page])

    useEffect(() => {
            setLogsData(logs_list.logs_list)
    }, [logs_list])

    // console.log(data)
    // console.log(org_list, "org_list")
    // console.log(users_list, "user_list")
    // console.log(avatars_list, "avatar_list")
    // console.log(bots_list, "bots_list")
    // console.log(videos_list, "videos_list")
    // console.log(voices_list, "voices_list")
    // console.log(logics_list, "logics_list")
    // console.log(templates_list, "templates_list")
    // console.log(admins_list, "admins_list")
    console.log(pt_list, "pt_list")
    // console.log(isAdmin, "isAdmin")
    // console.log((typeof logsData), "logsData")

    // if (loading) return <Loading />
    if (error) return <div style={{ width: FILL_PARENT, display: GRID, placeItems: CENTER }}>Something went wrong! Please try after sometimes</div>
    return <div>
        <Alert className="alert-dismissible"
            isOpen={showLoginSuccessful}
            color='success'
        >
            <p>{"Login succcessful"}</p>
            <Button style={{ width: px(30), height: px(10) }} className="btn-close" onClick={() => {
                setShowLoginSuccessful(false)
                localStorage.setItem(LOGIN_STATUS, ZERO)
            }}></Button>
        </Alert>

        <Breadcrumb parent="Dashboard" title="Overview" />
        {/* <GlobalUploader title="Upload Images" path="templates/first" /> */}
       
        {logs_list.logs_list ? <div className="row">
            <div className="col-xl-8 xl-100">
                <div className="row">
                    <EventCharts data={data} loading={loading} />
                </div>
            </div>

            <div className="col-xl-4 xl-100">
                <div className="card">
                    <div className="card-header">
                        <h5>{Activitys}</h5>
                    </div>
                    {logs_loading ? <SplitLoader center={true} /> : <div className="card-body">
                        <div className="activity">
                            <ScrollBar className="scroll" style={{ padding: "24px 0px" }}>
                                {logsData?.map(({ action, method, timestamp, user }) => <div className="d-flex">
                                    <div className="flex-shrink-0  m-r-30 gradient-line-1">
                                        <div style={{ display: GRID, placeItems: CENTER }}>
                                            <span className={`badge rounded-pill ${method === "POST" ? "badge-success" : method === "PATCH" ? "badge-warning" : "badge-danger"}`}>{method}</span>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h6>
                                            {action.replaceAll("_", " ").trim()} <span className="pull-right f-14">{new Date(`${converToUTC(timestamp)}`).toLocaleString(undefined, { timeZone: 'Asia/Kolkata' })}</span>
                                        </h6>
                                        <div style={{ display: FLEX, alignItems: CENTER }}><span><Copy style={{ cursor: POINTER }} size={10} onClick={() => copyContent(user)} /></span>&nbsp;<p style={{ maxWidth: "150px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{user} </p></div>
                                    </div>
                                </div>
                                )}
                            </ScrollBar>
                        </div>
                        <div>
                            <div style={{ display: FLEX, justifyContent: "end", alignItems: CENTER }}>
                                <Paginate page={page} setPage={setPage} totalPage={Math.ceil(logs_list.total_logs / 10)} />
                            </div>
                        </div>
                    </div>}
                </div>
            </div>

        </div> : <div className="row">
            <EventCharts data={data} loading={loading} />
        </div>}

    </div>
}