import { useEffect, useState } from "react";
import { Copy, RefreshCcw } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../components/common/breadcrumb";
import { copyContent } from "../../constant/scripts/helper";
import { get_job_health_request, get_job_logs_request } from "../../constant/scripts/request";
import { BLACK, BLUE, GRAY, GREEN, RED, TRANSPARENT, WHEAT, WHITE, YELLOW } from "../../constant/typograpy/colors";
import { CENTER, COLUMN, FILL_PARENT, FLEX, LEFT, POINTER } from "../../constant/typograpy/properties";
import { get_event_logs_api } from "../../redux/logs/logs.actions";


const LogLine = ({ time = "time", events = [], misc, type }) => {
    const [healthLoading, setHealthLoading] = useState(false)
    const [jobLoading, setJobLoading] = useState(false)
    const [health, setHealth] = useState(" ")
    const [jobLog, setJobLog] = useState([])
    // console.log(jlogs_list, "job logs")

    const handleCheckHealth = (id) => {
        setHealthLoading(true)
        get_job_health_request(id).then((health) => {
            setHealth(health.data)
            setHealthLoading(false)
        }).catch((error) => {
            setHealthLoading(false)
        })
    }


    const handleJobLog = (id) => {
        setJobLoading(true)
        get_job_logs_request(id).then((job) => {
            setJobLog(job?.data)
            setJobLoading(false)
        }).catch((error) => {
            setJobLoading(false)
        })
    }


    return <div>
        <span style={{ color: GREEN }}> {new Date(`${time}z`).toLocaleString(undefined, { timeZone: 'Asia/Kolkata' })} {" | "}</span>
        <span style={{ color: YELLOW }}>INFO {" | "}</span>
        <span style={{ color: YELLOW }}>ID {" | "}</span>
        <span style={{ color: WHITE }}>AWS JOB ID : {<Copy size={12} style={{ cursor: POINTER }} onClick={() => {
            copyContent(misc.aws_job_id)
        }} />} {misc.aws_job_id} {" | "}</span>
        <span style={{ color: WHITE }}>PERSONATE JOB ID : {<Copy size={12} style={{ cursor: POINTER }} onClick={() => {
            copyContent(misc.personate_job_id)
        }} />} {misc.personate_job_id} {" | "}</span>
        <span style={{ color: YELLOW }}>INFO {" | "} TYPE</span>
        <span style={{ color: BLUE }}>{" | "} {type} {" | "} </span>
        <span style={{ color: YELLOW }}>INFO {" | "} EMAIL {" | "}</span>
        <span style={{ color: GRAY }}>{events[0]}</span>
        <div>----------------------------------------------------------------</div>
        {events.map((el, i) => i === 4 ? <div style={{ color: WHITE, width: "200px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}> {el} {" | "} </div>
            : <span style={{ color: WHITE }}> {el} {" | "} </span>
        )}
        <div style={{ color: WHEAT }}>Job Health : {healthLoading ? ""
            :
            <div className={`${health !== "" && "badge"} ${health === "FAILED" ? "badge-danger" : "badge-info"}`}>
                {health}
            </div>} &nbsp; &nbsp;
            <span>
                <button onClick={() => handleCheckHealth(misc.aws_job_id)} style={{ backgroundColor: TRANSPARENT, border: "1px solid yellowgreen", color: "yellowgreen" }}>
                    <div style={{ display: FLEX, alignItems: CENTER, gap: "4px" }}>
                        {!healthLoading ? <RefreshCcw size={14} />
                            :
                            <div style={{ width: "20px", height: "20px" }} className="loader-box">
                                <span style={{ width: "10px", height: "10px" }} className="rotate dashed">
                                </span>
                            </div>} &nbsp;
                        <span>Check health</span>
                    </div>
                </button>
            </span>
        </div>
        <br></br>

        <div style={{ color: WHEAT }}>Job Log: {jobLoading && jobLog.length !== 0 ? ""
            :
            <span>
                {jobLog?.map((el) => <div style={{ color: WHITE }}>
                    <span style={{ color: YELLOW }}>{el.timestamp} {" | "}</span>
                    <span>{el.message}</span>
                </div>)}
            </span>
        }<span>
                <button onClick={() => handleJobLog(misc.aws_job_id)} style={{ backgroundColor: TRANSPARENT, border: "1px solid orange", color: "orange" }}>
                    <div style={{ display: FLEX, alignItems: CENTER, gap: "4px" }}> {!jobLoading ? <RefreshCcw size={14} />
                        :
                        <div style={{ width: "20px", height: "20px" }} className="loader-box"><span style={{ width: "10px", height: "10px" }} className="rotate dashed"></span
                        ></div>} &nbsp;
                        <span>Job log</span>
                    </div>
                </button>
            </span>
        </div>
        <div style={{ width: FILL_PARENT, border: "0.1px solid #36454F", marginTop: "16px" }}></div>

    </div>
}

export default function Terminal() {
    const { elogs_loading, elogs_list, elogs_error } = useSelector((state) => state.logManager)

    const [logsData, setLogsData] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(get_event_logs_api())
    }, [])

    useEffect(() => {

        setLogsData(elogs_list?.logs_list)

    }, [elogs_list])

    console.log(elogs_list, "logsData")
    return <div>
        <Breadcrumb title="Terminal" parent="Utility" />
        <div className="container-fluid">
            <div className="row">
                {/* <!-- Individual column searching (text inputs) Starts--> */}
                <div className="col-sm-12">
                    <div className="card">
                        {/* <div style={{ display: FLEX, alignItems: CENTER, justifyContent: SB }}>
                            <div className="card-header">
                                <h5>{"See all logs here"} </h5><span>{""}</span>
                            </div>
                        </div> */}

                        {/* <div style={{ margin: "16px" }}>
                        <SearchBar filters={true} top={80} visible={visible} setVisible={setVisible} filterOptions={filterList} query={query} setQuery={setQuery} search={search} setSearch={setSearch} onKeyDown={handleSearch} />
                    </div> */}
                        <div className="card-body">
                            <h6>{"See all logs here"} </h6><span>{""}</span>

                            <div className="card" style={{ backgroundColor: BLACK, height: "500px", padding: "16px", overflowY: "scroll" }}>
                                {elogs_loading && <p style={{ color: WHITE }}>Loading...</p>}
                                {elogs_error && <p style={{ color: RED }}>error...</p>}

                                <div style={{ height: FILL_PARENT, display: FLEX, flexDirection: COLUMN, alignItems: LEFT, gap: "16px" }}>
                                    {logsData?.map(({ createdAt, events, misc, type }) => {
                                        return <>
                                            <LogLine time={createdAt} events={events} misc={misc} type={type} />

                                        </>
                                    })}

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Individual column searching (text inputs) Ends--> */}
            </div>
        </div>
        {/* <div style={{ display: FLEX, justifyContent: "end", alignItems: CENTER, margin: "8px" }}>
        {avatars_list.total_avatars !== undefined && <Paginate page={page} setPage={setPage} totalPage={Math.ceil(avatars_list.total_avatars / 10)} />}
    </div> */}
    </div>
}