import { useEffect, useState } from "react"
import { uploadMultipleImages } from "../../../constant/scripts/helper"
import { Button } from "reactstrap"
import { ABSOLUTE, CENTER, FLEX, POINTER, RELATIVE, START } from "../../../constant/typograpy/properties"
import { Loader, X, XCircle } from "react-feather"
import { RED, WHITE } from "../../../constant/typograpy/colors"

export default function GlobalUploader({ title = "Upload Images", path = "general", links, setLinks }) {
    const [files, setFiles] = useState([])
    const [state, setState] = useState({
        loading: false,
        progress: null
    })

    const handleUpload = () => {
        if (files.length === 0) {
            return
        }
        uploadMultipleImages(files, setState, path).then((res) => {
            console.log(res)
            setFiles([])
            setState({
                loading: false,
                progress: null
            })
            setLinks((prev) => [...prev, ...res])
        }).catch((err) => {
            console.log(err)
        })
    }

    // console.log(files)

    const handleRemoveImage = (index, setFiles) => {
        setFiles((prev) => prev.filter((el, i) => i !== index && el))
    }

    const handleThumbnailImage = (index, setFiles) => {
        setFiles((prev) => {

            const filterImage = prev?.filter((el, i) => i === index && el)
            const newList = prev.filter((el, i) => i !== index && el)

            return [...filterImage, ...newList]

        })
    }

    return <div
        style={{
            backgroundColor: "#fff",
            margin: "auto",
            padding: "1rem",
            width: "100%",
        }}
    >
        <br></br>

        <div
            style={{
                marginBottom: "1rem",
            }}
        >
            <h1
                style={{
                    fontSize: "1.5rem",
                    marginBottom: "0.5rem",
                }}
            >
                {title}
            </h1>
            <p>Choose a file from your device to upload.</p>
        </div>
        <div
            style={{
                marginBottom: "1rem",
            }}
        >
            <div
                style={{
                    marginBottom: "1rem",
                }}
            >
                <input
                    onChange={(e) => setFiles(Array.from(e.target.files))}
                    id="fileUpload"
                    multiple
                    accept="image/png, image/jpg"
                    style={{
                        borderColor: "#d1d5db",
                        display: "block",
                    }}
                    type="file"
                />
            </div>
        </div>
        <div style={{ display: FLEX, alignItems: CENTER, justifyContent: START, gap: "8px", flexWrap: "wrap" }}>
            {
                files.length > 0 && files.map((el, index) => <div key={index + "el"} style={{ position: RELATIVE }}>
                    <img width={"100px"} src={URL.createObjectURL(el)}></img>
                    <X onClick={() => handleRemoveImage(index, setFiles)} size={16} style={{ position: ABSOLUTE, top: -4, right: -4, backgroundColor: RED, color: WHITE, borderRadius: "100px", cursor: POINTER }} />
                </div>)
            }
        </div>
        <div style={{ display: FLEX, alignItems: CENTER, justifyContent: START, gap: "8px", flexWrap: "wrap" }}>
            {
                links.length > 0 && links.map((el, index) => <div key={index + "ell"} style={{ position: RELATIVE }}>
                    <img style={{border:index === 0 && "3px solid red", cursor:POINTER, borderRadius:"8px"}} onClick={() => handleThumbnailImage(index, setLinks)} width={"100px"} src={el}></img>
                    <X onClick={() => handleRemoveImage(index, setLinks)} size={16} style={{ position: ABSOLUTE, top: -4, right: -4, backgroundColor: RED, color: WHITE, borderRadius: "100px", cursor: POINTER }} />

                </div>)
            }
        </div>
        <br></br>
        <div>
            {state.loading && <div
                style={{
                    marginBottom: "4px",
                }}
            >
                <div
                    style={{
                        backgroundColor: "lightgray",
                        borderRadius: "20px",
                        height: "10px",
                    }}
                >
                    <div
                        aria-label="Progress bar at 50%"
                        style={{
                            backgroundColor: "deepskyblue",
                            borderRadius: "20px",
                            height: "100%",
                            width: state.progress !== null > 0 && (Math.floor((state.progress / files.length) * 100)) + "%",
                        }}
                    />
                </div>
            </div>}
            {files.length > 0 && <Button
                onClick={handleUpload}
                disabled={state.loading}
                style={{
                    border: "none",
                    borderRadius: "0.5rem",
                    color: "#fff",
                    cursor: "pointer",
                    padding: "1rem",
                    width: "100%",
                }}
            >
                {state.loading ? <Loader /> : "Upload"}
            </Button>}
        </div>
    </div>
}