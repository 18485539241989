import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Copy, Edit } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import SweetAlert from "sweetalert2"
import Breadcrumb from '../../../components/common/breadcrumb'
import AvatarActionPanel from '../../../components/custom/ActionPanels/AvatarActionPanel'
import Paginate from '../../../components/custom/AdvanceComp/Paginate'
import SearchBar from '../../../components/custom/AdvanceComp/SearchBar'
import SplitLoader from '../../../components/custom/SplitLoader'
import { convertUTCToIST, copyContent } from '../../../constant/scripts/helper'
import { CENTER, FLEX, POINTER, SB } from '../../../constant/typograpy/properties'
import { get_public_template_list_api, patch_avatar_api, patch_public_template_api, search_public_template_list_api } from '../../../redux/assets/assets.actions'
import "../../styles/styles.css"
import PtActionPanel from '../../../components/custom/ActionPanels/PtActionPanel'
import CreatePublicTemplaete from '../../../components/custom/CreatePanels/CreatePublicTemplate'

const filterList = [
    { name: "ID", value: "id" },
    { name: "Name", value: "name" },
    { name: "Tags", value: "tags" }
]

const ManagePublicTemplate = () => {
    const { pt_list, pt_loading, pt_error } = useSelector((state) => state.assetsManager)
    const [ptData, setPtData] = useState([])
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const [page, setPage] = useState(Number(searchParams.get("page")) || 1)
    const [visible, setVisible] = useState(false)
    const [query, setQuery] = useState(searchParams.get("query") || "")
    const [search, setSearch] = useState(searchParams.get("type") || filterList[2].value)
    const [refresh, setRefresh] = useState(false)
    useEffect(() => {
        setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
    }, [page])

    useEffect(() => {

        if (query.trim() !== "") {
            dispatch(search_public_template_list_api(query.trim(), search)) // search all avatars info
        } else {
            dispatch(get_public_template_list_api(page)) // gives all avatars info

        }

    }, [searchParams, search, refresh])
    const handlePatch = (formdata) => {
        dispatch(patch_public_template_api(formdata, SweetAlert)).then(() => {
            setPtData([])
            setRefresh((prev) => !prev)
        }).catch((e) => {
            console.log(e)
        })
    }

    useEffect(() => {
        setPtData(() => pt_list.public_templates_list?.map((el, i) => {
            let copyId = el.id
            el.avaId = <span key={i + "copy-ava-id"}><Copy size={12} style={{ cursor: POINTER }} onClick={() => {
                copyContent(copyId)
            }} /> <span>{copyId}</span></span>
            el.image = <PtActionPanel element={<img key={i + "image-avatar"} src={el.previewImages[0]} style={{ width: 50, height: 50 }} alt="" />} keyy={i + "ava"} id={el.id} el={el} actions={{ handlePatch }} /> 
            el.action = <PtActionPanel keyy={i + "ava"} id={el.id} el={el} actions={{ handlePatch }} />
            return el
        }))

    }, [pt_list])

    // console.log(pt_list)

    const columns = [
        {
            name: "Image",
            selector: (row) => row.image,
            sortable: true,
            center: true,
        },
        {
            name: "ID",
            selector: (row) => row.avaId,
            sortable: true,
            center: true,
        },
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            center: true,
        },
        {
            name: "Created At",
            selector: (row) => convertUTCToIST(row.created_at),
            sortable: true,
            center: true,
        },

        {
            name: "Video Type",
            selector: (row) => row.settings.video_type,
            sortable: true,
            center: true,
        },

        {
            name: "Action",
            selector: (row) => row.action,
            sortable: true,
            center: true,
        },


    ]

    const handleSearch = (e) => {
        if (e.key === "Enter") {
            setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
        }
    }

    useEffect(() => {
        if (query === "") {
            dispatch(get_public_template_list_api(page)) // gives all avatars info
            setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
        }
    }, [query])

    useEffect(() => {
        setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
    }, [search])

// console.log(pt_list)
    return (
        <div>
            <Breadcrumb title="Manage PT" parent="PAM" />
            <div className="container-fluid">
                <div className="row">
                    {/* <!-- Individual column searching (text inputs) Starts--> */}
                    <div className="col-sm-12">
                        <div className="card">
                            <div style={{ display: FLEX, alignItems: CENTER, justifyContent: SB }}>
                                <div className="card-header">
                                    <h5>{"Manage all the public templates from here"} </h5><span>{""}</span>
                                </div>
                                <span style={{marginRight:"16px"}}><CreatePublicTemplaete page = {page} loading={pt_loading} /></span>
                            </div>

                            <div style={{ margin: "16px"}}>
                                <SearchBar filters={true} top={80} visible={visible} setVisible={setVisible} filterOptions={filterList} query={query} setQuery={setQuery} search={search} setSearch={setSearch} onKeyDown={handleSearch} />
                            </div>
                            <div className="card-body">
                                <div className="table-responsive product-table">
                                    {pt_loading ? <SplitLoader center={true} /> : <DataTable
                                        noHeader
                                        className='no-scroll-body'
                                        data={ptData}
                                        columns={columns}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Individual column searching (text inputs) Ends--> */}
                </div>
            </div>
            <div style={{ display: FLEX, justifyContent: "end", alignItems: CENTER, margin: "8px" }}>
                {pt_list.total_templates !== undefined && <Paginate page={page} setPage={setPage} totalPage={Math.ceil(pt_list.total_templates / 10)} />}
            </div>
        </div>
    )
}

export default ManagePublicTemplate