import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Col, Progress, Row } from 'reactstrap'
import Breadcrumb from '../../../components/common/breadcrumb'
import Paginate from '../../../components/custom/AdvanceComp/Paginate'
import SearchBar from '../../../components/custom/AdvanceComp/SearchBar'
import SplitLoader from '../../../components/custom/SplitLoader'
import { CENTER, FLEX, POINTER } from '../../../constant/typograpy/properties'
import { get_org_list_api, search_orgs_list_api } from '../../../redux/org/org.actions'
import "../../styles/styles.css"
import { convertUTCToIST } from '../../../constant/scripts/helper'
import { Clock } from 'react-feather'

const filterList = [
    { name: "Email", value: "email" },
    { name: "ID", value: "id" },
    { name: "Name", value: "name" },
]

const ManageOrgs = () => {
    const { org_list, loading, error } = useSelector((state) => state.orgManager)
    const [ogrsData, setOrgsData] = useState([])
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const [page, setPage] = useState(Number(searchParams.get("page")) || 1)
    const [visible, setVisible] = useState(false)
    const [query, setQuery] = useState(searchParams.get("query") || "")
    const [search, setSearch] = useState(searchParams.get("type") || filterList[2].value)
    const [refresh, setRefresh] = useState(false)
    const nav = useNavigate()
    useEffect(() => {
        setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
    }, [page, search])

    useEffect(() => {

        if (query.trim() !== "") {
            dispatch(search_orgs_list_api(query.trim(), search)) // search all videos info
        } else {
            //comment out to get video list without search
            dispatch(get_org_list_api(page)) // gives all videos info

        }

    }, [searchParams, search, refresh])
    const handlePatch = (formdata) => {
        // dispatch(patch_video_api(formdata, SweetAlert)).then(() => {
        //     setOrgsData([])
        //     setRefresh((prev) => !prev)
        // }).catch((e) => {
        //     console.log(e)
        // })
    }

    useEffect(() => {

        setOrgsData(org_list?.orgs_list || org_list?.org_list)

    }, [org_list])

    // console.log(org_list)


    const handleSearch = (e) => {
        if (e.key === "Enter") {
            setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
        }
    }

    useEffect(() => {
        if (query.trim() === "") {
            //comment out to get video list without search
            dispatch(get_org_list_api(page)) // gives all videos info
            setSearchParams(`?page=${page}&query=${query.trim()}&type=${search}`)
        }
    }, [query])


    return (
        <div>
            <Breadcrumb title="Manage Orgs" parent="CAM" />
            <div className="container-fluid">
                <div className="row">
                    {/* <!-- Individual column searching (text inputs) Starts--> */}
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">

                                <h5>{"Manage all the orgs from here"} </h5><span>{""}</span>

                            </div>
                            <div style={{ margin: "16px" }}>
                                <SearchBar filters={true} top={80} visible={visible} setVisible={setVisible} filterOptions={filterList} query={query} setQuery={setQuery} search={search} setSearch={setSearch} onKeyDown={handleSearch} />
                            </div>
                            <div className="card-body">
                                {loading ? <SplitLoader center={true} /> : <div className='row'>
                                    {ogrsData?.map((el) => <Col style={{cursor:POINTER}} onClick={() => nav(`/cam/org/details/${el.id}`)} key={el.id} lg="6" xxl="4" className="mt-4">
                                        <div className="project-box">
                                            <span className={`badge ${"Done" === "Done" ? "badge-success" : "badge-primary"}`}>{"Active"}</span>
                                            <h6 className="f-w-600">
                                            
                                                {el.companyName}
                                            </h6>
                                            <div className="d-flex align-items-center">
                                                {/* <img className="img-20 me-1 rounded-circle" src={""} alt="" /> */}
                                                <div className="flex-grow-1">
                                                    <p><Clock size={12}/> Joined on &nbsp;{convertUTCToIST(el.created_at)}</p>
                                                </div>
                                            </div>
                                            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem eum natus sequi aperiam mollitia! Rem at tempora quaerat, perferendis, consectetur blanditiis minima qui amet deleniti consequatur perspiciatis reiciendis. Omnis, accusantium.</p> */}
                                            <Row className="details">
                                                <Col xs="6">
                                                    <span>{"Employees"} </span>
                                                </Col>
                                                <Col xs="6" className={"Done" === "Done" ? "text-success" : "text-primary"}>
                                                    {el.employeeCount}
                                                </Col>
                                                <Col xs="6">
                                                    {" "}
                                                    <span>{"Topup Credits"}</span>
                                                </Col>
                                                <Col xs="6" className={"Done" === "Done" ? "text-success" : "text-primary"}>
                                                    {el.topupCredits}
                                                </Col>
                                                <Col xs="6">
                                                    {" "}
                                                    <span>{"Issues"}</span>
                                                </Col>
                                                <Col xs="6" className={"Done" === "Done" ? "text-success" : "text-primary"}>
                                                    {0}
                                                </Col>
                                            </Row>
                                            {/* <div className="customers">
                                            <ul>
                                                <li className="d-inline-block">
                                                    <img className="img-30 rounded-circle" src={""} alt="" />
                                                </li>
                                                <li className="d-inline-block">
                                                    <img className="img-30 rounded-circle" src={""} alt="" />
                                                </li>
                                                <li className="d-inline-block">
                                                    <img className="img-30 rounded-circle" src={""} alt="" />
                                                </li>
                                                <li className="d-inline-block ms-2">
                                                    <p className="f-12">{`+${7} More`}</p>
                                                </li>
                                            </ul>
                                        </div> */}
                                            <div className="project-status mt-4">
                                                <div className="d-flex mb-0">
                                                    <p>{el?.topupCredits}</p>
                                                    <div className="flex-grow-1 text-end">
                                                        <span>{"Credits Left"}</span>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </Col>)}
                                </div>}
                            </div>
                        </div>
                    </div>
                    {/* <!-- Individual column searching (text inputs) Ends--> */}
                </div>
            </div>
            <div style={{ display: FLEX, justifyContent: "end", alignItems: CENTER, margin: "8px" }}>
                {org_list.total_orgs !== undefined && <Paginate page={page} setPage={setPage} totalPage={Math.ceil(org_list.total_orgs / 10)} />}
            </div>
        </div>
    )
}

export default ManageOrgs